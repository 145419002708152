import React from 'react'
import '../Style/BoxBtnNew.scss'

function BoxBtnNew(props: { onClick: any, icon: any, name?: any, disabled?: boolean, xl?: boolean, addToBtn?: any }) {

    function onAddToProfile() {
        window.open(props.addToBtn, '_blank')    
    }

    return (
        <div className={`box-btn-new ${props.disabled && 'disabled'} `} >
            <div className='icon-wrap'>
                <img src={props.icon} className={`icon ${props.xl && 'xl'}`}></img>{props.name}
            </div>
            <button onClick={props.onClick} disabled={props.disabled} className='btn' >
                Share on
            </button>
            {
                props.addToBtn && (
                    <button onClick={onAddToProfile} className='btn' >
                        Add to Profile
                    </button>
                )
            }
        </div>
    );
}

export default BoxBtnNew;

import React, { ChangeEvent, useEffect, useState } from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import Button from '../components/Button'
import BoxbtnCustom from '../components/BoxbtnCustom'
import TopNavigationHeader from '../components/TopNavigationHeader'
import Spinner from '../components/Spinner'
import Alert from "../components/Alert";


import backBtn from '../Assets/backBtn.svg';
import correctMark from '../Assets/correctMark.svg';
import trophyIcon from '../Assets/trophy.svg';
import badgeIcon from '../Assets/badge.svg';
import propic from '../Assets/defaultUser.jpg';
import editIcon from '../Assets/editIcon.svg';
import { useHistory } from 'react-router'
import { VIEWS } from '../utils'
import axios, { AxiosError } from 'axios'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { setImage, setName, _image, _name } from '../redux/identitySlice'
import AlertWithText from '../components/AlertWithText'
import cameraIcon from '../Assets/camera.png';
import greenInfo from '../Assets/greenInfo.svg'
import greenCheck from '../Assets/greenCheck.svg'
import jwt from 'jsonwebtoken'


function Myprofile() {

    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [alertWithTextOpen, setAlertWithTextOpen] = useState({ open: false, title: '', content: '' })
    const profileImage = useAppSelector(_image)
    const name = useAppSelector(_name)
    const history = useHistory()
    const dispatch = useAppDispatch()
    const [showCamera, setShowCamera] = useState(false)
    const [changeBackground, setChangeBackground] = useState(false)
    const [isDomainVerify, setIsDomainVerify] = useState(false)
    const [verifiedDomain, setVerifiedDomain] = useState('')
    const domainVerified = 'true';

    useEffect(() => {
        const userToken = sessionStorage.getItem('userToken')
        const decodedUserToken:any = jwt.decode(userToken as string)
        setIsDomainVerify(decodedUserToken?.domainVerified)
        setVerifiedDomain(decodedUserToken?.domain)
    })

    function addLinkedinAcc() {
        window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/change&scope=r_liteprofile`, "_self")
    }

    function viewIdSecret() {
        history.push(VIEWS.IDENTITY)
    }

    function tropyCase() {
        history.push(VIEWS.TROPHYCASE)
    }

    function myRewards() {
        history.push(VIEWS.MYREWARDS)
    }

    function handleBack() {
        history.push(VIEWS.HOME)
    }

    function handleDone() {
        history.push(VIEWS.HOME)
    }

    function changeProfileImage(event: ChangeEvent<HTMLInputElement>) {
        setIsLoading(true)
        const reader = new FileReader();
        if (event.target.files && event.target.files[0]) {
            const img = event.target.files[0];
            reader.onloadend = async () => {
                const imageData: string = reader.result as string
                const token = sessionStorage.getItem('userToken')
                try {
                    const userTokenResponse = await axios.post(`${process.env.REACT_APP_REWARD_BACKEND}user/set/image`, { token, photo: imageData })
                    if (userTokenResponse.data) {
                        const userToken = userTokenResponse.data.user
                        sessionStorage.setItem("userToken", userToken);
                        dispatch(setImage(imageData))
                        setIsLoading(false)
                    }
                } catch (error: any) {
                    console.log(error.message)
                }
            }
            reader.readAsDataURL(img);
        }
        
    }

    async function changeName(newName:string) {
        setIsLoading(true)
        const token = sessionStorage.getItem('userToken')
        try {
            const userTokenResponse = await axios.post(`${process.env.REACT_APP_REWARD_BACKEND}user/set/name`, { token, name: newName })
            if (userTokenResponse.data) {
                const userToken = userTokenResponse.data.user
                sessionStorage.setItem("userToken", userToken);
                dispatch(setName(newName))
                setIsLoading(false)
                setAlertOpen({ open: true, title: 'Success', content: 'Your profile name changed successfully.' });
            }
        } catch (error: any) {
            setAlertOpen({ open: true, title: 'Error', content: 'Something went wrong. Try again.' })
            console.log(error.message)
        }
        setIsLoading(false)
    }

    function openChangeName() {
        setAlertWithTextOpen({ ...alertWithTextOpen, open: true })
    }

    function closeChangeName() {

    }

    function showCameraIcon(){
        setShowCamera(true)
        setChangeBackground(true)
    }

    function hideCameraIcon(){
        setShowCamera(false)
        setChangeBackground(false)
    }

    function verifyDomain(){
        history.push(VIEWS.VERIFYDOMAIN)
    }


    return (
        <React.Fragment>
            <div className="container container-back">
                <TopNavigationHeader
                    caption="My Profile"
                    handleDone={handleDone}
                    rightIcon={correctMark}
                />

                <div className="wrap-full">
                    {isDomainVerify? (
                        <div className="verifyLink">
                            <a className="signInLink" onClick={verifyDomain} >
                                <p className="greenCheckTxt">
                                    <img src={greenCheck} />Verified
                                </p>
                            </a>    
                            <p className="greenCheckTxt">{verifiedDomain}</p>
                        </div>
                    ):(
                        <div className="verifyLink">
                            <a onClick={verifyDomain} className="signInLink">
                                <p className="greenCheckTxt">
                                    <img src={greenInfo} />Verify my Domain
                                </p>
                            </a>
                        </div>
                    )}
                    <div className="profile-section">
                        <div className="propic" onMouseEnter={showCameraIcon} onMouseLeave={hideCameraIcon}>
                            <label htmlFor='profile-image-upload' style={{ cursor: 'pointer' }}>
                                <img className="propic" src={profileImage || propic}></img>
                                <input
                                    accept='image/*'
                                    className="hidden"
                                    id='profile-image-upload'
                                    type="file"
                                    onChange={changeProfileImage}
                                />
                                <div className="changeIcons">
                                    {showCamera? (
                                    <div>
                                        <img src={cameraIcon}/>
                                    </div>)
                                    :<p className="defaultDisplayTxt">Change</p>}
                                </div>
                            </label>
                            <input className="hidden" accept='image/*' id="icon-button-file" type="file" />
                            <div className={changeBackground? "no-fill":"fill"}></div>
                        </div>
                        <p className="userProfileName">{name.substring(0, 30)}
                            <button onClick={openChangeName} className="btn-without-background2">
                                <img src={editIcon}></img>
                            </button>
                        </p>
                    </div>
                    <div className="boxbtn-section">
                        <BoxbtnCustom icon={trophyIcon} name="Favorite Rewards" onClick={tropyCase} />
                        <BoxbtnCustom icon={badgeIcon} name="My Rewards" onClick={myRewards} />
                    </div>
                    <Button onClick={addLinkedinAcc} name="Change/Add LinkedIn Account" />
                    <Button onClick={viewIdSecret} name="View My ID Secret" />
                </div>
            </div>
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
            <AlertWithText
                open={alertWithTextOpen.open}
                handleClose={() => setAlertWithTextOpen({ ...alertWithTextOpen, open: false })}
                handleOpen={() => setAlertWithTextOpen({ ...alertWithTextOpen, open: true })}
                handleAction={changeName}
                title={alertWithTextOpen.title}
                content={alertWithTextOpen.content}
                userNamePreviously={name}
            />
        </React.Fragment>
    );
}

export default Myprofile;
import React, { Component } from 'react'

export default function BadgeTemplatesItem(props: {badgeType: any, onClick: any,}){
    return(
        <React.Fragment>
            <button onClick={props.onClick} className="badge-item">
                <div>
                    <img className="badge-img" src={props.badgeType}></img>
                </div>
            </button>
        </React.Fragment>
    );
}

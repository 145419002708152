import React, { useEffect } from 'react'
import jwt from 'jsonwebtoken'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import ContactListItem from '../components/ContactListItem'
import TopNavigationHeader from '../components/TopNavigationHeader'
import Button from '../components/Button'

import closeIcon from '../Assets/closeIcon.svg';
import u1 from '../Assets/u1.png';

import { useState } from "react";
import { useHistory } from 'react-router'
import { VIEWS } from '../utils'
import axios, { AxiosRequestConfig } from 'axios'
import deleteIcon from '../Assets/deleteIcon.svg';
import { useAppDispatch } from '../app/hooks'
import { setHolderDID, setHolderImage, setHolderName } from '../redux/signerSlice'
import RewardRequestItem from '../components/RewardRequestItem'
import Spinner from "../components/Spinner";
import Alert from "../components/Alert";


function ReceivedRequests() {

    const history = useHistory()
    const dispatch = useAppDispatch()
    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: ''})


    useEffect(() => {
        setIsLoading(true);
        const userToken = sessionStorage.getItem('userToken')
        getRequests(userToken as string).then(res => {
            setRequests(res.data.requests)
            console.log(res.data.requests);
            if(res.data.requests.length === 0){
                setAlertOpen({ open: true, title: 'Alert', content: 'You don\'t have new rewards request.'});
            }
            // setContacts(res.data.contacts);
            // setFilteredContacts(res.data.contacts);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error)
            setIsLoading(false)
        })
    }, []);

    const getRequests = async (token: string) => {
        const contacts = await axios.post(`${process.env.REACT_APP_REWARD_BACKEND}rewardRequest`, {
            token
        });
        return contacts;
    };

    function handleBack() {
        history.push(VIEWS.HOME)
    }

    function handleDone() {
        history.push(VIEWS.HOME)
    }

    function issueReward() {
        history.push(VIEWS.ISSUE)
    }

    function onAccept(idx: number) {
        const { name, receivingDID, photo } = requests[idx]
        dispatch(setHolderDID(receivingDID))
        dispatch(setHolderImage(photo))
        dispatch(setHolderName(name))
        history.push(VIEWS.ISSUE + '?requestid=' + requests[idx]['id']  )
    }

    function onDeny(idx: number) {
        const { receivingDID,id } = requests[idx]
        const token = sessionStorage.getItem('userToken')
        axios.delete(`${process.env.REACT_APP_REWARD_BACKEND}rewardRequest`, {
            data: {token, receiverDID: receivingDID, id:id}
        }).then(res => {
            window.location.reload()
        })
    }

    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="Received Requests"
                    handleBack={handleBack}
                    handleDone={handleDone}
                />
                <div className="wrap-full">
                    <div className="wrapReverse">
                        {
                            requests.map((item, index) => (
                                <RewardRequestItem
                                    userPropic={item["photo"] ? item["photo"] : u1}
                                    userName={item["name"]}
                                    actionBtn={deleteIcon}
                                    onAccept={() => { onAccept(index) }}
                                    onDeny ={() => { onDeny(index) }}
                                    blueTxt="Sent you a reward request"
                                    key={index.toString()} />
                            ))
                        }
                    </div>

                    <div className="extraMargin-iR-Btn">
                        <Button onClick={issueReward} name="Issue a new Reward" />
                    </div>

                <Alert
                    open={alertOpen.open}
                    handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                    handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                    title={alertOpen.title}
                    content={alertOpen.content}
                 />
                </div>
            </div>
            {isLoading && <Spinner />}
        </React.Fragment>
    );
}

export default ReceivedRequests;

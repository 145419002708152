import React, { Component } from 'react'

function BoxbtnCustom(props: {onClick: any, icon: any; name: any}){
    return(
        <React.Fragment>
            <button onClick={props.onClick} className="boxbtn-custom">
                <img src={props.icon}></img>{props.name}
            </button>
        </React.Fragment>
    );
}

export default BoxbtnCustom;
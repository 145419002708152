import React, { Component } from 'react'

function ReceivedRewardPreviewUser(props: {heading: any, profileImage?: any, userName?: any, url?: any, companyUrl?: any,}){
    //console.log(props)
    return(
        <React.Fragment>
            <div className="issuerDetails">
                <p className="sectionTitle"><span>{props.heading}</span></p>
                <div className="issuedTo">
                    <div className="imgUserPropic">
                        {props.profileImage ? (<img src={props.profileImage}></img>) : <div className="emptyspace"></div>}
                    </div>
                    <div className="details">
                        <p className="name">{props.userName.substring(0, 30)}</p>
                        {props.url ? <p className="url"><a href={props.url} target='_blank'>Public Profile</a></p>: null}
                        {/*<p className="url"><a href={props.url}>Public Profile</a></p>*/}
                        <p className="company-url">{props.companyUrl}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ReceivedRewardPreviewUser;

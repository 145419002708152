import React from "react";

export default function ContactListItem(props: {
    userPropic: any,
    userName: any,
    actionBtn: any,
    blueTxt: any,
    onAccept: any
    onDeny: any
}) {
    return (
        <React.Fragment>
            <div className="contact-item">
                <div className="left-side">
                    <img src={props.userPropic} className="userpropic-icon"></img>
                    <div className="textArea pointer" onClick={props.onAccept}>
                        <p className="userprofile-name">{props.userName}</p>
                        {props.blueTxt ? (<p className="receivedRequestTxt">{props.blueTxt}</p>) : (<p></p>)}
                    </div>
                </div>
                <div className="right-side" onClick={props.onDeny}>
                    <button className="btn-without-background">
                        <img src={props.actionBtn} className="chevronDown-icon"></img>
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

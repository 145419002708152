import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface MyRewardState {
    view: string;
}

const initialState: MyRewardState = {
    view: 'myRewards'
};

export const myRewardsSlice = createSlice({
    name: 'myRewards',
    initialState,
    reducers: {
        setView: (state, action: PayloadAction<string>) => {
            state.view = action.payload;
        }
    },
});

export const { setView } = myRewardsSlice.actions;

export const _view = (state: RootState) => state.myRewards.view;

export default myRewardsSlice.reducer;

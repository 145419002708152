import React, { useEffect } from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import Boxbtn from '../components/Boxbtn'
import UserProfile from '../components/UserProfile'
import TopNavigationHeader from '../components/TopNavigationHeader'

import arrowAccept from '../Assets/arrowAccept.svg';
import arrowIssue from '../Assets/arrowIssue.svg';
import deleteIcon from '../Assets/deleteIcon.svg';
import userProfile from '../Assets/userProfile.png';
import jwt from 'jsonwebtoken'

import { useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { VIEWS } from "../utils";
import { useAppDispatch } from '../app/hooks'
import { setHolderDID, setHolderImage, setHolderName } from '../redux/signerSlice'
import PopupQuestion from "../components/PopupQuestion";
import Alert from "../components/Alert";
import Spinner from "../components/Spinner";


function ContactsProfile() {
    const history = useHistory()
    const [contact, setContact] = useState(null);
    const dispatch = useAppDispatch()
    const [popUp, setPopUp] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [isLoading, setIsLoading] = useState(false)
    const receivingDID = new URLSearchParams(history.location.search).get("receivingDID");
    const searchType = new URLSearchParams(history.location.search).get("type");
    const success = new URLSearchParams(history.location.search).get("success");
    const query = new URLSearchParams(history.location.search).get("query");
    const from = new URLSearchParams(history.location.search).get("from");

    useEffect(() => {
        setIsLoading(true)
        if (receivingDID && receivingDID !== "undefined") {
            getContact(receivingDID).then(res => {
                console.log(res.data);
                setContact(res.data);
                setIsLoading(false)
                if (success === "true" && from === "gc") {
                    setAlertOpen({ open: true, title: 'Success', content: 'Contact added successfully' })
                }
            })
        }

    }, []);

    const getContact = async (receivingDID: string) => {
        const contact = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}contacts/getContact`, {
            params: {
                receivingDID
            }
        });
        return contact;
    };

    const removeContact = async (uid: string, contactName: string, contactDID: string) => {
        const contact = await axios.delete(`${process.env.REACT_APP_REWARD_BACKEND}contacts/removeContact`, {
            data: {
                uid,
                contactName,
                contactDID
            }
        });
        return contact;
    };

    function onClickRemove() {
        setIsLoading(true)
        const userToken = sessionStorage.getItem('userToken')
        const decodedUserToken: any = jwt.decode(userToken as string)
        const uid = decodedUserToken["_id"]
        // @ts-ignore
        removeContact(uid, contact.name, contact.receivingDID).then((res: any) => {
            console.log('REMOVE RESPONSE', res);
            if (res.data["message"] === "Contact Removed") {
                history.push(VIEWS.MYCONTACT);
            }
            setIsLoading(false)
        })

    };

    function handleBack() {
        // if (from === "gc") {
        //     history.push(VIEWS.GLOBALCONTACTS + "?type=" + searchType + "&query=" + query)
        // } else {
        //     history.goBack()
        // }
        history.goBack();
    }

    function issueReward() {
        // @ts-ignore
        dispatch(setHolderDID(contact.receivingDID))
        // @ts-ignore
        dispatch(setHolderName(contact.name))
        // @ts-ignore
        dispatch(setHolderImage(contact.photo))
        history.push(VIEWS.ISSUE)
    }

    function requestReward() {
        setIsLoading(true)
        const userToken = sessionStorage.getItem('userToken')
        const decodedUserToken: any = jwt.decode(userToken as string)
        const receiverReceivingDID = decodedUserToken.receivingDID

        axios.put(`${process.env.REACT_APP_REWARD_BACKEND}rewardRequest`,
            {
                // @ts-ignore
                issuerReceivingDID: contact.receivingDID,
                receiverReceivingDID,
                // @ts-ignore
                receiverName: decodedUserToken.name,
                // @ts-ignore
                receiverImage: decodedUserToken.photo,
            }).then((res) => {
                setIsLoading(false)
                setAlertOpen({ title: "Success", content: "Reward Request sent successfully.", open: true })
                console.log(res.data);
            }).catch(() => { setAlertOpen({ title: "Reward Request Error", content: "Reward Request Unsuccessful", open: false }) })
        //setIsLoading(false)
    }


    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    // @ts-ignore
                    caption={contact ? `${contact["name"]}'s Profile` : "No profile found for given DID"}
                    handleBack={handleBack}
                />
                <div className="wrap-full">
                    {
                        contact ? (
                            <>
                                <div className="delete-icon">
                                    <button className="btn-without-background" onClick={() => { setPopUp(true) }}>
                                        <img src={deleteIcon}></img>
                                    </button>
                                </div>

                                <UserProfile
                                    userProfilePicture={// @ts-ignore
                                        contact["photo"]}
                                    userProfileName={// @ts-ignore
                                        contact["name"]}
                                />
                                <div className="boxbtn-section">
                                    <Boxbtn icon={arrowIssue} name="Issue a Reward" onClick={issueReward} />
                                    <Boxbtn icon={arrowAccept} name="Request a Reward" onClick={requestReward} />
                                </div>
                            </>
                        ) : null
                    }
                </div>
            </div>
            {
                popUp ? <PopupQuestion onClickConfirm={() => { onClickRemove() }} onClickCancel={() => { setPopUp(false) }} headingText={"Alert"} bodyMessage={"Are you sure you want to remove this contact ?"} buttonTxtCancel={"NO"} buttonTxtConfirm={"YES"} />
                    : null

            }
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </React.Fragment>
    );
}

export default ContactsProfile;

import React, { useState, useEffect } from 'react';
import greenCheck from '../Assets/greenCheck.svg'
import circleHelp from '../Assets/circle-help.svg'
import copy from '../Assets/copy.svg';
import jwt from 'jsonwebtoken'


export default function TxtValueAndStatus(props:{helpTxtPage:any, txt:any, domain:string, checkStatus:any, isDomainVerify:any,}){

    const [helpTxtValue, setHelpTxtValue] = useState(false)
    const [copyText, setCopyText] = useState(false);
    const userToken = sessionStorage.getItem('userToken')
    const decodedUserToken:any = jwt.decode(userToken as string)


    function displyTxtHelp(){
        setHelpTxtValue(true)
    }

    function hideTxtHelp(){
        setHelpTxtValue(false)
    }


    function copyClick(){
        setCopyText(true)
        navigator.clipboard.writeText(props.txt)
        setTimeout(()=>{
            setCopyText(false)
        },2000)
    }


    return(
        <React.Fragment>
            <div className="extra-mt-homebtn"></div>
            <div className="issuerDetails">
                <p className="sectionTitleClaimDomain">
                    <span>TXT Value
                        <img onMouseEnter={displyTxtHelp} onMouseLeave={hideTxtHelp} className="helpIcon" src={circleHelp}/>
                    </span>
                </p>
            </div>
            {helpTxtValue?(<div className="txtValueHelpText">Please add the below value as a txt record to your domain <br/><br/> Click the below link if you need more details</div>):null}

            <div className="input_container">
                <input type="text" className="domainConfirmTxt" placeholder="Value for TXT record" value={props.txt}></input>
                <button className="btn-without-background" onClick={copyClick}>
                    <img className="copy-icon-inside-domainverify" src={copy}></img>
                        {copyText? (<div className="tooltipText">Copied to<br/>Clipboard</div>) : ""}
                </button>
            </div>

            <a onClick={props.helpTxtPage} className="signInLink">
                <p className="subtitle subtitle-mg-signup">How to add this to your domain ?</p>
            </a>

            <div className="extra-mt-homebtn"></div>
                <div className="issuerDetails">
                    <p className="sectionTitleClaimDomain"><span>TXT Status</span></p>
                </div>
            <div className="verifyLink">
                {props.isDomainVerify? (
                    <p className="greenCheckTxt">
                        <img src={greenCheck} />VERIFIED
                    </p>
                ):(
                    <p className="greenCheckTxt">Pending</p>
                )}
    
                <p className="greenCheckTxt">
                    {decodedUserToken?.domain}
                </p>
            </div>
            <button onClick={props.checkStatus} className="traansparent-btn-domain">Check Status</button>
            <div className="extra-mt-homebtn"></div>
        </React.Fragment>
    );
}


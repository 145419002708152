import { IconButton, makeStyles } from "@material-ui/core"
import { ReactComponent as RemoveButton } from './../SVGs/close.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100px',
        height: '100px'
    },
    label: {
        fontWeight: 'bold',
        fontSize: '0.9rem',
        lineHeight: '1.5rem',
        color: '#161719'
    },
    pointer: {
        cursor: 'pointer',
        '&:hover': {

        },
    },
    removeIcon: {
        position: 'absolute',
        height: 1,
        width: 1,
        backgroundColor:'#fff',
        top: 2,
        right: 2,
        fontSize: '1rem',
        zIndex: 100,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor:'#fff',
        },
    },

    placeholder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 100,
        width: 100,
        borderRadius: 10,
        backgroundColor: 'white'
    },
    placeholderTxt:{
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '6px',
        lineHeight: '9px',
        color: '#A8A4A4',
        position: 'absolute',
        marginTop: '85px',
        zIndex: 0
    },
    placeholderImage: {
        height: 24,
        width: 24
    },
    error: {
      color: 'red',
      margin: 1
    },
    hidden: {
      display: 'none'
    }
}))

export default function ProfileImageUploadField(props: { id: string, handleImageData: any, imageData: any, accept: string, caption?: string, removeCallback?: any, error?: boolean, helperText?: string }) {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <p className={classes.label} hidden={!props.caption}>{props.caption}</p>
            <input
                accept={props.accept}
                className="hidden"
                id={props.id}
                type="file"
                onChange={props.handleImageData}
            />
            <label className={`${classes.pointer}`} htmlFor={props.id}>
                <div className={classes.placeholder}>
                    {
                        !props.imageData ? <img className={classes.placeholderImage} src={"./../SVGs/image-placeholder.svg"} alt="" />
                            :
                            <img src={props.imageData || ""} alt="" />}
                    <p className={classes.placeholderTxt}>Tap to Upload</p>
                </div>
            </label>
            <input className="hidden" accept={props.accept} id="icon-button-file" type="file" />
            {props.removeCallback && props.imageData && <IconButton onClick={props.removeCallback} className={`${classes.removeIcon} ${classes.pointer}`}>
                {/* <RemoveButton /> */}
                x
            </IconButton>}
            <p className={props.error ? classes.error : classes.hidden}>{props.helperText}</p>
        </div>
    )
}
import React, { Component } from 'react'
//import KeyboardEventHandler from "react-keyboard-event-handler";


function ContactSearchInput(props: {placehldr: any, searchIcon?: any, onChange?: any, onClick?: any, onPressEnter?:any, value?: string,}){
    const onKeyPress = (keyEvent:any)=> {
        if(keyEvent.key === "Enter"){
            props.onPressEnter();
            console.log("enter pressed")
        }
    };
    return(
        <React.Fragment>
            <div className="searchInput-container">
                <input type="text" className="search-contact" placeholder={props.placehldr} onChange={props.onChange} onKeyPress={onKeyPress} value={props.value}></input>
                <button className="btn-without-background" onClick={props.onClick}>
                    {props.searchIcon ? (<img className="search-icon-inside" src={props.searchIcon}></img>) : <div></div>}
                </button>
            </div>

        </React.Fragment>
    );
}

export default ContactSearchInput;

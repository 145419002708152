import React, { Component } from 'react'

function SmallTransparentBtn(props: {onClick: any, txt: any}){
    return(
        <React.Fragment>
            <button onClick={props.onClick} className="small-transparent-btn">{props.txt}</button>
        </React.Fragment>
    );
}

export default SmallTransparentBtn;
import React, { useEffect, useState } from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import TopNavigationHeader from '../components/TopNavigationHeader'
import Boxbtn from '../components/Boxbtn'
import RewardPreviewItem from '../components/RewardPreviewItem'

import shareIconSolid from '../Assets/shareIconSolid.svg';
import downloadIcon from '../Assets/download.svg';
import { useHistory } from 'react-router'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { setHolderDID, setRewardImage, setRemarks, setHolderProfileURL, setHolderName, setHolderImage, setHolderDIDError, setHolderProfileURLError, setHolderNameError, setIssuerProfileURLError, setHolderImageError, setRemarksError, setIssuedDateError, setRewardImageError, setTitle, setTitleError, _vc } from '../redux/signerSlice';
import { VC } from '@zedeid-sdk/sd-vc-lib'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import { VIEWS } from '../utils'
import Spinner from '../components/Spinner'
import Alert from '../components/Alert'


export default function GeneratedRewardPreview() {

    const history = useHistory()
    const vc = useAppSelector(_vc);
    const [vcData, setVcData] = useState<VC>()
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useAppDispatch()
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [shareWithReceiveralertOpen, setShareWithReceiveralertOpen] = useState({ open: false, title: '', content: '' })
    const id = new URLSearchParams(history.location.search).get("requestid");

    useEffect(() => {
        try {
            console.log(vc)
            setVcData(JSON.parse(vc))
            setAlertOpen({ open: true, title: "Success", content: "You have successfully generated the Reward, You can share this with the receiver now." })
        } catch (error) {
            console.log(error)
        }

    }, [])


    function handleBack() {
        history.goBack()
    }

    function shareReward() {
        setIsLoading(true)
        const userToken = sessionStorage.getItem('userToken')
        const { receivingDID } = jwt.decode(userToken as string) as any

        axios.put(`${process.env.REACT_APP_REWARD_BACKEND}reward/credential`, {
            vc: vcData,
            issuerReceivingDID: receivingDID,
            requestid:id
        }).then((res) => {
            setIsLoading(false)

            dispatch(setHolderDIDError(false))
            dispatch(setHolderNameError(false))
            dispatch(setHolderImageError(false))
            dispatch(setHolderProfileURLError(false))
            dispatch(setIssuerProfileURLError(false))
            dispatch(setTitleError(false))
            dispatch(setRemarksError(false))
            dispatch(setIssuedDateError(false))
            dispatch(setRewardImageError(false))
            dispatch(setHolderDID(''))
            dispatch(setHolderProfileURL(''))
            dispatch(setHolderName(''))
            dispatch(setHolderImage(''))
            dispatch(setRemarks(''))
            dispatch(setRewardImage(''))
            dispatch(setTitle(''))

            setShareWithReceiveralertOpen({ open: true, title: "Success", content: "The Reward has been shared successfully" })
            
        }).catch((error) => {
            console.log(error.response.status)
            if(error.response.status === 302){
                setAlertOpen({ open: true, title: "Error", content: "You have shared the exact same reward before" })
            }else{
                setAlertOpen({ open: true, title: "Error", content: "Unknown error" })
            }
            setIsLoading(false)
            
        })
    }

    function handleSave() {
        const blob = new Blob([vc]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `MR_${vcData?.claims?.holderName}_${vcData?.claims?.issuedDate}.vc`,
        );
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
        setAlertOpen({ open: true, title: "Success", content: "You have successfully downloaded the file" })
    }

    function goToReceivedRequest(){
        setAlertOpen({ ...shareWithReceiveralertOpen, open: false })
        history.push(VIEWS.RECEIVEDREQUESTS)
    }

    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="Reward Preview"
                    handleBack={handleBack}
                />
                <div className="wrap-full">

                    <RewardPreviewItem
                        rewardImage={vcData?.claims.rewardBadge}
                        holderImage={vcData?.claims.holderImage}
                        issuerName={vcData?.claims.issuerName || ''}
                        issuedDate={vcData?.claims.issuedDate || ''}
                        holderName={vcData?.claims.holderName || ''}
                        issuerImage={vcData?.claims.issuerImage}
                        rewardTitle={vcData?.claims.title || ''}
                        remarks={vcData?.claims.remarks || ''}
                        holderProfileUrl={vcData?.claims.holderProfileURL || ''}
                        issuerProfileUrl={vcData?.claims.issuerProfileURL || ''}
                        attributesValues={JSON.parse(vcData?.claims.attributesValues || '{}') || {}}
                    />
                    <div className="mg-top-boxbtn"></div>
                    <div className="mg-top-boxbtn"></div>
                    <div className="boxbtn-section mg-top-boxbtn">
                        <Boxbtn icon={shareIconSolid} name="Share With Receiver" onClick={shareReward} />
                        <Boxbtn icon={downloadIcon} name="Save to a File" onClick={handleSave} />
                    </div>
                </div>
            </div>
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
            <Alert
                open={shareWithReceiveralertOpen.open}
                handleClose={goToReceivedRequest}
                handleOpen={() => setAlertOpen({ ...shareWithReceiveralertOpen, open: true })}
                title={shareWithReceiveralertOpen.title}
                content={shareWithReceiveralertOpen.content}
            />
        </React.Fragment>
    );
}

export default function Reward(props:{rewardImage:string | undefined, title:string | undefined, remarks:string | undefined}) {

    return (
        <div className="reward-wrapper">
            <img className="reward-image" src={props.rewardImage} alt="" />

            <div className="card-caption" >
                {props.title}
            </div>

            <div className="remarks card-sub-text" >
                {props.remarks}
            </div>
        </div>
    )
}
import { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setIsVerifid, setVP, _isVerified, _vp } from "../redux/verifierSlice";
import { shareOnLinkedIn, VIEWS } from "../utils";
import { verify, VP } from "@zedeid-sdk/sd-vc-lib";
import axios, { AxiosResponse } from "axios";

import verifysign from '../Assets/rightMarkIcon.svg'
import shareIconSolid from '../Assets/shareIconSolid.svg';

import TopNavigationHeader from "../components/TopNavigationHeader";
import Spinner from "../components/Spinner";
import Alert from "../components/Alert";
import Boxbtn from '../components/Boxbtn'
import RewardPreviewUser from '../components/RewardPreviewUser'
import SmallTransparentBtn from '../components/SmallTransparentBtn'

export default function Verifier() {

    const dispatch = useAppDispatch();
    const history = useHistory()
    const vp: VP = useAppSelector(_vp);
    const { vpId, did } = useParams<any>()
    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })


    useEffect(() => {
        console.log(vpId, did)
        if (vpId !== '' && did !== '') {
            console.log(vpId, did)
            setIsLoading(true)
            axios.get(`${process.env.REACT_APP_REWARD_BACKEND}reward/getPresentation`,
                {
                    params: {
                        id: vpId,
                        did
                    }
                }).then(async (res: AxiosResponse) => {
                    if (res.data) {
                        const vp = res.data
                        dispatch(setVP(JSON.stringify(vp)))
                        const verificationResult = await handeleVerify(vp)
                        dispatch(setIsVerifid(verificationResult))
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        setAlertOpen({ open: true, title: "Error", content: "Unknown error. Try again" })
                    }
                }).catch((err: any) => {
                    setIsLoading(false)
                })

        }
    }, [dispatch, vpId])


    async function handeleVerify(vp: VP) {
        try {
            const holderDID = vp.subject.did
            const issuerDID = vp.credentials[0].issuer.did

            const holderRes = await axios.get(`${process.env.REACT_APP_RESOLVER}${holderDID}`)
            const holderPublicKey = holderRes.data.didDocument.authentication[0].publicKey

            const issuerRes = await axios.get(`${process.env.REACT_APP_RESOLVER}${issuerDID}`)
            const issuerPublicKey = issuerRes.data.didDocument.authentication[0].publicKey

            const verificationResult = await verify(vp, [issuerPublicKey], holderPublicKey)

            return verificationResult

        } catch (err: any) {
            return false
        }
    }


    function handleDone() {
        dispatch(setVP(undefined))
        dispatch(setIsVerifid(false))
        history.push(VIEWS.ONBORDING)
    }

    function handleShowMore() {
        console.log("Clicked")
        history.push(VIEWS.MORE)
    }

    function handleShare() {
        shareOnLinkedIn(did, vpId || "")
    }

    function handleBack() {
        history.push(VIEWS.ONBORDING)
    }

    return (

        <>
            <div className="container container-noback">

                <TopNavigationHeader
                    caption="Verify Reward"
                    handleDone={handleDone}
                    handleBack={handleBack}
                />

                <div className="wrap-full">

                    {/* <label htmlFor="contained-button-file">
            <Button className="button-outlined" component="span">
                Open Reward
            </Button>
        </label> */}

                    <button className="verify-btnHN2">
                        <img className="verify-sign" src={verifysign}></img>Reward Verified
                    </button>

                    {/* <input className="hidden" accept=".vp" id="icon-button-file" type="file" />
        <input
            accept=".vp"
            className="hidden"
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleVPData}
        /> */}



                    {/* <ReceivedRewardPreviewItem  
            badgeImg={badgeGold}
            userImg={u3}
            companyImg={u9}
        /> */}


                    <div className="ReceivedRewardPreview-wrap">
                        <div className="coin-section">
                            <img className="coin-img" src={vp?.credentials?.[0].claims.rewardBadge}></img>
                            <p className="title-txt-blue">{vp?.credentials?.[0].claims.title}</p>
                            <p className="normal-txt-black">{vp?.credentials?.[0].claims.remarks}</p>
                        </div>
                        <RewardPreviewUser
                            heading="Issued to"
                            profileImage={vp?.credentials?.[0].claims.holderImage}
                            userName={vp?.credentials?.[0].claims.holderName}
                            url={vp?.credentials?.[0].claims.holderProfileURL}
                        />
                        <RewardPreviewUser
                            heading="Issued by"
                            profileImage={vp?.credentials?.[0].claims.issuerImage}
                            userName={vp?.credentials?.[0].claims.issuerName}
                            url={vp?.credentials?.[0].claims.issuerProfileURL}
                        />
                        <RewardPreviewUser
                            heading="Issued on"
                            userName={vp?.credentials?.[0].claims.issuedDate}
                        />
                        <SmallTransparentBtn onClick={handleShowMore} txt="See more" />
                    </div>


                    {/* <div className="group">
            {isVerifid ? <Pass className="verification-status" /> : <Fail className="verification-status" />}
            <Reward rewardImage={vp?.credentials?.[0].claims.rewardBadge} remarks={vp?.credentials?.[0].claims.remarks} title={vp?.credentials?.[0].claims.title} />
            <UserDetailCard
                caption="Issued to"
                name={vp?.credentials?.[0].claims.holderName}
                url={vp?.credentials?.[0].claims.holderProfileURL}
                image={vp?.credentials?.[0].claims.holderImage}
            />
            <UserDetailCard
                caption="Issued by"
                name={vp?.credentials?.[0].claims.issuerName}
                url={vp?.credentials?.[0].claims.issuerProfileURL}
                image={vp?.credentials?.[0].claims.issuerImage}
            />
            <div className="profile-card">
                <div className="card-caption">Issued on</div>
                <div className="card-sub-text">
                    {vp?.credentials?.[0].claims.issuedDate}
                </div>
            </div>
        </div>
        <Button onClick={handleShowMore} className="button-filled">
            Show more details
        </Button>
        <a className="link" href="https://rewards.radicalledger.com" target="_blank" rel="noopener noreferrer" >Start issuing rewards or learn more about Micro Rewards</a> */}


                    <div className="boxbtn-section-irp mg-top-boxbtn">
                        <Boxbtn icon={shareIconSolid} name="Share on Linkedin" onClick={handleShare} />
                    </div>

                </div>
            </div>
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </>
    )
}

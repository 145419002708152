import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Onbording from './Views/1.0 Onbording'
import Signin from "./Views/1.1 Signin";
import Signup from "./Views/1.2 Signup";
import Home from './Views/2.0 Home'
import MyProfile from './Views/2.1 MyProfile';
import MyContacts from './Views/2.2 MyContacts';
import ContactsProfile from './Views/2.2.1 ContactsProfile';
import GlobalContactProfile from './Views/2.2.3 GlobalContactProfile';
import ReceivedRequests from './Views/2.4 ReceivedRequests';
import PrepareReward from './Views/2.4.1 PrepareReward'
import ReceivedRewards from './Views/2.5 ReceivedRewards';
import ReceivedRewardPreview from './Views/2.5.1 ReceivedRewardPreview';
import RequestReward from "./Views/2.5.2 RequestReward";
import MyRewards from "./Views/2.6 MyRewards";
import MyRewardPreview from './Views/2.6.1 MyRewardPreview';
import IssuedRewardPreview from './Views/2.6.3 IssuedRewardPreview';
import TrophyCase from './Views/2.7 TrophyCase';
import ReceivedRewardPreviewTrophyCase from './Views/2.7.1 ReceivedRewardPreviewTrophyCase';
import ReceivedRewardPreviewTrophyCasePublic from './Views/2.7.3 ReceivedRewardPreviewPublicTrophyCase';
import TrophyCasePublicView from './Views/2.7.2 TrophyCasePublicView';
import Verifier from './Views/2.8.1 VerifiedReward';
import InviteCollegues from './Views/2.9 InviteCollegues';
import SignedX from './Views/SignedX';
import ViewMyId from './Views/2.1.1 ViewMyId';
import Callback from "./Views/Callback";
import Presenter from './Views/Presenter';
import SeeMore from "./Views/2.8.3 VerificationDetailsVC";
import VerifyDomain from "./Views/5.0 ClaimIDByDomain";
import VerifyReward from './Views/VerifyReward'

import { useAppSelector } from './app/hooks';

import { VIEWS } from './utils'
import { _isIdentityCreated } from './redux/identitySlice';
import { _vc } from './redux/signerSlice';
import { _vp } from './redux/presenterSlice';

import './Style/App.scss'
import './Style/Responsive.scss'
import { _isVerified } from "./redux/verifierSlice";
import GeneratedRewardPreview from "./Views/2.4.1.2 GeneratedRewardPreview";
import Notifications from "./Views/2.3 Notifications";
import BadgeTemplates from "./Views/2.4.1.1 BadgeTemplates";
import RewardCertificateView from "./Views/RewardCertificateView";

export default function App() {

  const vc = useAppSelector(_vc)
  const vp = useAppSelector(_vp)
  const isIdentityCreated = useAppSelector(_isIdentityCreated);

  return (
    <Router>
      <Switch>
        <Route exact path={VIEWS.ONBORDING} >
          {isIdentityCreated ? <Redirect to={VIEWS.HOME} /> : <Onbording />}
        </Route>

        <Route path={`${VIEWS.SIGNIN}/:org?/:invitationId?/:activityInvitationId?`} >
          <Signin />
        </Route>

        <Route path={`${VIEWS.SIGNUP}/:org?/:invitationId?/:activityInvitationId?`} >
          <Signup />
        </Route>

        <Route path={`${VIEWS.VERIFIER}/:did/:vpId`} >
          <Verifier />
        </Route>

        <Route path={VIEWS.HOME} >
          {isIdentityCreated ? <Home /> : <Redirect to={VIEWS.ONBORDING} />}
        </Route>

        <Route path={VIEWS.PROFILE} >
          <MyProfile />
        </Route>

        <Route path={VIEWS.NOTIFICATIONS} >
          <Notifications />
        </Route>

        <Route path={VIEWS.INVITE} >
          <InviteCollegues />
        </Route>

        <Route path={VIEWS.MYCONTACT} >
          <MyContacts />
        </Route>

        <Route path={VIEWS.CONTACTPROFILE} >
          <ContactsProfile />
        </Route>

        {/* <Route path={VIEWS.GLOBALCONTACTS} >
          <AddNewContactFromGc />
        </Route> */}

        <Route path={VIEWS.GLOBALCONTACTPROFILE} >
          <GlobalContactProfile />
        </Route>

        <Route path={VIEWS.MYREWARDS} >
          <MyRewards />
        </Route>

        <Route path={VIEWS.MYREWARDPREVIEW} >
          <MyRewardPreview />
        </Route>

        <Route path={VIEWS.ISSUEDREWARDPREVIEW} >
          <IssuedRewardPreview />
        </Route>

        <Route path={VIEWS.TROPHYCASEPUBLIC} >
          <TrophyCasePublicView />
        </Route>

        <Route path={VIEWS.TROPHYVIEWPUBLIC} >
          <ReceivedRewardPreviewTrophyCasePublic />
        </Route>

        <Route path={VIEWS.TROPHYVIEW} >
          <ReceivedRewardPreviewTrophyCase />
        </Route>

        <Route path={VIEWS.TROPHYCASE} >
          <TrophyCase />
        </Route>

        <Route path={VIEWS.RECEIVEDREQUESTS} >
          <ReceivedRequests />
        </Route>

        <Route path={VIEWS.RECEIVEDREWARDS} >
          <ReceivedRewards />
        </Route>

        <Route path={VIEWS.REQUESTREWARD} >
          <RequestReward />
        </Route>

        {/* <Route path={VIEWS.SEEMORE} >
          <SeeMore />
        </Route> */}

        <Route path={VIEWS.MORE} >
          <SeeMore />
        </Route>

        <Route path={VIEWS.RECEIVEDREWARDPREVIEW} >
          <ReceivedRewardPreview />
        </Route>

        <Route path={VIEWS.ISSUE} >
          {isIdentityCreated ? <PrepareReward /> : <Redirect to={VIEWS.ONBORDING} />}
        </Route>

        <Route path={VIEWS.BADGES} >
          {isIdentityCreated ? <BadgeTemplates /> : <Redirect to={VIEWS.ONBORDING} />}
        </Route>

        <Route path={VIEWS.GENREWARDPREVIEW} >
          <GeneratedRewardPreview />
        </Route>

        <Route path={VIEWS.ACCEPT} >
          {isIdentityCreated ? <Presenter /> : <Redirect to={VIEWS.ONBORDING} />}
        </Route>

        <Route path={VIEWS.SIGNEDVC} >
          {isIdentityCreated && vc !== '' ? <SignedX type="Credential" /> : <Redirect to={VIEWS.ISSUE} />}
        </Route>

        <Route path={VIEWS.SIGNEDVP} >
          {isIdentityCreated && vp !== '' ? <SignedX type="Presentation" /> : <Redirect to={VIEWS.ACCEPT} />}
        </Route>

        <Route path={VIEWS.IDENTITY} >
          <ViewMyId />
        </Route>

        <Route path={VIEWS.VERIFYDOMAIN} >
          <VerifyDomain />
        </Route>

        <Route path={VIEWS.VERIFYREWARD} >
          <VerifyReward />
        </Route>

        <Route path={VIEWS.REWARDCERTIFICATE} >
          <RewardCertificateView />
        </Route>

        <Route path={`${VIEWS.CALLBACK}/:type`} >
          <Callback />
        </Route>

        <Route path="*">
          <h1>404</h1>
        </Route>

      </Switch>
    </Router>
  );
}

import { createStyles, FormControl, InputBase, makeStyles, Theme, withStyles } from "@material-ui/core";

const CustomInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      height: '48px',
      background: '#FFFFFF',
      border: '0.5px solid #e5e5e5',
      boxSizing: 'border-box',
      borderRadius: '8px',
      padding: '9px 12px 8px 12px',
      fontFamily: 'Poppins',
      fontSize: '13px'
    },
  }),
)(InputBase);

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '16px',
    color: '#24C3B5',
    marginTop: '11px',
    marginBottom: '6px',
    fontFamily: 'Poppins'
  },
  formControlWrapper: {
    width: '100%',
    display: 'flex',
    marginBottom: 5
  },
  error: {
    color: 'red',
    margin: 1
  },
  hidden: {
    display: 'none'
  }
}))

export default function CustomTextField(props: { multiline?: boolean, type?: string, value?: string, id?: string, onChange?: any, helperText?: string, fullWidth?: boolean, placeholder?: string, label?: string, error?: boolean, endAdornment?:any }) {

  const classes = useStyles()
  return (
    <FormControl className={classes.formControlWrapper}>
      <p className={classes.label}>
        {props.label}
      </p>
      <CustomInput
        type={props.type as string}
        value={props.value as string}
        onChange={props.onChange}
        fullWidth={props.fullWidth as boolean}
        error={props.error as boolean}
        placeholder={props.placeholder || ''}
        multiline={props.multiline as boolean}
        id={`text-${props.label}`}
        endAdornment={props.endAdornment}
        autoComplete="off"
      />
      <p className={props.error ? classes.error : classes.hidden}>{props.helperText}</p>
    </FormControl>
  )
}

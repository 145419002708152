import React, { Component } from 'react'

function RewardItem(props: {coin: any, rewardDesc: any, blueTxt: any, userimg: any, date: any, onClick?:any}){
    return(
        <React.Fragment>
            <div className="reward-item-squre" onClick={props.onClick}>
                <img src={props.coin} className="coin-img"></img>
                <p className="role-heading">{props.rewardDesc}</p>
                <div className="userdetails mg-top-item">
                    <img src={props.userimg} className="user-img"></img>
                    <p className="user-heading">{props.blueTxt}</p>
                </div>
                <p className="date-txt">{props.date}</p>
            </div>
        </React.Fragment>
    );
}

export default RewardItem;
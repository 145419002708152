import React, { Component } from 'react'

function AcceptRewardListItem(props: {userPropic: any, rewardDesc: any, role: any, actionBtn: any, blueTxt: any, userimg: any, date: any, onSelect?:any, onCancel?:any}){
    return(
        <React.Fragment>
            <div className="reward-item">
                <div className="left-side" onClick={props.onSelect}>
                    <img src={props.userPropic} className="userpropic-icon" ></img>
                    <div className="textArea">
                        <p className="main-heading">{props.rewardDesc}</p>
                        <p className="role-txt">{props.role}</p>
                        <div className="userdetails">
                            <p className="user-name">{props.blueTxt}</p>
                            <img src={props.userimg} className="user-image"></img>
                        </div>
                    </div>
                </div>
                <div className="right-side">
                    <button className="btn-without-background" onClick={props.onCancel}>
                        <img src={props.actionBtn} className="chevronDown-icon"></img>
                    </button>
                </div>
                <p className="date">{props.date}</p>
            </div>
        </React.Fragment>
    );
}

export default AcceptRewardListItem;

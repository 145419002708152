import React, { ChangeEvent, useState } from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import Logo from '../components/Logo'
import Button from '../components/Button'
import TopNavigationHeader from '../components/TopNavigationHeader'

import LinkedinIco from '../Assets/linkedin.svg'
import { VIEWS } from "../utils";
import { setHolderDID, setHolderPrivateKey, setImage, setIsIdentityCreated, setIssuerDID, setIssuerPrivateKey, setMnemonic, setName, _mnemonic } from "../redux/identitySlice";
import Wallet, { Types, validateMnemonic } from "@zedeid-sdk/zedeid-hdk-wallet";
import axios from "axios";
import Alert from "../components/Alert";
import { createHash } from "crypto";
import jwt from 'jsonwebtoken'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { useHistory, useParams } from 'react-router-dom'
import Spinner from '../components/Spinner'

export default function Signin() {

  const dispatch = useAppDispatch();
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isValidMnemonic, setIsValidMnemonic] = useState<boolean>()
  const mnemonic = useAppSelector(_mnemonic)
  const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
  const { org, invitationId, activityInvitationId } = useParams<any>()

  const [validationTxt, setValidationTxt] = useState();


  async function handleSigningWithMnemonic() {
    if (validateMnemonic(mnemonic)) {
      setIsLoading(true)
      const uid: string = createHash('sha256').update(mnemonic).digest('hex')

      try {
        const userTokenResponse: any = await axios.post(`${process.env.REACT_APP_REWARD_BACKEND}user/signin/mnemonic`, { uid, org, invitationId, activityInvitationId })
        if (userTokenResponse.data) {
          const userToken = userTokenResponse.data.token
          sessionStorage.setItem("userToken", userToken);
          const decodedUser: any = jwt.decode(userToken)

          const wallet = new Wallet(Types.MNEMONIC, mnemonic)
          const { privateKey: issuerPrivateKey, did: issuerDID } = wallet.getChildKeys(process.env.REACT_APP_ISSUER_DERIVATION_PATH || 'm/256/256/1')
          const { privateKey: holderPrivateKey, did: holderDID } = wallet.getChildKeys(process.env.REACT_APP_HOLDER_DERIVATION_PATH || 'm/256/256/2')

          try {
            const issuerRes = await axios.get(`${process.env.REACT_APP_RESOLVER}${issuerDID}`)
            const holderRes = await axios.get(`${process.env.REACT_APP_RESOLVER}${holderDID}`)

            if (issuerRes.data && holderRes.data) {
              dispatch(setHolderDID(holderDID))
              dispatch(setHolderPrivateKey(holderPrivateKey as string))
              dispatch(setIssuerDID(issuerDID))
              dispatch(setIssuerPrivateKey(issuerPrivateKey as string))
              dispatch(setIsIdentityCreated(true))
              dispatch(setImage(decodedUser?.photo))
              dispatch(setName(decodedUser?.name))
              history.push(VIEWS.HOME)
            } else {
              setAlertOpen({ open: true, title: "Error", content: "DID documents not found" })
            }
            setIsLoading(false)

          } catch (err: any) {
            setIsLoading(false)
            setAlertOpen({ open: true, title: "Error", content: "Couldn't resolve DID Doc. Try again" })
          }
        } else {
          setAlertOpen({ open: true, title: "Error", content: "Unknown error. Try again" })
        }
      } catch (err: any) {
        if (err.response.data) {
          setAlertOpen({ open: true, title: "Error", content: err.response.data.message })
        } else {
          setAlertOpen({ open: true, title: "Error", content: "Backend server error. Try again" })
        }
      }
      setIsLoading(false)
    } else {
      setAlertOpen({ open: true, title: "Error", content: "Invalid secret ID" })
    }

  }

  function handleSigninWithLinkedin() {
    window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/signin&scope=r_liteprofile`, "_blank")
  }

  function handleSetMenmonic(event: ChangeEvent<HTMLInputElement>) {
    const mnemonic = event.target.value
    dispatch(setMnemonic(mnemonic))
    if (validateMnemonic(mnemonic)) {
      setIsValidMnemonic(true)
    } else {
      setIsValidMnemonic(false)
    }
  }

  const [isKeysGenerated, setIsKeysGenerated] = useState(true)
  const [isInput, setisInput] = useState(true)

  function handleBack() {
    history.push(VIEWS.ONBORDING)
  }

  function goToSignUp() {
    //history.push(`${VIEWS.SIGNUP}/`)
    history.push(`${VIEWS.SIGNUP}/${org ?? org}/${invitationId ?? invitationId}/${activityInvitationId ?? activityInvitationId}`)
  }

  return (
    <React.Fragment>
      <div className="container container-back">
        <TopNavigationHeader
          caption=""
          handleBack={handleBack}
          disabled={isKeysGenerated !== true}
        />
        <Logo />
        <div className="wrap">
          <button onClick={handleSigninWithLinkedin} className="linkedin-btn">
            <img src={LinkedinIco} alt=""></img>Sign In With LinkedIn
          </button>

          <p className="subtitle">Or use ID Secret</p>
          <input className="input-url" type="password" placeholder="ID Secret" onChange={handleSetMenmonic}></input>

          <p className="error-validation-txt">{validationTxt}</p>
          <Button onClick={handleSigningWithMnemonic} name="Submit" disabled={isInput !== true} />
          <a onClick={goToSignUp} className="signInLink">
            <p className="subtitle subtitle-mg-signup">I’m New. I want to sign up</p>
          </a>
        </div>
      </div>
      {isLoading && <Spinner />}
      <Alert
        open={alertOpen.open}
        handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
        handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
        title={alertOpen.title}
        content={alertOpen.content}
      />
    </React.Fragment>
  );
}
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from "@material-ui/core";
import { ChangeEvent, useState } from "react";

export default function AlertConfirmation(props: { id?: string, message: string, content?: string, open: boolean, handleOpen: any, handleClose: any, handleAction:any, yesTxt?:boolean}) {

    function action(){
        props.handleAction()
        props.handleClose()
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby={`${props.id}-alert-dialog-title`}
            aria-describedby={`${props.id}-alert-dialog-description`}
        >
 
            <div className="popupOverLay">
                <div className="popup-wrap">
                    <div className="popup-heading">
                        <p>Alert</p>
                    </div>
                    <p className="popup-body">{props.message}</p>
                    <button className="popup-btn" onClick={action}>{props.yesTxt? "Yes" : "Ok"}</button>
                    <button className="popup-btn" onClick={props.handleClose}>Cancel</button>
                </div>
            </div>

        </Dialog>
    )
}
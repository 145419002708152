import React, { useEffect, useState } from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import TopNavigationHeader from '../components/TopNavigationHeader'
import Boxbtn from '../components/Boxbtn'
import BoxbtnTransparent from '../components/BoxbtnTransparent'
import RewardPreviewItem from '../components/RewardPreviewItem'

import arrowAccept from '../Assets/arrowAccept.svg';
import closeIconGreen from '../Assets/closeIconGreen.svg';
import { useHistory } from "react-router";
import axios from "axios";
import { rewardTypes, shareOnFacebookHelper, VIEWS } from "../utils";
import jwt from "jsonwebtoken";
import { present } from "@zedeid-sdk/sd-vc-lib";
import { useAppSelector } from "../app/hooks";
import { _receivingPrivateKey } from "../redux/identitySlice";
import Spinner from "../components/Spinner";
import PopupQuestion from "../components/PopupQuestion";
import Alert from "../components/Alert";
import Certificate from '../components/Certificate'
import ReceivedRewardPreviewUser from '../components/RewardPreviewUser'


function AcceptReward() {

    const [isKeysGenerated, setIsKeysGenerated] = useState(true)
    const [rewards, setRewards] = useState([])
    const history = useHistory()
    const [popUp, setPopUp] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [isLoading, setIsLoading] = useState(false)

    const userToken = sessionStorage.getItem('userToken')
    const decodedUserToken: any = jwt.decode(userToken as string)
    const uid = decodedUserToken.receivingDID;
    const holderPrivateKey = useAppSelector(_receivingPrivateKey);
    const id = new URLSearchParams(history.location.search).get("id");
    const [showButtons, setShowButtons] = useState(false)


    const [tabBtn, setTabBtn] = useState('')
    const [isCertificateAvailable, setIsCertificateAvailable] = useState(false);
    const [isBadgeAvailable, setIsBadgeAvailable] = useState(false);
    const [displayBadge, setDisplayBadge] = useState(true)
    const [displayCertificate, setDisplayCertificate] = useState(false)

    useEffect(() => {
        setIsLoading(true);
        if (id && id != "undefined") {
            getRewards(uid, id).then(res => {
                setRewards(res.data.credentials);
                console.log(res.data)
                // console.log(JSON.parse(res.data.credentials[0].claims?.certificate))
                //Checking the the certificate is available or not 
                if (!!res.data.credentials[0].claims?.rewardBadge) {
                    setIsBadgeAvailable(true);
                }
                if (!!res.data.credentials[0].claims?.certificate) {
                    setIsCertificateAvailable(true);
                }
                //Checking the active tab
                /* if (res.data.credentials[0]) {
                    setTabBtn('badge');
                } else {
                    setTabBtn('certificate');
                } */
                if (!!res.data.credentials[0].claims?.rewardBadge) {
                    //setTabBtn('badge');

                    displyBatch()
                } else if (!!res.data.credentials[0].claims?.certificate) {
                    //setTabBtn('certificate');

                    displyCertificate()
                }



                setIsLoading(false);
                setShowButtons(true);
            })
        }
    }, []);

    const getRewards = async (did: string, id: string) => {
        const rewards = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}reward/getUnacceptedRewardCredentials`, {
            params: {
                did,
                id
            }
        });
        return rewards;
    };

    const cancelReward = async (did: string, id: string) => {
        const rewards = await axios.delete(`${process.env.REACT_APP_REWARD_BACKEND}reward/declineRewardCredential`, {
            data: {
                did,
                id
            }
        });
        return rewards;
    };

    const acceptRewards = async (did: string, id: string, vp: any) => {
        const rewardsRes = await axios.put(`${process.env.REACT_APP_REWARD_BACKEND}reward/acceptRewardCredential`, {
            did,
            id,
            vp
        });
        return rewardsRes;
    };

    function handleBack() {
        history.goBack();
        console.log('Clicked successfully.');
    }

    function handleDone() {
        console.log('Clicked successfully.');
    }

    function copyOption() {
        navigator.clipboard.writeText(window.location.href)
    }


    function declineReward() {
        setIsLoading(true)
        cancelReward(uid, id || "").then(res => {
            console.log('declined.', res.data);
            setIsLoading(false)
            history.push(VIEWS.RECEIVEDREWARDS);
        }).catch(e => {
            setIsLoading(false)
            console.log(e)
        })
    }

    function acceptRewardFunc() {
        setIsLoading(true)
        const vp = present(holderPrivateKey, rewards as any, [{}]) as any
        acceptRewards(uid, id || "", vp).then(res => {
            setIsLoading(false)
            console.log('accepted', res.data);
            setAlertOpen({ title: "Success", content: "You have successfully signed and accepted the reward, visit My rewards section to view your rewards", open: true });
        }).catch(e => {
            setIsLoading(false)
            console.log(e)
        });
    }

    function seeMore() {
        console.log(id + "type recivedReward")
        history.push(VIEWS.SEEMORE + "?id=" + id + "&type=" + rewardTypes.receivedReward);
    }

    function goToMyRewards() {
        setAlertOpen({ ...alertOpen, open: false })
        history.push(VIEWS.MYREWARDS);
    }

    function displyBatch() {
        setTabBtn('badge')
        setDisplayBadge(true)
        setDisplayCertificate(false)
    }

    function displyCertificate() {
        setTabBtn('certificate')
        setDisplayCertificate(true)
        setDisplayBadge(false)
    }

    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="Received Reward"
                    handleBack={handleBack}
                    handleDone={handleDone}
                    disabled={isKeysGenerated !== true}
                />
                <div className="wrap-full">

                    <div className="tabSection">
                        {isBadgeAvailable && (
                            <button className={tabBtn === 'badge' ? "tabSectionBtnActive" : "tabSectionBtn"} onClick={displyBatch}>Badge</button>
                        )}
                        {isCertificateAvailable && (
                            <button className={tabBtn === 'certificate' ? "tabSectionBtnActive" : "tabSectionBtn"} onClick={displyCertificate}>Certificate</button>
                        )}
                    </div>


                    {displayBadge && (
                        <div className='insideComponentsWrap'>
                            {
                                rewards.map((item: any, index) => (
                                    <RewardPreviewItem
                                        key={index.toString()}
                                        // copyClicked={copyOption}
                                        // copyIcon={copy}
                                        holderName={item.claims.holderName}
                                        rewardImage={item.claims.rewardBadge}
                                        holderImage={item.claims.holderImage}
                                        issuerImage={item.claims.issuerImage}
                                        issuedDate={item.claims.issuedDate}
                                        issuerName={item.claims.issuerName}
                                        holderProfileUrl={item.claims.holderProfileURL}
                                        issuerProfileUrl={item.claims.issuerProfileURL}
                                        remarks={item.claims.remarks}
                                        rewardTitle={item.claims.title}
                                        seeMore={seeMore}
                                        attributesValues={JSON.parse(item.claims.attributesValues || '[]') || []} />
                                ))
                            }
                            {
                                showButtons ? (
                                    <div className="boxbtn-section mg-top-boxbtn">
                                        <BoxbtnTransparent icon={closeIconGreen} name="Decline Reward" onClick={() => { setPopUp(true) }} />
                                        <Boxbtn icon={arrowAccept} name="Accept Reward" onClick={acceptRewardFunc} />
                                    </div>) : null
                            }
                        </div>
                    )}


                    {displayCertificate && (
                        <div className='insideComponentsWrap'>
                            {
                                rewards.map((item: any, index) => {
                                    const certificate = JSON.parse(item.claims.certificate)
                                    certificate['issuedDate'] = item.claims.issuedDate
                                    certificate['issuerImage'] = item.claims.issuerImage
                                    certificate['issuerName'] = item.claims.issuerName
                                    certificate['issuerProfileURL'] = item.claims.issuerProfileURL
                                    certificate['userName'] = item.claims.holderName
                                    certificate['shareLink'] = shareOnFacebookHelper(uid, id || "")
                                    certificate['linkedInUrl'] = item.claims.holderProfileURL

                                    return <Certificate
                                        key={index.toString()}
                                        certificate={certificate}
                                    />
                                })
                            }
                            {
                                !isBadgeAvailable && isCertificateAvailable ?
                                    <>
                                        <div className="ReceivedRewardPreview-wrap" >
                                            {
                                                rewards.map((item: any, index) => (
                                                    <ReceivedRewardPreviewUser
                                                        heading="Issued by"
                                                        profileImage={item.claims.issuerImage}
                                                        userName={item.claims.issuerName}
                                                        url={item.claims.issuerProfileURL}
                                                    />
                                                ))
                                            }
                                        </div>
                                        {
                                            showButtons ? (
                                                <div className="boxbtn-section mg-top-boxbtn">
                                                    <BoxbtnTransparent icon={closeIconGreen} name="Decline Reward" onClick={() => { setPopUp(true) }} />
                                                    <Boxbtn icon={arrowAccept} name="Accept Reward" onClick={acceptRewardFunc} />
                                                </div>) : null
                                        }
                                    </>
                                    : null
                            }
                        </div>
                    )}


                </div>
            </div>
            {isLoading && <Spinner />}
            {
                popUp ? <PopupQuestion onClickConfirm={() => { declineReward() }} onClickCancel={() => { setPopUp(false) }} headingText={"Are you sure you want to remove this reward ?"} bodyMessage={"You won't be able to revert this action"} buttonTxtCancel={"NO"} buttonTxtConfirm={"YES"} />
                    : null
            }
            <Alert
                open={alertOpen.open}
                handleClose={goToMyRewards}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </React.Fragment>
    );
}

export default AcceptReward;

import React, { useEffect, useRef, useState } from 'react'
import { renderToStaticMarkup, renderToString } from 'react-dom/server'
import ReactDOM from 'react-dom'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import { FacebookShareButton } from 'react-share'

import TopNavigationHeader from '../components/TopNavigationHeader'
import Boxbtn from '../components/Boxbtn'
import RewardPreviewItem from '../components/RewardPreviewItem'
import Button from '../components/Button'

import copy from '../Assets/copy.svg';
import plusCircle from '../Assets/plusCircle.svg';
import shareIconSolid from '../Assets/shareIconSolid.svg';
import { useHistory } from 'react-router'
import axios from "axios";
import { shareOnFacebookHelper, shareOnLinkedIn, VIEWS } from "../utils";
import jwt from "jsonwebtoken";
import Spinner from "../components/Spinner";
import { present, VC } from '@zedeid-sdk/sd-vc-lib'
import { _receivingPrivateKey } from '../redux/identitySlice'
import { useAppSelector } from '../app/hooks'
import Alert from "../components/Alert";
import ShareComponent from '../components/ShareComponent'
import Certificate from '../components/Certificate'
import CertificateSignature from '../Assets/certificateSignature.png'
import ReceivedRewardPreviewUser from '../components/RewardPreviewUser'

export const templates = [
    { name: "Default Arrangement", stylesheet: 'Template1.styles.css' },
    { name: "Certificate of Mastery", stylesheet: 'Template2.styles.css' },
    { name: "Certificate of Mastery 2", stylesheet: 'Template3.styles.css' },
    { name: "Certificate of Mind Bait", stylesheet: 'Template4.styles.css' },
];

function MyRewardPreview() {

    const history = useHistory()
    const [rewards, setRewards] = useState([])
    const userToken = sessionStorage.getItem('userToken')
    const decodedUserToken: any = jwt.decode(userToken as string)
    const uid = decodedUserToken.receivingDID;
    const id = new URLSearchParams(history.location.search).get("id");
    const [isLoading, setIsLoading] = useState(false)
    const holderPrivateKey = useAppSelector(_receivingPrivateKey);
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [showButtons, setShowButtons] = useState(false)
    const [copyText, setCopyText] = useState(false);
    const [shareOn, setShareOn] = useState(false);
    const [copiedTooltip, setCopiedTooltip] = useState(false)
    const [addToLinkedInUrl, setAddToLinkedInUrl] = useState('')

    const [tabBtn, setTabBtn] = useState('')
    const [isCertificateAvailable, setIsCertificateAvailable] = useState(false);
    const [isBadgeAvailable, setIsBadgeAvailable] = useState(false);
    const [displayBadge, setDisplayBadge] = useState(true)
    const [displayCertificate, setDisplayCertificate] = useState(false)

    const fbShareRef = useRef<HTMLButtonElement>(null);
    const certificateRef = useRef();


    useEffect(() => {
        setIsLoading(true);
        if (id && id != "undefined") {
            getRewards(uid, id).then(res => {
                console.log(res.data);
                res.data.accepted_credentials[0].on_trophy = false;
                res.data.trophy_case.forEach((reward: any) => {
                    if (reward._id === res.data.accepted_credentials[0]._id) {
                        res.data.accepted_credentials[0].on_trophy = true;
                        // @ts-ignore
                    }
                });
                //Checking the the badge is available or not 
                if (!!res.data.accepted_credentials[0].claims?.rewardBadge) {
                    setIsBadgeAvailable(true);
                }
                //Checking the the certificate is available or not 
                if (!!res.data.accepted_credentials[0].claims?.certificate) {
                    setIsCertificateAvailable(true);
                }
                //Checking the active tab
                /* if (res.data.accepted_credentials[0]) {
                    setTabBtn('badge');
                } else {
                    setTabBtn('certificate');
                } */
                if (!!res.data.accepted_credentials[0].claims?.rewardBadge) {
                    //setTabBtn('badge');

                    displyBatch()
                } else if (!!res.data.accepted_credentials[0].claims?.certificate) {
                    //setTabBtn('certificate');

                    displyCertificate()
                }

                setRewards(res.data.accepted_credentials);
                setIsLoading(false);
                setShowButtons(true);
            })
        }
        //console.log(rewards)
    }, []);

    useEffect(() => {
        if (rewards[0]) {
            try {
                const item: any = rewards[0];
                const certificate = JSON.parse(item.claims.certificate)
                const date = new Date(certificate.DATE)
                const certUrl: any = shareOnFacebookHelper(uid, id || "")
                console.log(date.getMonth())
                const url = new URL("https://www.linkedin.com/profile/add")
                url.searchParams.append('startTask', 'CERTIFICATION_NAME')
                url.searchParams.append('name', certificate.title)
                url.searchParams.append('organizationName', item.claims.issuerName)
                url.searchParams.append('issueYear', date.getFullYear().toString())
                url.searchParams.append('issueMonth', (date.getMonth() + 1).toString())
                url.searchParams.append('certUrl', certUrl)

                if (url) setAddToLinkedInUrl(url.href)
            } catch (error) {
                console.log(error)
            }
        };

    }, [rewards])


    const getRewards = async (did: string, id: string) => {
        const rewards = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}reward/getAcceptedRewardCredentials`, {
            params: {
                did,
                id
            }
        });
        return rewards;
    };

    const addToCase = async (did: string, id: string) => {
        const rewards = await axios.put(`${process.env.REACT_APP_REWARD_BACKEND}reward/addToTrophyCase`, {
            did,
            id
        });
        return rewards;
    };

    function handleBack() {
        history.goBack()
    }

    function copyOption() {
        const uri = `/${uid}/${id}`
        const url = `${process.env.REACT_APP_REWARD_BACKEND}rewardPage${uri}`
        console.log(url)
        navigator.clipboard.writeText(url)
        setCopyText(true)
        setTimeout(() => {
            setCopyText(false)
        }, 2000)
    }


    function addToTrphyCase() {
        setIsLoading(true)
        addToCase(uid, id || "").then(res => {
            setIsLoading(false);
            setAlertOpen({ open: true, title: 'Success', content: 'Reward added to Favorite Rewards.' });
            console.log(res)
        }).catch(e => {
            console.log(e)
        })

    }

    function shareOnLinkedin() {
        shareOnLinkedIn(uid, id || "")
    }

    function shareOnFacebook() {
        return shareOnFacebookHelper(uid, id || "")
    }

    async function handleSaveBadge() {
        try {
            //@ts-ignore
            const { subject, issuer, type, claims, proof } = { ...rewards[0] }
            const vc: VC = { subject, issuer, type, claims, proof } //rewards[0] as VC
            const vp = present(holderPrivateKey, [vc], [{}]) as any

            var WinPrint: any = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            WinPrint.document.write(`<link rel="stylesheet" href="/certificate-template/styles.css">`);
            WinPrint.document.title = `MR_${vp?.credentials?.[0].claims.holderName}_${vp?.credentials?.[0].claims.issuedDate}.pdf`;
            WinPrint.document.write(`<img src='${vp?.credentials?.[0].claims.rewardBadge}' />`);
            WinPrint.document.close();
            WinPrint.setTimeout(async function () {
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();

                setAlertOpen({ open: true, title: 'Success', content: 'You have successfully downloaded the file' });
            }, 1000);

        } catch (error) {
            console.log({ error })
            setAlertOpen({ open: true, title: 'Failed', content: 'Something went wrong' });
        }
    }

    async function handleSaveCert() {
        try {
            //@ts-ignore
            const { subject, issuer, type, claims, proof } = { ...rewards[0] }
            const vc: VC = { subject, issuer, type, claims, proof } //rewards[0] as VC
            const vp = present(holderPrivateKey, [vc], [{}]) as any
            const certificate = JSON.parse(claims.certificate)
            let styles: any = 'Template1.styles.css';

            if (certificate?.template_name) {
                let index = templates.findIndex(i => i.name == certificate?.template_name)
                if (index > -1) styles = templates[index].stylesheet
            }

            const element: any = certificateRef.current
            
            var WinPrint: any = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            WinPrint.document.write(`<link rel="stylesheet" href="/certificate-template/${styles}"/ >`);
            WinPrint.document.title = `MR_${vp?.credentials?.[0].claims.holderName}_${vp?.credentials?.[0].claims.issuedDate}.pdf`;
            WinPrint.document.write(element.innerHTML);
            WinPrint.document.close();
            WinPrint.setTimeout(async function () {
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();

                setAlertOpen({ open: true, title: 'Success', content: 'You have successfully downloaded the file' });
            }, 1000);

        } catch (error) {
            console.log({ error })
            setAlertOpen({ open: true, title: 'Failed', content: 'Something went wrong' });
        }
    }

    // save a vp file
    function handleSave1() {
        //@ts-ignore
        const { subject, issuer, type, claims, proof } = { ...rewards[0] }
        const vc: VC = { subject, issuer, type, claims, proof } //rewards[0] as VC
        const vp = present(holderPrivateKey, [vc], [{}]) as any
        const blob = new Blob([JSON.stringify(vp)]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `MR_${vp?.credentials?.[0].claims.holderName}_${vp?.credentials?.[0].claims.issuedDate}.vp`,
        );
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
        setAlertOpen({ open: true, title: 'Success', content: 'You have successfully downloaded the file' });
    }

    function goToMyRewards() {
        setAlertOpen({ ...alertOpen, open: false })
        history.push(VIEWS.MYREWARDS);
    }

    function shareOnDisplay() {
        setShareOn(true);
    }

    function copyURL() {
        setCopiedTooltip(true)
        navigator.clipboard.writeText(shareOnFacebookHelper(uid, id || ""))
        setTimeout(() => {
            setCopiedTooltip(false)
        }, 2000)
    }

    function displyBatch() {
        setTabBtn('badge')
        setDisplayBadge(true)
        setDisplayCertificate(false)
    }

    function displyCertificate() {
        setTabBtn('certificate')
        setDisplayCertificate(true)
        setDisplayBadge(false)
    }

    // @ts-ignore
    return (
        <React.Fragment>
            {shareOn ? (
                <div className="share-component-overLay"></div>
            ) : null}
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="Received Reward"
                    handleBack={handleBack}
                />

                <div className="wrap-full">

                    <div className="tabSection">
                        {isBadgeAvailable && (
                            <button className={tabBtn === 'badge' ? "tabSectionBtnActive" : "tabSectionBtn"} onClick={displyBatch}>Badge</button>
                        )}
                        {isCertificateAvailable && (
                            <button className={tabBtn === 'certificate' ? "tabSectionBtnActive" : "tabSectionBtn"} onClick={displyCertificate}>Certificate</button>
                        )}
                    </div>

                    {displayBadge && (
                        <div className='insideComponentsWrap'>
                            {
                                rewards.map((item: any, index) => (
                                    <RewardPreviewItem
                                        key={index.toString()}
                                        copyClicked={copyOption}
                                        copyIcon={copy}
                                        holderName={item.claims.holderName}
                                        rewardImage={item.claims.rewardBadge}
                                        holderImage={item.claims.holderImage}
                                        issuerImage={item.claims.issuerImage}
                                        issuedDate={item.claims.issuedDate}
                                        issuerName={item.claims.issuerName}
                                        holderProfileUrl={item.claims.holderProfileURL}
                                        issuerProfileUrl={item.claims.issuerProfileURL}
                                        remarks={item.claims.remarks}
                                        rewardTitle={item.claims.title}
                                        attributesValues={JSON.parse(item.claims.attributesValues || '{}') || {}}
                                        copyText={copyText}
                                    />
                                ))
                            }
                            {
                                showButtons ?
                                    <div className="boxbtn-section mg-top-boxbtn">
                                        <Boxbtn icon={plusCircle} name="Add to Favorite Rewards" onClick={addToTrphyCase} disabled={rewards[0] ? rewards[0]["on_trophy"] : true} />
                                        <FacebookShareButton children={undefined} url={shareOnFacebook()} ref={fbShareRef} />
                                        <Boxbtn icon={shareIconSolid} name="Share on" onClick={shareOnDisplay} />
                                    </div>
                                    : null
                            }
                            {
                                showButtons ? <div className="extraMargin-iR-Btn"><Button onClick={handleSaveBadge} name="Save to a File" /></div>
                                    : null
                            }
                            {shareOn ? (
                                <ShareComponent
                                    addToLinkedIn={addToLinkedInUrl}
                                    shareOnLinkedIn={shareOnLinkedin}
                                    shareOnFacebook={() => { fbShareRef!.current?.click() }}
                                    linkValue={shareOnFacebookHelper(uid, id || "")}
                                    clickCopy={copyURL}
                                    closeShareOn={setShareOn}
                                    copiedTooltip={copiedTooltip}
                                />
                            ) : null}
                        </div>
                    )}

                    {displayCertificate && (
                        <div className='insideComponentsWrap'>
                            {
                                rewards.map((item: any, index) => {
                                    const certificate = JSON.parse(item.claims.certificate)
                                    certificate['issuedDate'] = item.claims.issuedDate
                                    certificate['issuerImage'] = item.claims.issuerImage
                                    certificate['issuerName'] = item.claims.issuerName
                                    certificate['issuerProfileURL'] = item.claims.issuerProfileURL
                                    certificate['userName'] = item.claims.holderName
                                    certificate['shareLink'] = shareOnFacebookHelper(uid, id || "")
                                    certificate['linkedInUrl'] = item.claims.holderProfileURL

                                    return <Certificate
                                        templateRef={certificateRef}
                                        key={index.toString()}
                                        certificate={certificate}
                                    />
                                })
                            }

                            {
                                !isBadgeAvailable && isCertificateAvailable ?
                                    <>
                                        <div className="ReceivedRewardPreview-wrap" style={{ marginTop: '30px' }} >
                                            {
                                                rewards.map((item: any, index) => (
                                                    <ReceivedRewardPreviewUser
                                                        heading="Issued by"
                                                        profileImage={item.claims.issuerImage}
                                                        userName={item.claims.issuerName}
                                                        url={item.claims.issuerProfileURL}
                                                    />
                                                ))
                                            }
                                        </div>
                                        {
                                            showButtons ?
                                                <div className="boxbtn-section mg-top-boxbtn">
                                                    <Boxbtn icon={plusCircle} name="Add to Favorite Rewards" onClick={addToTrphyCase} disabled={rewards[0] ? rewards[0]["on_trophy"] : true} />
                                                    <FacebookShareButton children={undefined} url={shareOnFacebook()} ref={fbShareRef} />
                                                    <Boxbtn icon={shareIconSolid} name="Share on" onClick={shareOnDisplay} />
                                                </div>
                                                : null
                                        }
                                        {
                                            shareOn ? (
                                                <ShareComponent
                                                    addToLinkedIn={addToLinkedInUrl}
                                                    shareOnLinkedIn={shareOnLinkedin}
                                                    shareOnFacebook={() => { fbShareRef!.current?.click() }}
                                                    linkValue={shareOnFacebookHelper(uid, id || "")}
                                                    clickCopy={copyURL}
                                                    closeShareOn={setShareOn}
                                                    copiedTooltip={copiedTooltip}
                                                />
                                            ) : null
                                        }
                                    </>
                                    : null
                            }
                            {
                                showButtons ? <div className="extraMargin-iR-Btn"><Button onClick={handleSaveCert} name="Save to a File" /></div>
                                    : null
                            }
                        </div>
                    )}

                    <Alert
                        open={alertOpen.open}
                        handleClose={goToMyRewards}
                        handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                        title={alertOpen.title}
                        content={alertOpen.content}
                    />

                </div>

            </div>
            {isLoading && <Spinner />}
        </React.Fragment>
    );
}

export default MyRewardPreview;
import React, { Component } from 'react'


import linkedinBlueColor from '../Assets/linkedinBlueColor.svg';
import likedIn from '../Assets/linkedin.svg';

function UserProfile(props: { userProfilePicture: any, userProfileName: any, linkedIn?: boolean }) {
    const openLikedIn = () => {
        // window.open(props.linkedIn, "_blank")
    };

    return (
        <React.Fragment>
            <div className="user-profile">
                <img src={props.userProfilePicture} className="user-profile-img"></img>
                <div className="user-profile-txt-section">
                    {props.linkedIn ?
                        <>
                            <img src={linkedinBlueColor} onClick={openLikedIn} className={"clickable"}></img>
                            <p className="user-profile-txt clickable" onClick={openLikedIn}>{props.userProfileName}</p>
                        </>

                        : <>
                            {/* <img src={likedIn}></img> */}
                            <p className="user-profile-txt disabled">{props.userProfileName}</p>
                        </>}

                </div>
            </div>
        </React.Fragment>
    );
}

export default UserProfile;

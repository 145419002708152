import React, { ChangeEvent, useState, useEffect } from 'react'
import { Claims, issue } from '@zedeid-sdk/sd-vc-lib'

import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setHolderDID, setRewardImage, setIssuedDate, setRemarks, _holderDID, _holderProfileURL, _rewardImage, _issuedDate, _remarks, setHolderProfileURL, setVC, _issuerProfileURL, setIssuerProfileURL, _holderName, _holderImage, setHolderName, setHolderImage, setHolderDIDError, setHolderProfileURLError, setHolderNameError, setIssuerProfileURLError, setHolderImageError, setRemarksError, setIssuedDateError, setRewardImageError, _holderDIDError, _holderImageError, _holderNameError, _holderProfileURLError, _issuedDateError, _issuerImageError, _issuerNameError, _issuerProfileURLError, _remarksError, _rewardImageError, _title, _titleError, setTitle, setTitleError, _attributes, setAttributes } from '../redux/signerSlice';
import { VIEWS } from '../utils';
import axios, { AxiosResponse } from 'axios';
import CustomTextField from '../components/CustomTextField';
import ImageUploadField from '../components/ImageUploadField'
import { _image, _rewardingPrivateKey, _name } from '../redux/identitySlice'
import { useHistory } from 'react-router-dom'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import TopNavigationHeader from '../components/TopNavigationHeader'
import Button from '../components/Button'
import SectionHeader from '../components/SectionHeader';
import Spinner from '../components/Spinner';
import ProfileImageUploadField from '../components/ProfileImageUploadField';
import Alert from '../components/Alert';
import jwt from "jsonwebtoken";
import profileImageSample from '../Assets/defaultUser.jpg';
import AddAttribute from '../Assets/AddAttribute.svg';
import AddSectionAttribute from '../components/AddSectionAttribute'
import AddAttributePopUp from '../components/AddAttributePopUp'
import EditAttributePopUp from '../components/EditAttributePopUp';


export default function PrepareReward() {

    const dispatch = useAppDispatch();
    const history = useHistory()
    const holderDID = useAppSelector(_holderDID);
    const holderName = useAppSelector(_holderName);
    const holderImage = useAppSelector(_holderImage);
    const holderProfileURL = useAppSelector(_holderProfileURL);
    const issuerPrivateKey = useAppSelector(_rewardingPrivateKey);
    const issuerName = useAppSelector(_name);
    const issuerImage = useAppSelector(_image);
    const issuerProfileURL = useAppSelector(_issuerProfileURL);
    const issuedDate = useAppSelector(_issuedDate);
    const title = useAppSelector(_title)
    const remarks = useAppSelector(_remarks);
    const rewardImage = useAppSelector(_rewardImage);
    const [isLoading, setIsLoading] = useState(false);
    const profileImage = useAppSelector(_image)
    const attributes = useAppSelector(_attributes)


    const holderDIDError = useAppSelector(_holderDIDError);
    const holderNameError = useAppSelector(_holderNameError);
    const holderImageError = useAppSelector(_holderImageError);
    const holderProfileURLError = useAppSelector(_holderProfileURLError);
    const issuerProfileURLError = useAppSelector(_issuerProfileURLError);
    const issuedDateError = useAppSelector(_issuedDateError);
    const titleError = useAppSelector(_titleError);
    const remarksError = useAppSelector(_remarksError);

    const userToken = sessionStorage.getItem('userToken')
    const decodedUserToken: any = jwt.decode(userToken as string)
    const uid = decodedUserToken.receivingDID;
    const id = new URLSearchParams(history.location.search).get("requestid");

    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })

    useEffect(() => {
        const savedAttributes = sessionStorage.getItem("attributes");
        let attributes;
        if (savedAttributes) {
            attributes =  JSON.parse(savedAttributes);
        } else {
            attributes = {};
        }
        dispatch(setAttributes(attributes))
    }, []);

    const [addAttributePopUpOpen, setAddAttributePopUpOpen] = useState({ open: false, title: '', content: '' });
    const [editAttributePopUpOpen, setEditAttributePopUpOpen] = useState({ open: false, title: '', content: '' });
    // const attributesValues = JSON.stringify(attributes);

    function handelHolderDID(event: ChangeEvent<HTMLInputElement>) {
        dispatch(setHolderDID(event.target.value))
    }

    function handleHolderProfileURL(event: ChangeEvent<HTMLInputElement>) {
        dispatch(setHolderProfileURL(event.target.value))
    }

    function handleHolderName(event: ChangeEvent<HTMLInputElement>) {
        dispatch(setHolderName(event.target.value))
    }

    function handleHolderImage(event: ChangeEvent<HTMLInputElement>) {
        setIsLoading(true)
        const reader = new FileReader();
        if (event.target.files && event.target.files[0]) {
            const img = event.target.files[0];
            reader.onloadend = () => {
                const imageData: string = reader.result as string
                dispatch(setHolderImage(imageData))
                setIsLoading(false)
            }
            reader.readAsDataURL(img);
        }
        setIsLoading(false)
    };

    function handleIssuerProfileURL(event: ChangeEvent<HTMLInputElement>) {
        dispatch(setIssuerProfileURL(event.target.value))
    }

    function handleIssuedDate(event: ChangeEvent<HTMLInputElement>) {
        dispatch(setIssuedDate(event.target.value))
    }

    function handleTitle(event: ChangeEvent<HTMLInputElement>) {
        dispatch(setTitle(event.target.value))
    }

    function handleRemarks(event: ChangeEvent<HTMLInputElement>) {
        dispatch(setRemarks(event.target.value))
    }


    function removeRewardImage() {
        dispatch(setRewardImage(''))
    }

    function removeHolderImage() {
        dispatch(setHolderImage(''))
    }


    function handleGenerate() {
        if (validateInputs()) {
            dispatch(setHolderDIDError(false))
            dispatch(setHolderNameError(false))
            dispatch(setHolderImageError(false))
            dispatch(setHolderProfileURLError(false))
            dispatch(setIssuerProfileURLError(false))
            dispatch(setTitleError(false))
            dispatch(setRemarksError(false))
            dispatch(setIssuedDateError(false))
            dispatch(setRewardImageError(false))

            setIsLoading(true)
            axios.get(`${process.env.REACT_APP_RESOLVER}${holderDID}`).then(async (res: AxiosResponse) => {
                const holderPublicKey = res.data.didDocument.authentication[0].publicKey
                const claims: Claims = {
                    rewardBadge: rewardImage,
                    remarks,
                    title,
                    issuedDate,
                    issuerName,
                    issuerImage,
                    issuerProfileURL,
                    holderName,
                    holderImage,
                    holderProfileURL,
                    attributesValues : JSON.stringify(attributes)
                }
                try {
                    const vc = issue(issuerPrivateKey, claims, holderPublicKey) as any
                    setIsLoading(false)
                    dispatch(setVC(JSON.stringify(vc)))
                    history.push(id ? VIEWS.GENREWARDPREVIEW + '?requestid=' + id : VIEWS.GENREWARDPREVIEW)
                    sessionStorage.removeItem('attributes');
                    setAlertOpen({ open: true, title: "Error", content: "Reward generate successfully." })
                } catch (err: any) {
                    setAlertOpen({ open: true, title: "Error", content: "Signing error. Try again" })
                    setIsLoading(false)
                }
            }).catch(err => {
                if (err.response && err.response.data) {
                    setAlertOpen({ open: true, title: "Error", content: err.response.data.error })
                } else {
                    setAlertOpen({ open: true, title: "Error", content: "Unknown error. Try again" })
                }
                setIsLoading(false)
            })
        } else {
            setAlertOpen({ open: true, title: "Enter all fields", content: "Enter all fields and images to issue a reward" })
            dispatch(setHolderDIDError(holderDID === ""))
            dispatch(setHolderNameError(holderName === ""))
            dispatch(setHolderImageError(holderImage === ""))
            dispatch(setHolderProfileURLError(holderProfileURL === ""))
            dispatch(setIssuerProfileURLError(issuerProfileURL === ""))
            dispatch(setTitleError(title === ""))
            dispatch(setRemarksError(remarks === ""))
            dispatch(setIssuedDateError(issuedDate === ""))
            dispatch(setRewardImageError(rewardImage === ""))
        }
    }

    function handleDone() {
        try {
            sessionStorage.removeItem('attributes');
        } catch (error) {
        }
        dispatch(setHolderDIDError(false))
        dispatch(setHolderNameError(false))
        dispatch(setHolderImageError(false))
        dispatch(setHolderProfileURLError(false))
        dispatch(setIssuerProfileURLError(false))
        dispatch(setTitleError(false))
        dispatch(setRemarksError(false))
        dispatch(setIssuedDateError(false))
        dispatch(setRewardImageError(false))
        dispatch(setHolderDID(''))
        dispatch(setHolderProfileURL(''))
        dispatch(setHolderName(''))
        dispatch(setHolderImage(''))
        dispatch(setIssuerProfileURL(''))
        dispatch(setRemarks(''))
        dispatch(setRewardImage(''))
        history.push(VIEWS.HOME)
    }

    function handleBack() {
        try {
            sessionStorage.removeItem('attributes');
        } catch (error) {
        }
        dispatch(setHolderDIDError(false))
        dispatch(setHolderNameError(false))
        dispatch(setHolderImageError(false))
        dispatch(setHolderProfileURLError(false))
        dispatch(setIssuerProfileURLError(false))
        dispatch(setTitleError(false))
        dispatch(setRemarksError(false))
        dispatch(setIssuedDateError(false))
        dispatch(setRewardImageError(false))
        dispatch(setHolderDID(''))
        dispatch(setHolderProfileURL(''))
        dispatch(setHolderName(''))
        dispatch(setHolderImage(''))
        dispatch(setIssuerProfileURL(''))
        dispatch(setRemarks(''))
        dispatch(setRewardImage(''))
        history.goBack()
    }

    function handleCancel() {
        try {
            sessionStorage.removeItem('attributes');
        } catch (error) {
        }
        dispatch(setHolderDIDError(false))
        dispatch(setHolderNameError(false))
        dispatch(setHolderImageError(false))
        dispatch(setHolderProfileURLError(false))
        dispatch(setIssuerProfileURLError(false))
        dispatch(setTitleError(false))
        dispatch(setRemarksError(false))
        dispatch(setIssuedDateError(false))
        dispatch(setRewardImageError(false))
        dispatch(setHolderDID(''))
        dispatch(setHolderProfileURL(''))
        dispatch(setHolderName(''))
        dispatch(setHolderImage(''))
        dispatch(setIssuerProfileURL(''))
        dispatch(setRemarks(''))
        dispatch(setRewardImage(''))
        history.push(VIEWS.HOME)
    }

    function validateInputs(): boolean {
        if (
            holderDID === "" ||
            holderName === "" ||
            holderImage === "" ||
            holderProfileURL === "" ||
            issuerPrivateKey === "" ||
            issuedDate === "" ||
            issuerProfileURL === "" ||
            title === "" ||
            remarks === ""
        ) {
            return false
        } else {
            return true
        }
    }

    function goToBagesTemplates() {
        history.push(VIEWS.BADGES)
    }

    function addSection() {
        if (Object.keys(attributes).length > 9) {
            setAddAttributePopUpOpen({ ...addAttributePopUpOpen, open: false })
            setAlertOpen({ open: true, title: "Alert", content: "You added maximum number of attributes." })
        } else {
            setAddAttributePopUpOpen({ ...addAttributePopUpOpen, open: true })
        }
    }

    function openEditPopUp(key: string){
        setEditAttributePopUpOpen({ ...addAttributePopUpOpen, open: true, content: key })
    }

    const addAttribute = (attribute: any) => {
        if (!attribute.key || /^\s*$/.test(attribute.key) || !attribute.value || /^\s*$/.test(attribute.value)) {
            setAlertOpen({ open: true, title: "Alert", content: "Please enter the all fields." })
            return
        }

        console.log(attribute.key !== 'undefined');
        
        if(attributes.key !== 'undefined'){
            setAlertOpen({ open: true, title: "Alert", content: "Attribute already exists." })
        }

        const newAttributes = {...attributes, [attribute.key]: attribute.value}
        dispatch(setAttributes(newAttributes))
        sessionStorage.setItem("attributes", JSON.stringify(newAttributes));
    }
    

    const updateAttribute = (key: string, newValue: string) => {
        if (!key || /^\s*$/.test(newValue)) {
            return;
        }
        const newAttributes = {...attributes, [key]:newValue}
        dispatch(setAttributes(newAttributes));
        sessionStorage.setItem("attributes", JSON.stringify(newAttributes));
    }

    const removeAttribute = (id: string) => {
        let newAttributes = {...attributes}
        delete newAttributes[id]
        dispatch(setAttributes(newAttributes));
        sessionStorage.setItem("attributes", JSON.stringify(newAttributes));
    }


    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="Issue a Reward"
                    handleDone={handleDone}
                    handleBack={handleBack}
                />

                <div className="wrap-full">

                    <div className="issuerDetails-pr">
                        <SectionHeader title="Issuer details" />
                        <div className="issuedTo">
                            <div className="imgUserPropic">
                                <img className="emptyIssuerImage" src={profileImage || profileImageSample}></img>
                            </div>
                            <div className="details">
                                <CustomTextField
                                    id="issuerName"
                                    placeholder="Issuer Name"
                                    value={issuerName}
                                    onChange={() => { }}
                                    fullWidth
                                    helperText="Enter issuer name"
                                />
                                <CustomTextField
                                    id="issuerName"
                                    placeholder="Linkedln URL"
                                    value={issuerProfileURL}
                                    onChange={handleIssuerProfileURL}
                                    fullWidth
                                    error={issuerProfileURLError}
                                    helperText="Enter issuer profile URL"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="issuerDetails-pr">
                        <SectionHeader title="Receiver Detail" />
                        <CustomTextField
                            id="holderDID"
                            label="Receiver DID"
                            placeholder="Enter the Receiving DID of the Receiver"
                            value={holderDID}
                            onChange={handelHolderDID}
                            fullWidth
                            error={holderDIDError}
                            helperText="Enter receiver DID"
                        />
                        <div className="issuedTo">
                            <div className="imgUserPropic">
                                <ProfileImageUploadField
                                    id="holderImageUpload"
                                    imageData={holderImage}
                                    handleImageData={handleHolderImage}
                                    removeCallback={removeHolderImage}
                                    accept="image/*"
                                    error={holderImageError}
                                    helperText="Image"
                                />
                            </div>
                            <div className="details">
                                <CustomTextField
                                    id="holderName"
                                    placeholder="Receiver Name"
                                    value={holderName}
                                    onChange={handleHolderName}
                                    fullWidth
                                    error={holderNameError}
                                    helperText="Enter receiver name"
                                />

                                <CustomTextField
                                    id="holderProfile"
                                    placeholder="Linkedln URL"
                                    value={holderProfileURL}
                                    onChange={handleHolderProfileURL}
                                    fullWidth
                                    error={holderProfileURLError}
                                    helperText="Enter receiver profile URL"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="issuerDetails-pr">
                        <SectionHeader title="Reward details" />
                        <CustomTextField
                            id="title"
                            label="Reward Title"
                            placeholder="Select a Quick Title or Type one"
                            value={title}
                            onChange={handleTitle}
                            fullWidth
                            error={titleError}
                            helperText="Enter title"
                        />
                        <CustomTextField
                            id="remarks"
                            label="Reason to reward"
                            value={remarks}
                            onChange={handleRemarks}
                            fullWidth
                            error={remarksError}
                            helperText="Enter remarks"
                        />
                        <CustomTextField
                            id="issuedDate"
                            label="Issued Date"
                            type="date"
                            value={issuedDate}
                            onChange={handleIssuedDate}
                            fullWidth
                            error={issuedDateError}
                            helperText="Enter valid date"
                        />
                        <div className="extra-mt-homebtn"></div>

                        {/* Add attribute codes goes from here */}
                        <SectionHeader title="Custom Fields" />
                        <div className="addSection">
                            <AddSectionAttribute
                                attributeList={attributes}
                                updateAttribute={openEditPopUp}
                                removeAttribute={removeAttribute}
                                setAddAttributePopUpOpen={setAddAttributePopUpOpen}
                            />
                            <button onClick={addSection} className="btn-green-with-icon">
                                <img src={AddAttribute} className="btn-green-with-iconImg" />  Insert Additional Attribute
                            </button>
                        </div>
                    </div>

                    <div className="issuerDetails-pr">
                        <SectionHeader title="Select Reward Badge" />
                        <ImageUploadField
                            id="rewardImageUpload"
                            caption=""
                            imageData={rewardImage}
                            onClick={goToBagesTemplates}
                            removeCallback={removeRewardImage}
                            accept=".svg"
                        />
                        <Button onClick={handleGenerate} name="Generate Reward" />
                        <button onClick={handleCancel} className="login-btn">Cancel</button>
                        <div className="bottomExtraMargin"></div>
                    </div>

                    <Alert
                        open={alertOpen.open}
                        handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                        handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                        title={alertOpen.title}
                        content={alertOpen.content}
                    />
                    <AddAttributePopUp
                        open={addAttributePopUpOpen.open}
                        handleClose={() => setAddAttributePopUpOpen({ ...addAttributePopUpOpen, open: false })}
                        handleOpen={() => setAddAttributePopUpOpen({ ...addAttributePopUpOpen, open: true })}
                        title={addAttributePopUpOpen.title}
                        content={addAttributePopUpOpen.content}
                        handleAction={addAttribute}
                    />
                    <EditAttributePopUp
                        open={editAttributePopUpOpen.open}
                        handleClose={() => setEditAttributePopUpOpen({ ...editAttributePopUpOpen, open: false })}
                        handleOpen={() => setEditAttributePopUpOpen({ ...editAttributePopUpOpen, open: true })}
                        title={editAttributePopUpOpen.title}
                        content={editAttributePopUpOpen.content || ""}
                        handleAction={updateAttribute}
                    />
                </div>
            </div>
            {isLoading && <Spinner />}
        </React.Fragment>
    )
}


import { ChangeEvent, Fragment, useState } from "react";

import { useAppDispatch } from '../app/hooks';
import { useHistory, useParams } from "react-router-dom";

import Spinner from "../components/Spinner";
import { VIEWS } from "../utils";
import axios from "axios";
import Wallet, { generateMnemonic, Types } from "@zedeid-sdk/zedeid-hdk-wallet";
import { publicKeyCreate, ecdsaSign } from "secp256k1";
import jwt from 'jsonwebtoken'
import { createHash } from "crypto";
import { setHolderDID, setHolderPrivateKey, setImage, setIsIdentityCreated, setIssuerDID, setIssuerPrivateKey, setMnemonic, setName, setEmail } from "../redux/identitySlice";
import Alert from "../components/Alert";
import TopNavigationHeader from '../components/TopNavigationHeader'
import ProfilePictureDefault from '../Assets/ProfilePictureDefault'


import Logo from '../components/Logo'
import Button from '../components/Button'
import LinkedinIco from '../Assets/linkedin.svg'

export default function Signup() {

    const dispatch = useAppDispatch();
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const userLogged = 'hkfsuhfry77yr43riuhf34fy7fheiwowjf89r4';
    const { org, invitationId, activityInvitationId } = useParams<any>()

    function handleSignupWithLinkedin() {
        window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/signup&scope=r_liteprofile`, "_blank")
    }

    async function handleSignupWithMnemonic() {
        try {
            const mnemonic = generateMnemonic(128)

            const wallet = new Wallet(Types.MNEMONIC, mnemonic)
            const uid: string = createHash('sha256').update(mnemonic).digest('hex')
            const { privateKey: issuerPrivateKey, did: issuerDID } = wallet.getChildKeys(process.env.REACT_APP_ISSUER_DERIVATION_PATH || 'm/256/256/1')
            const { privateKey: holderPrivateKey, did: holderDID } = wallet.getChildKeys(process.env.REACT_APP_HOLDER_DERIVATION_PATH || 'm/256/256/2')

            try {
                setIsLoading(true)
                const issuerChallengeResponse = await axios.post(`${process.env.REACT_APP_RESOLVER}`, { did: issuerDID })
                const { challenge: issuerChallenge } = jwt.decode(issuerChallengeResponse.data.challengeToken) as any

                const issuerResponse = await axios.post(`${process.env.REACT_APP_RESOLVER}`,
                    {
                        did: issuerDID,
                        "challengeResponse": {
                            "publicKey": Buffer.from(publicKeyCreate(Buffer.from(issuerPrivateKey as string, 'hex'))).toString('hex'),
                            "cipherText": Buffer.from(ecdsaSign(
                                Buffer.from(issuerChallenge, 'hex'),
                                Buffer.from(issuerPrivateKey as string, 'hex')).signature).toString('hex'),
                            "jwt": issuerChallengeResponse.data.challengeToken
                        }
                    })

                if (!issuerResponse.data || !issuerResponse.data.did) {
                    return false
                }

                const holderChallengeResponse = await axios.post(`${process.env.REACT_APP_RESOLVER}`, { did: holderDID })
                const { challenge: holderChallenge } = jwt.decode(holderChallengeResponse.data.challengeToken) as any
                const holderResponse = await axios.post(`${process.env.REACT_APP_RESOLVER}`,
                    {
                        did: holderDID,
                        "challengeResponse": {
                            "publicKey": Buffer.from(publicKeyCreate(Buffer.from(holderPrivateKey as string, 'hex'))).toString('hex'),
                            "cipherText": Buffer.from(ecdsaSign(
                                Buffer.from(holderChallenge, 'hex'),
                                Buffer.from(holderPrivateKey as string, 'hex')).signature).toString('hex'),
                            "jwt": holderChallengeResponse.data.challengeToken
                        }
                    })

                const userTokenResponse = await axios.post(`${process.env.REACT_APP_REWARD_BACKEND}user/signup/mnemonic`,
                    {
                        uid,
                        name: userName,
                        email: userEmail,
                        receivingDID: holderDID,
                        photo: ProfilePictureDefault,
                        org,
                        invitationId,
                        activityInvitationId
                    })


                if (issuerResponse.data && holderResponse.data) {
                    if (!userTokenResponse.data) {
                        return setAlertOpen({ open: true, title: "Error", content: "Unknown error. Try again" })
                    }

                    const userToken = userTokenResponse.data.token
                    sessionStorage.setItem("userToken", userToken);
                    const decodedUser: any = jwt.decode(userToken)

                    dispatch(setMnemonic(mnemonic))
                    dispatch(setHolderDID(holderDID))
                    dispatch(setHolderPrivateKey(holderPrivateKey as string))
                    dispatch(setIssuerDID(issuerDID))
                    dispatch(setIssuerPrivateKey(issuerPrivateKey as string))
                    dispatch(setIsIdentityCreated(true))
                    dispatch(setName(userName))
                    dispatch(setEmail(userEmail))
                    dispatch(setImage(decodedUser?.photo))
                    history.push(`${VIEWS.IDENTITY}/${userLogged}`)
                } else {
                    setAlertOpen({ open: true, title: "Error", content: "DID documents not found" })
                }

                setIsLoading(false)

            } catch (err: any) {
                setIsLoading(false)
                if (err?.response?.data?.message) {
                    setAlertOpen({ open: true, title: "Error", content: err?.response?.data?.message })
                } else {
                    setAlertOpen({ open: true, title: "Error", content: "Couldn't resolve DID Doc. Try again" })
                }
            }

        } catch (error: any) {
            setAlertOpen({ open: true, title: "Error", content: error?.response?.data?.message })
        }
    }

    function handleSetName(event: ChangeEvent<HTMLInputElement>) {
        const name = event.target.value
        setUserName(name)
    }

    function handleSetEmail(event: ChangeEvent<HTMLInputElement>) {
        const email = event.target.value
        setUserEmail(email)
    }

    const [isKeysGenerated, setIsKeysGenerated] = useState(true)
    const [isInput, setisInput] = useState(true)

    function handleBack() {
        history.push(VIEWS.ONBORDING)
    }

    function goToSignIn() {
        //history.push(`${VIEWS.SIGNIN}/`)
        history.push(`${VIEWS.SIGNIN}/${org ?? org}/${invitationId ?? invitationId}/${activityInvitationId ?? activityInvitationId}`)
    }

    return (

        <Fragment>
            <div className="container container-back">
                <TopNavigationHeader
                    caption=""
                    handleBack={handleBack}
                    disabled={isKeysGenerated !== true}
                />
                <Logo />
                <div className="wrap">
                    <p className="subtitle exmfbtm"><b>Almost there</b></p>
                    <input type="email" onChange={handleSetEmail} className="normal-input margin-bottom" placeholder="Enter your email" />
                    <input type="text" onChange={handleSetName} className="normal-input" placeholder="Enter your name" maxLength={30} />
                    {/* <p className="error-validation-txt">{validationTxt}</p> */}
                    <Button onClick={handleSignupWithMnemonic} name="Create Account" disabled={isInput !== true} />
                    <div className="extra-mt-homebtn"></div>
                    <a onClick={goToSignIn} className="signInLink">
                        <p className="subtitle subtitle-mg-signup">I have an account, I want to sign in</p>
                    </a>
                    <button onClick={handleSignupWithLinkedin} className="linkedin-btn">
                        <img src={LinkedinIco}></img>Signup with LinkedIn
                    </button>
                </div>
            </div>
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </Fragment>
    )
}

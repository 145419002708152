import { Dialog } from "@material-ui/core";
import { useState, useRef } from "react";

export default function AddAttributePopUp(props: { id?: string, title?: string, content?: string, open: boolean, handleOpen?: any, handleClose?: any, handleAction?:any, handleUpdate?:any}) {
    

    const [inputAttribute, setInputAttribute] = useState('')
    const [inputValue, setInputValue] = useState('')
    const inputRef = useRef(null);


    const handleChangeAttribute = (e:any) => {
        setInputAttribute(e.target.value)
    }

    const handleChangeValue = (e:any) => {
        setInputValue(e.target.value)
    }


    const handleSubmit = (e:any) => {
        e.preventDefault();
        props.handleAction({
            key: inputAttribute,
            value: inputValue
        });
        setInputAttribute('')
        setInputValue('')
        props.handleClose()
    }



    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby={`${props.id}-alert-dialog-title`}
            aria-describedby={`${props.id}-alert-dialog-description`}
        >
 
            <div className="popupOverLay">
                <div className="popup-wrap-addSections">
                    <div className="popup-heading">
                        <p>New Attribute</p>
                    </div>
                    <p className="colorGreen">Please enter an Attribute and Value</p>
                    <div className="exmfbtm"></div>
                    <input 
                        type="text" 
                        className="popup-bold-input" 
                        placeholder="Attribute" 
                        onChange={handleChangeAttribute} 
                        value={inputAttribute}
                        name='attribute'
                        autoComplete="off"
                        ref={inputRef}
                    />
                    <div className="exmfbtm"/>
                    <input 
                        type="text" 
                        className="popup-bold-input" 
                        placeholder="Value" 
                        onChange={handleChangeValue} 
                        value={inputValue}
                        name='value'
                        autoComplete="off"
                        ref={inputRef}
                    />
                    <div className="mg-top-boxbtn"></div>
                    <div className="buttonRow">
                        <button className="popup-btn" onClick={handleSubmit}>Add</button>
                        <button className="popup-btn" onClick={props.handleClose}>Close</button>
                    </div>
                </div>
            </div>

        </Dialog>
    )
}
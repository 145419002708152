import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface IdentityState {
    mnemonic: string;
    image: string;
    name: string;
    email: string;
    receivingPrivateKey: string;
    receivingDID: string;
    rewardingPrivateKey: string;
    rewardingDID: string;
    isIdentityCreated: boolean;
    domainVerified: boolean;
    verifiedDomain: string;
}

let identityState;

try {
    identityState = sessionStorage.getItem("identityState") !== null ? JSON.parse(sessionStorage.getItem("identityState") as string) : undefined
} catch (err) {
    identityState = undefined
}


const initialState: IdentityState = identityState ||
{
    mnemonic: '',
    image: '',
    name: '',
    email: '',
    receivingPrivateKey: '',
    receivingDID: '',
    rewardingPrivateKey: '',
    rewardingDID: '',
    isIdentityCreated: false
};

export const identitySlice = createSlice({
    name: 'identity',
    initialState,
    reducers: {
        setMnemonic: (state, action: PayloadAction<string>) => {
            state.mnemonic = action.payload;
            sessionStorage.setItem("identityState", JSON.stringify({ ...state, mnemonic: action.payload }))
        },
        setImage: (state, action: PayloadAction<string>) => {
            state.image = action.payload;
            sessionStorage.setItem("identityState", JSON.stringify({ ...state, image: action.payload }))
        },
        setName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
            sessionStorage.setItem("identityState", JSON.stringify({ ...state, name: action.payload }))
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
            sessionStorage.setItem("identityState", JSON.stringify({ ...state, email: action.payload }))
        },
        setHolderPrivateKey: (state, action: PayloadAction<string>) => {
            state.receivingPrivateKey = action.payload;
            sessionStorage.setItem("identityState", JSON.stringify({ ...state, holderPrivateKey: action.payload }))
        },
        setHolderDID: (state, action: PayloadAction<string>) => {
            state.receivingDID = action.payload;
            sessionStorage.setItem("identityState", JSON.stringify({ ...state, holderDID: action.payload }))
        },
        setIssuerPrivateKey: (state, action: PayloadAction<string>) => {
            state.rewardingPrivateKey = action.payload;
            sessionStorage.setItem("identityState", JSON.stringify({ ...state, IssuerPrivateKey: action.payload }))
        },
        setIssuerDID: (state, action: PayloadAction<string>) => {
            state.rewardingDID = action.payload;
            sessionStorage.setItem("identityState", JSON.stringify({ ...state, issuerDID: action.payload }))
        },
        setIsIdentityCreated: (state, action: PayloadAction<boolean>) => {
            state.isIdentityCreated = action.payload;
            sessionStorage.setItem("identityState", JSON.stringify({ ...state, isIdentityCreated: action.payload }))
        },
    },
});

export const { setMnemonic, setImage, setName, setEmail, setHolderPrivateKey, setHolderDID, setIssuerPrivateKey, setIssuerDID, setIsIdentityCreated } = identitySlice.actions;

export const _mnemonic = (state: RootState) => state.identity.mnemonic;
export const _image = (state: RootState) => state.identity.image;
export const _name = (state: RootState) => state.identity.name;
export const _email = (state: RootState) => state.identity.email;
export const _receivingPrivateKey = (state: RootState) => state.identity.receivingPrivateKey;
export const _receivingDID = (state: RootState) => state.identity.receivingDID;
export const _rewardingPrivateKey = (state: RootState) => state.identity.rewardingPrivateKey;
export const _rewardingDID = (state: RootState) => state.identity.rewardingDID;
export const _isIdentityCreated = (state: RootState) => state.identity.isIdentityCreated;

export default identitySlice.reducer;

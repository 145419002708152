import React, { useEffect } from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import ContactListItem from '../components/ContactListItem'
import ContactSearchInput from '../components/ContactSearchInput'
import TopNavigationHeader from '../components/TopNavigationHeader'

import search from '../Assets/search.svg';
import chevronDown from '../Assets/chevronDown.svg';
import u1 from '../Assets/u1.png';

import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { VIEWS } from "../utils";
import jwt from 'jsonwebtoken'
import Spinner from "../components/Spinner";


export default function RequestReward(){

    const [contacts, setContacts] = useState([])
    const [filteredContacts, setFilteredContacts] = useState([])
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [noResultFound, setNoResultFound] = useState(false);


 
    useEffect(() => {
        setIsLoading(true);
        const userToken = sessionStorage.getItem('userToken')
        const decodedUserToken:any = jwt.decode(userToken as string)
        const uid = decodedUserToken["_id"]
        getContacts(uid).then(res => {
            console.log(res.data.contacts);
            setContacts(res.data.contacts);
            setFilteredContacts(res.data.contacts);
            setIsLoading(false);
        })
    }, []);

    const getContacts = async (uid: string) => {
        const contacts = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}contacts/getAllContacts`, {
            params: {
                uid
            }
        });
        return contacts;
    };

    function handleBack() {
        history.push(VIEWS.HOME)
    }

    function handleAdd() {
        history.push(VIEWS.GLOBALCONTACTS)
    }
    const onSearch = (event: any) => {
        setNoResultFound(false)
        const name = event.target.value;
        const reg = new RegExp(`^${name}|\\s${name}`);
        const filtered = contacts.filter((item) => (reg.test(item["name"])));
        console.log(filtered);
        setFilteredContacts(filtered);
        if(filtered.length === 0){
            console.log("No results, Please try another name");
            setNoResultFound(true);
        }
        //filter the list by name
    };

    function onPress(receivingDID: string) {
        history.push(VIEWS.CONTACTPROFILE + "?receivingDID=" + receivingDID);
    }

    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="Request a Reward"
                    handleBack={handleBack}
                    handleDone={handleAdd}
                />
                <div className="wrap-full">

                    <p className="blueTextHeading">Select a contact to Request a Reward</p>

                    <ContactSearchInput placehldr="Enter the name" searchIcon={search} onChange={onSearch} />

                    {noResultFound? (<p className="noResultFountTxt">No results, Please try another name</p>):(<p></p>)}

                    {
                        filteredContacts.map((item, index) => (
                            <ContactListItem userPropic={item["photo"] ? item["photo"] : u1} userName={item["name"]} actionBtn={chevronDown} onPress={() => { onPress(item["receivingDID"]) }} key={index.toString()} />
                        ))
                    }
                </div>
            </div>
            {isLoading && <Spinner />}
        </React.Fragment>
    );
}
import React, { Component } from 'react'


function InputLarge(props: {text?: any, placeholder: any, onChange:any}) {
    return(
        <React.Fragment>
            <p className="subtitle">{props.text}</p>
            <input className="input-url" type="text" placeholder={props.placeholder} onChange={props.onChange}></input>
        </React.Fragment>
    );
}

export default InputLarge;
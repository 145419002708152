import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface SignerState {
  holderDID: string;
  holderDIDError: boolean;
  holderName: string;
  holderNameError: boolean;
  holderImage: string;
  holderImageError: boolean;
  holderProfileURL: string;
  holderProfileURLError: boolean;
  issuerName: string;
  issuerNameError: boolean;
  issuerImage: string;
  issuerImageError: boolean;
  issuerProfileURL: string;
  issuerProfileURLError: boolean;
  issuedDate: string;
  issuedDateError: boolean;
  title: string;
  titleError: boolean;
  remarks: string;
  remarksError: boolean;
  rewardImage: string;
  rewardImageError: boolean;
  vc: string;
  attributes: {[key:string]: string};
}

const initialState: SignerState = {
  holderDID: '',
  holderDIDError: false,
  holderName: '',
  holderNameError: false,
  holderImage: '',
  holderImageError: false,
  holderProfileURL: '',
  holderProfileURLError: false,
  issuerName: '',
  issuerNameError: false,
  issuerImage: '',
  issuerImageError: false,
  issuerProfileURL: '',
  issuerProfileURLError: false,
  issuedDate: `${new Date().toISOString().slice(0, 10)}`,
  issuedDateError: false,
  title: '',
  titleError: false,
  remarks: 'Rewarded for',
  remarksError: false,
  rewardImage: '',
  rewardImageError: false,
  vc: '',
  attributes: {},
};

export const signerSlice = createSlice({
  name: 'signer',
  initialState,
  reducers: {
    setHolderDID: (state, action: PayloadAction<string>) => {
      state.holderDID = action.payload;
    },
    setHolderName: (state, action: PayloadAction<string>) => {
      state.holderName = action.payload;
    },
    setHolderImage: (state, action: PayloadAction<string>) => {
      state.holderImage = action.payload;
    },
    setHolderProfileURL: (state, action: PayloadAction<string>) => {
      state.holderProfileURL = action.payload;
    },
    setIssuerName: (state, action: PayloadAction<string>) => {
      state.issuerName = action.payload;
    },
    setIssuerImage: (state, action: PayloadAction<string>) => {
      state.issuerImage = action.payload;
    },
    setIssuerProfileURL: (state, action: PayloadAction<string>) => {
      state.issuerProfileURL = action.payload;
    },
    setIssuedDate: (state, action: PayloadAction<string>) => {
      state.issuedDate = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setTitleError: (state, action: PayloadAction<boolean>) => {
      state.titleError = action.payload;
    },
    setRemarks: (state, action: PayloadAction<string>) => {
      state.remarks = action.payload;
    },
    setRewardImage: (state, action: PayloadAction<string>) => {
      state.rewardImage = action.payload;
    },
    setVC: (state, action: PayloadAction<string>) => {
      state.vc = action.payload;
    },
    setHolderDIDError: (state, action: PayloadAction<boolean>) => {
      state.holderDIDError = action.payload;
    },
    setHolderNameError: (state, action: PayloadAction<boolean>) => {
      state.holderNameError = action.payload;
    },
    setHolderImageError: (state, action: PayloadAction<boolean>) => {
      state.holderImageError = action.payload;
    },
    setHolderProfileURLError: (state, action: PayloadAction<boolean>) => {
      state.holderProfileURLError = action.payload;
    },
    setIssuerNameError: (state, action: PayloadAction<boolean>) => {
      state.issuerNameError = action.payload;
    },
    setIssuerImageError: (state, action: PayloadAction<boolean>) => {
      state.issuerImageError = action.payload;
    },
    setIssuerProfileURLError: (state, action: PayloadAction<boolean>) => {
      state.issuerProfileURLError = action.payload;
    },
    setIssuedDateError: (state, action: PayloadAction<boolean>) => {
      state.issuedDateError = action.payload;
    },
    setRemarksError: (state, action: PayloadAction<boolean>) => {
      state.remarksError = action.payload;
    },
    setRewardImageError: (state, action: PayloadAction<boolean>) => {
      state.rewardImageError = action.payload;
    },
    setAttributes: (state, action: PayloadAction<{[key:string]: string}>) => {
      state.attributes = action.payload;
    },
  },
});

export const { setHolderDID, setIssuedDate, setRemarks,
  setHolderProfileURL, setIssuerProfileURL, setRewardImage,
  setVC, setHolderImage, setHolderName,
  setIssuerImage, setIssuerName, setHolderDIDError,
  setHolderImageError, setHolderNameError, setHolderProfileURLError,
  setIssuedDateError, setIssuerImageError, setIssuerNameError,
  setIssuerProfileURLError, setRemarksError, setRewardImageError,
  setTitle, setTitleError, setAttributes } = signerSlice.actions;

export const _holderDID = (state: RootState) => state.signer.holderDID;
export const _holderName = (state: RootState) => state.signer.holderName;
export const _holderImage = (state: RootState) => state.signer.holderImage;
export const _holderProfileURL = (state: RootState) => state.signer.holderProfileURL;
export const _issuerName = (state: RootState) => state.signer.issuerName;
export const _issuerImage = (state: RootState) => state.signer.issuerImage;
export const _issuerProfileURL = (state: RootState) => state.signer.issuerProfileURL;
export const _issuedDate = (state: RootState) => state.signer.issuedDate;
export const _title = (state: RootState) => state.signer.title;
export const _remarks = (state: RootState) => state.signer.remarks;
export const _rewardImage = (state: RootState) => state.signer.rewardImage;
export const _vc = (state: RootState) => state.signer.vc;
export const _holderDIDError = (state: RootState) => state.signer.holderDIDError;
export const _holderNameError = (state: RootState) => state.signer.holderNameError;
export const _holderImageError = (state: RootState) => state.signer.holderImageError;
export const _holderProfileURLError = (state: RootState) => state.signer.holderProfileURLError;
export const _issuerNameError = (state: RootState) => state.signer.issuerNameError;
export const _issuerImageError = (state: RootState) => state.signer.issuerImageError;
export const _issuerProfileURLError = (state: RootState) => state.signer.issuerProfileURLError;
export const _issuedDateError = (state: RootState) => state.signer.issuedDateError;
export const _titleError = (state: RootState) => state.signer.titleError;
export const _remarksError = (state: RootState) => state.signer.remarksError;
export const _rewardImageError = (state: RootState) => state.signer.rewardImageError;
export const _attributes = (state: RootState) => state.signer.attributes;


export default signerSlice.reducer;

import React, {useEffect, useState} from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import ContactListItem from '../components/ContactListItem'
import TopNavigationHeader from '../components/TopNavigationHeader'
import Button from '../components/Button'
import { io } from "socket.io-client";

import closeIcon from '../Assets/closeIcon.svg';
import u1 from '../Assets/u1.png';
import u6 from '../Assets/u6.png';
import u3 from '../Assets/u3.png';
import u4 from '../Assets/u4.png';
import u5 from '../Assets/u5.png';

import chevronDown from '../Assets/chevronDown.svg';
import { useHistory } from 'react-router'
import axios from "axios";
import jwt from "jsonwebtoken";
import AcceptRewardListItem from "../components/AcceptRewardListItem";
import Alert from "../components/Alert";
import {VIEWS} from "../utils";
import Spinner from "../components/Spinner";
import {setHolderDID, setHolderImage, setHolderName} from "../redux/signerSlice";
import {useAppDispatch} from "../app/hooks";
import PopupQuestion from "../components/PopupQuestion";
import socket from "../socket";


export default function Notifications() {

    const [rewards, setRewards] = useState([])
    const history = useHistory()
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: ''})
    const [isLoading, setIsLoading] = useState(false)
    const [popUp, setPopUp] = useState(false)
    const userToken = sessionStorage.getItem('userToken')
    const decodedUserToken:any = jwt.decode(userToken as string)
    const uid = decodedUserToken.receivingDID;
    const dispatch = useAppDispatch()
    //remove / if doesnot work


    const socketHandler = (data:any)=> {
        console.log('NEW DATA RECIEVED', data)
        setRewards(data);
    }

    useEffect(() => {
        setIsLoading(true);
        console.log(uid)
        getRewards(uid).then(res => {
            setRewards(res.data[0].unread);
            if(res.data[0].unread.length === 0){
                setAlertOpen({ open: true, title: 'Alert', content: 'You don\'t have any new notifications'});
            }
            console.log(res.data[0].unread);
            setIsLoading(false);
        }).catch(err => {
            setAlertOpen({ open: true, title: 'Alert', content: 'You don\'t have any new notifications'});
            console.log(err.message)
        })
        socket.on(uid || "", socketHandler);
        return () => {
            socket.off(uid,socketHandler)
        };
    }, []);

    const getRewards = async (uid: string) => {
        const rewards = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}notifications/getUnreadNotifications`, {
            params: {
                uid
            }
        });
        return rewards;
    };

    function onClickRemove(id:string) {
        setIsLoading(true)
        const userToken = sessionStorage.getItem('userToken')
        const decodedUserToken:any = jwt.decode(userToken as string)
        const receivingDID = decodedUserToken.receivingDID;
        // @ts-ignore
        removeNotification(receivingDID,id ).then((res:any) => {
            console.log('REMOVE RESPONSE',res);
            if(res.data["message"] === "Notification Removed"){
                getRewards(uid).then(res => {
                    setRewards(res.data[0].unread);
                    if(res.data[0].unread.length === 0){
                        setAlertOpen({ open: true, title: 'Alert', content: 'You don\'t have any new notifications' });
                    }
                    console.log(res.data[0].unread);
                    setIsLoading(false)
                })
            }
        })

    };

    const removeNotification = async (uid:string, id:string) => {
        const contact = await axios.delete(`${process.env.REACT_APP_REWARD_BACKEND}notifications/removeNotification`, {
            data: {
                uid,
                id,
            }
        });
        return contact;
    };

    function handleBack() {
        history.push(VIEWS.HOME)
    }

    function onClick(item:any) {
        if(item.remarks){
        //    rewards
            onAcceptReward(item)
        }else{
        //    request
            setPopUp(true);
        }
    }

    function onAcceptReward(item: any) {
        onClickRemove(item._id)
        history.push(VIEWS.RECEIVEDREWARDPREVIEW + "?id=" + item._id);
    }

    function onAcceptRequest(item: any) {
        onClickRemove(item._id)
        dispatch(setHolderDID(item.issuerDID))
        dispatch(setHolderImage(item.issuerImage))
        dispatch(setHolderName(item.issuerName))
        history.push(VIEWS.ISSUE)
    }

    function handleClose(){
        setAlertOpen({ ...alertOpen, open: false })
        history.push(VIEWS.HOME)
    }

    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="Notifications"
                    handleBack={handleBack}
                />
                <div className="wrap-full">

                    <div className="wrapReverse">
                        {
                            rewards.map((item:any,index)=> (
                                <button  key={index.toString()} className="contact-item notifi-full-click" onClick={()=>{onClick(item)}}>
                                <ContactListItem userPropic={item.issuerImage} userName={item.issuerName} greyTxt={item.remarks} blueTxt={item.remarks ? "Sent you a reward":"Sent you a reward request" } actionBtn={closeIcon} onPress={()=>{onClickRemove(item._id)}} clickable={true} onClick={()=>{onClick(item)}}/>
                                </button>
                            ))
                        }
                    </div>
                </div>
                    <Alert
                        open={alertOpen.open}
                        handleClose={handleClose}
                        handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                        title={alertOpen.title}
                        content={alertOpen.content}
                    />
            </div>
            {
                popUp ? <PopupQuestion onClickConfirm={()=>{onAcceptRequest(rewards ? rewards[0]: {})}} onClickCancel={()=>{setPopUp(false)}} headingText={"Do you wish to issue a reward to "+ (rewards ? rewards[0]["issuerName"]: "") + " ?"} bodyMessage={""} buttonTxtCancel={"NO"} buttonTxtConfirm={"YES"}/>
                : null
            }
            {isLoading && <Spinner />}
        </React.Fragment>
    );
}

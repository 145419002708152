import React, {ChangeEvent, useState} from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import Logo from '../components/Logo'
import Button from '../components/Button'
import TopNavigationHeader from '../components/TopNavigationHeader'
import Spinner from '../components/Spinner'
import Alert from "../components/Alert";
import { useHistory } from 'react-router'
import { VIEWS } from '../utils'
import axios from 'axios'

function InviteCollegues(){

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [alertOpenGoToHome, setAlertOpenGoToHome] = useState({ open: false, title: '', content: '' })
    const [email, setEmail] = useState('')


    function handleBack(){
        history.push(VIEWS.HOME)
    }

    function handleSetEmail(event: ChangeEvent<HTMLInputElement>){
        setEmail(event.target.value)
    }

    function sendInvite(){
        setIsLoading(true)
        const token = sessionStorage.getItem('userToken')
        axios.post(`${process.env.REACT_APP_REWARD_BACKEND}invite`, 
        {
            token,
            email
        }
        ).then((res) =>{
            console.log(res)
            setIsLoading(false)
            setAlertOpenGoToHome({ open: true, title: "Success", content: "Invite successfully sent !" })
        })
        .catch((res) => {
            console.log(res)
            setIsLoading(false)
            setAlertOpen({ open: true, title: "Error", content: "Invitation failed, please try again later." })
        })
    }

    function goToHome(){
        setAlertOpenGoToHome({ ...alertOpenGoToHome, open: false })
        history.push(VIEWS.HOME)
    }

    return(
        <React.Fragment>
            <div className="container container-back">
                <TopNavigationHeader
                caption=""
                handleBack={handleBack}
                />
                <Logo />
                <div className="wrap">
                    <p className="bluetxt extra-pd">Invite your Colleagues to Join Micro Rewards</p>
                    <input type="text" onChange={handleSetEmail} className="normal-input" placeholder="Enter Email"></input>
                    <Button onClick={sendInvite} name="Send Invite" disabled={email === ''}/>
                    <div className="extra-mb"></div>
                </div>
            </div>
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
            <Alert
                open={alertOpenGoToHome.open}
                handleClose={goToHome}
                handleOpen={() => setAlertOpenGoToHome({ ...alertOpenGoToHome, open: true })}
                title={alertOpenGoToHome.title}
                content={alertOpenGoToHome.content}
            />
        </React.Fragment>
    );
}

export default InviteCollegues;
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import jwt from 'jsonwebtoken'
import crypto, { createHash } from "crypto";
import Wallet, { Types } from "@zedeid-sdk/zedeid-hdk-wallet";
import { setHolderDID, setHolderPrivateKey, setImage, setIsIdentityCreated, setIssuerDID, setIssuerPrivateKey, setMnemonic, setName, _mnemonic } from "../redux/identitySlice";
import { VIEWS } from "../utils";
import Alert from "../components/Alert";
import Spinner from "../components/Spinner";


export default function Callback() {

    const query = useQuery();
    const dispatch = useAppDispatch();
    const { type } = useParams<any>()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const mnemonic = useAppSelector(_mnemonic)

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    useEffect(() => {
        if (type === 'signin') {
            axios.post(`${process.env.REACT_APP_REWARD_BACKEND}user/signin/linkedin`, { code: query.get('code') })
                .then(async response => {
                    if (response.data && response.data.token) {
                        const userToken = response.data.token
                        sessionStorage.setItem("userToken", userToken);
                        const decodedUser: any = jwt.decode(userToken)
                        const encryptedMnemonic = decodedUser.mnemonic

                        const key = Buffer.from(createHash('sha256').update(decodedUser?.linkedin).digest('hex'), 'hex')
                        const components = encryptedMnemonic.split(':')
                        const iv_from_ciphertext = Buffer.from(components.shift(), 'hex');
                        const decipher = crypto.createDecipheriv('aes256', key, iv_from_ciphertext);
                        let decryptedMnemonic = decipher.update(components.join(':'), 'hex', 'utf8')
                        decryptedMnemonic += decipher.final('utf8')

                        const wallet = new Wallet(Types.MNEMONIC, decryptedMnemonic)
                        const { privateKey: issuerPrivateKey, did: issuerDID } = wallet.getChildKeys(process.env.REACT_APP_ISSUER_DERIVATION_PATH || 'm/256/256/1')
                        const { privateKey: holderPrivateKey, did: holderDID } = wallet.getChildKeys(process.env.REACT_APP_HOLDER_DERIVATION_PATH || 'm/256/256/2')

                        setIsLoading(true)
                        const issuerRes = await axios.get(`${process.env.REACT_APP_RESOLVER}${issuerDID}`)
                        const holderRes = await axios.get(`${process.env.REACT_APP_RESOLVER}${holderDID}`)

                        if (issuerRes.data && holderRes.data) {
                            dispatch(setMnemonic(decryptedMnemonic))
                            dispatch(setHolderDID(holderDID))
                            dispatch(setHolderPrivateKey(holderPrivateKey as string))
                            dispatch(setIssuerDID(issuerDID))
                            dispatch(setIssuerPrivateKey(issuerPrivateKey as string))
                            dispatch(setIsIdentityCreated(true))
                            dispatch(setImage(decodedUser?.photo))
                            dispatch(setName(decodedUser?.name))
                            history.push(VIEWS.HOME)
                        } else {
                            setAlertOpen({ open: true, title: "Error", content: "DID documents not found" })
                        }
                        setIsLoading(false)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setAlertOpen({ open: true, title: "Error", content: "User not found. Please login" })
                })
        } else if (type === 'signup') {
            axios.post(`${process.env.REACT_APP_REWARD_BACKEND}user/signup/linkedin`, { code: query.get('code') })
                .then(async response => {
                    if (response.data && response.data.token) {
                        const userToken = response.data.token
                        sessionStorage.setItem("userToken", userToken);
                        const decodedUser: any = jwt.decode(userToken)
                        const encryptedMnemonic = decodedUser.mnemonic

                        const key = Buffer.from(createHash('sha256').update(decodedUser?.linkedin).digest('hex'), 'hex')
                        const components = encryptedMnemonic.split(':')
                        const iv_from_ciphertext = Buffer.from(components.shift(), 'hex');
                        const decipher = crypto.createDecipheriv('aes256', key, iv_from_ciphertext);
                        let decryptedMnemonic = decipher.update(components.join(':'), 'hex', 'utf8')
                        decryptedMnemonic += decipher.final('utf8')

                        const wallet = new Wallet(Types.MNEMONIC, decryptedMnemonic)
                        const { privateKey: issuerPrivateKey, did: issuerDID } = wallet.getChildKeys(process.env.REACT_APP_ISSUER_DERIVATION_PATH || 'm/256/256/1')
                        const { privateKey: holderPrivateKey, did: holderDID } = wallet.getChildKeys(process.env.REACT_APP_HOLDER_DERIVATION_PATH || 'm/256/256/2')

                        setIsLoading(true)
                        const issuerRes = await axios.get(`${process.env.REACT_APP_RESOLVER}${issuerDID}`)
                        const holderRes = await axios.get(`${process.env.REACT_APP_RESOLVER}${holderDID}`)

                        if (issuerRes.data && holderRes.data) {
                            dispatch(setMnemonic(decryptedMnemonic))
                            dispatch(setHolderDID(holderDID))
                            dispatch(setHolderPrivateKey(holderPrivateKey as string))
                            dispatch(setIssuerDID(issuerDID))
                            dispatch(setIssuerPrivateKey(issuerPrivateKey as string))
                            dispatch(setIsIdentityCreated(true))
                            dispatch(setImage(decodedUser?.photo))
                            dispatch(setName(decodedUser?.name))
                            history.push(VIEWS.HOME)
                        } else {
                            setAlertOpen({ open: true, title: "Error", content: "DID documents not found" })
                        }
                        setIsLoading(false)
                    }
                })
                .catch(error => {
                    console.log(error.response.data.message)
                    if(error.response.data.message === "User exists"){
                        setAlertOpen({ open: true, title: "Error", content: "Already signed up. Sign in instead" })
                    }else{
                        setAlertOpen({ open: true, title: "Error", content: "Unknown error. Try again" })
                    }
                    
                })
        } else if (type === 'change') {
            const token = sessionStorage.getItem('userToken')
            const decodedToken:any = jwt.decode(token as string)
            const uid = decodedToken['_id']
            // sessionStorage.removeItem('userToken')

            const key = Buffer.from(createHash('sha256').update(uid).digest('hex'), 'hex')
            const iv = crypto.randomBytes(16)

            const cipher = crypto.createCipheriv('aes256', key, iv)
            let ciphered = cipher.update(mnemonic, 'utf8', 'hex')
            ciphered += cipher.final('hex')
            const encryptedMnemonic = iv.toString('hex') + ':' + ciphered

            axios.post(`${process.env.REACT_APP_REWARD_BACKEND}user/set/linkedin`, { token, code: query.get('code'), encryptedMnemonic })
                .then(async response => {
                    if (response.data && response.data.token) {
                        const userToken = response.data.token
                        sessionStorage.setItem("userToken", userToken);
                        const decodedUser: any = jwt.decode(userToken)
                        const encryptedMnemonic = decodedUser.mnemonic

                        const key = Buffer.from(createHash('sha256').update(decodedUser?.linkedin).digest('hex'), 'hex')
                        const components = encryptedMnemonic.split(':')
                        const iv_from_ciphertext = Buffer.from(components.shift(), 'hex');
                        const decipher = crypto.createDecipheriv('aes256', key, iv_from_ciphertext);
                        let decryptedMnemonic = decipher.update(components.join(':'), 'hex', 'utf8')
                        decryptedMnemonic += decipher.final('utf8')

                        const wallet = new Wallet(Types.MNEMONIC, decryptedMnemonic)
                        const { privateKey: issuerPrivateKey, did: issuerDID } = wallet.getChildKeys(process.env.REACT_APP_ISSUER_DERIVATION_PATH || 'm/256/256/1')
                        const { privateKey: holderPrivateKey, did: holderDID } = wallet.getChildKeys(process.env.REACT_APP_HOLDER_DERIVATION_PATH || 'm/256/256/2')

                        setIsLoading(true)
                        const issuerRes = await axios.get(`${process.env.REACT_APP_RESOLVER}${issuerDID}`)
                        const holderRes = await axios.get(`${process.env.REACT_APP_RESOLVER}${holderDID}`)

                        if (issuerRes.data && holderRes.data) {
                            dispatch(setMnemonic(decryptedMnemonic))
                            dispatch(setHolderDID(holderDID))
                            dispatch(setHolderPrivateKey(holderPrivateKey as string))
                            dispatch(setIssuerDID(issuerDID))
                            dispatch(setIssuerPrivateKey(issuerPrivateKey as string))
                            dispatch(setIsIdentityCreated(true))
                            dispatch(setImage(decodedUser?.photo))
                            dispatch(setName(decodedUser?.name))
                            history.push(VIEWS.HOME)
                        } else {
                            setAlertOpen({ open: true, title: "Error", content: "DID documents not found" })
                        }
                        setIsLoading(false)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [])

    return (
        <div className="root">
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </div>
    )
}
import { IconButton, makeStyles } from "@material-ui/core"
import { ReactComponent as RemoveButton } from '../SVGs/close.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        justifyContent: 'center',
        background: '#FFFFFF',
        border: 'none',
        boxSizing: 'border-box',
        borderRadius: '8px',
        height: '206px',
        margin: '30px 0px',
        padding: '35px 0px',
        cursor: 'pointer'
    },
    label: {
        fontWeight: 'bold',
        fontSize: '0.9rem',
        lineHeight: '1.5rem',
        color: '#161719'
    },
    labelWrapper: {
        width: '100%'
    },
    pointer: {
        cursor: 'pointer',
        '&:hover': {
        },
    },
    removeIcon: {
        position: 'absolute',
        height: 1,
        width: 1,
        backgroundColor:'#fff',
        margin: 10,
        top: '0.3rem',
        right: 0
    },
    uploadHint: {
        fontSize: '12px',
        lineHeight: '21px',
        textAlign: 'center',
        letterSpacing: '-0.32px',
        color: '#757575',
        fontFamily: 'Poppins',
    },
    placeholderImage:{
        height: 45,
        width: 45
    },
    rewardImage:{
        width:'auto',
        height:200
    }
}))

export default function ImageUploadField(props: { id: string,onClick?:any, handleImageData?: any, imageData: any, accept: string, caption?: string, removeCallback?: any }) {

    const classes = useStyles()

    return (
        <div className={classes.root} onClick={() => props.onClick()}>
            <div className={classes.labelWrapper}>
                <p className={classes.label} hidden={!props.caption}>{props.caption}</p>
            </div>
            {/* <input
                accept={props.accept}
                className="hidden"
                id={props.id}
                type="file"
                onChange={props.handleImageData}
            /> */}
            <label className={`${classes.root} ${classes.pointer} reward-image`} htmlFor={props.id}>

                {!props.imageData ?
                    <>
                        <img className={classes.placeholderImage} src={'./../SVGs/image-placeholder.svg'} alt="" />
                        <div className="spacing"></div>
                        <p className={classes.uploadHint}>Select a Reward Badge</p>
                    </>
                    :
                    <img className={classes.rewardImage} src={props.imageData} alt="" />
                }
            </label>
            <input className="hidden" accept={props.accept} id="icon-button-file" type="file" />
            {props.imageData && props.removeCallback && <IconButton onClick={props.removeCallback} className={`${classes.removeIcon} ${classes.pointer}`}>
                {/* <RemoveButton /> */}
                x
            </IconButton>}
        </div>
    )
}
import React, { useState, useRef, useEffect } from 'react';
import Button from '../components/Button'
import ButtonDisabled from '../components/ButtonDisabled'
import editIconBlack from '../Assets/editIconBlack.svg'
import circleHelp from '../Assets/circle-help.svg'


export default function TxtValueDomainVerify(props:{editBtn:any, generateTxtValue?:any, handleDomain:any, enableEdit:any, domain:string, disabled:any, foxusDomainInput?:any}){


    const [helpTxtValueDomain, setHelpTxtValueDomain] = useState(false)
    const domainTextRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if(!props.disabled){
            domainTextRef.current?.focus()
            // domainTextRef.current?.select()
        }
    })

    function displyTxtHelp(){
        setHelpTxtValueDomain(true)
    }

    function hideTxtHelp(){
        setHelpTxtValueDomain(false)
    }

    return(
        <React.Fragment>

            <div className="issuerDetails">
                <p className="sectionTitleClaimDomain">
                    <span>Domain Details
                        <img onMouseEnter={displyTxtHelp} onMouseLeave={hideTxtHelp} className="helpIcon" src={circleHelp}/>
                    </span>
                </p>
            </div>
            {helpTxtValueDomain?(<div className="txtValueHelpTextDomain">Enter your domain name without the www part <br/>E.g.  yourbusinessname.com</div>):null}

            <div className="blackHeadingsmall">
                <p>Domain Name</p>
                {props.editBtn? (
                    <button className="btn-without-background editIcon-mg-right" onClick={props.enableEdit}>
                        <img className="editIcon" src={editIconBlack}></img>
                    </button>
                ):null}
            </div>
            <input type="text" 
            onChange={props.handleDomain} 
            className="normal-input" 
            placeholder="example.com" 
            value={props.domain} 
            disabled={props.disabled} 
            ref={domainTextRef}>

            </input>
            {props.disabled? (
                <ButtonDisabled onClick={props.generateTxtValue} name="Generate TXT Value" disabled={props.disabled}/>
            ):(
                <Button onClick={props.generateTxtValue} name="Generate TXT Value" disabled={props.disabled}/>
            )}
        </React.Fragment>
    )
}
import React, { useEffect } from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import ContactListItem from '../components/ContactListItem'
import ContactSearchInput from '../components/ContactSearchInput'
import TopNavigationHeader from '../components/TopNavigationHeader'

import chevronDown from '../Assets/chevronDown.svg';
import search from '../Assets/search.svg';
import addDone from '../Assets/addDone.svg';
import adding from '../Assets/adding.svg';

import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { VIEWS } from "../utils";
import jwt from 'jsonwebtoken'
import Spinner from "../components/Spinner";
import Alert from "../components/Alert";



function MyContacts() {

    const [contacts, setContacts] = useState([] as any)
    const [myContacts, setMyContacts] = useState([] as any)
    const [filteredContacts, setFilteredContacts] = useState([])
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [noResultFound, setNoResultFound] = useState(false);
    const [noContacts, setNoContacts] = useState(false);
    const [searchType, setSearchType] = useState("")
    const [searchName, setSearchName] = useState("")
    const userToken = sessionStorage.getItem('userToken')
    const decodedUserToken: any = jwt.decode(userToken as string)
    const uid = decodedUserToken["_id"]
    const did = decodedUserToken["receivingDID"]


    useEffect(() => {
        setIsLoading(true);
        const userToken = sessionStorage.getItem('userToken')
        const decodedUserToken: any = jwt.decode(userToken as string)
        const uid = decodedUserToken["_id"]
        getContacts(uid).then(res => {
            console.log(res.data.contacts);
            setMyContacts(res.data.contacts);
            if (res.data.contacts.length === 0) {
                console.log("You don't have contacts, Please add new contacts.");
                setNoContacts(true);
            }
            setFilteredContacts(res.data.contacts);
            const searchType = new URLSearchParams(history.location.search).get("type");
            const query = new URLSearchParams(history.location.search).get("query") || "";
            if(searchType === "name"){
                setSearchName(query || "")
                onClickSearch(query, res.data.contacts)
            }
            setIsLoading(false);
        })
        setNoContacts(false);
    }, []);

    const onReturn = ()=> {
        getContacts(uid).then(res => {
            setMyContacts(res.data.contacts);
            const searchType = new URLSearchParams(history.location.search).get("type");
            const query = new URLSearchParams(history.location.search).get("query") || "";
            if(searchType === "name"){
                setSearchName(query || "")
                onClickSearch(query, res.data.contacts)
            }
        })
    };

    const getGlobalContacts = async (value: string, type: string) => {
        const contacts = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}contacts/users`, {
            params: {
                type,
                value,
                did
            }
        });
        return contacts;
    };

    const filterByMyContacts = (globalContacts: any[], contactsList?: any[]) => {

        let myContactsList = contactsList ? contactsList : myContacts;
        console.log('MY CONTACTS LIST', myContactsList)
        return globalContacts.map(item => { myContactsList.some((contact: any) => contact["receivingDID"] == item["receivingDID"]) ? item["contact"] = true : item["contact"] = false; return item })
    };

    function onClickSearch(query?: string, contacts?: any[]) {
        setIsLoading(true);
        setSearchType("name");
        if (searchName === "") {
            // setAlertOpen({ open: true, title: "Error", content: "Please enter a name" });
            setIsLoading(false);
        }
        if (searchName || query) {
            getGlobalContacts(searchName ? searchName : query || "", "name").then((res: any) => {
                if (res.data.length > 0) {
                    const filtered = filterByMyContacts(res.data, contacts);
                    setContacts(filtered);
                    setIsLoading(false);
                }
            }).catch(e => {
                setContacts([]);
                console.log(e.response.data.error)
                // setAlertOpen({ open: true, title: "Error", content: e.response.data.error });
                setIsLoading(false);
            });
        }

    }

    const getContacts = async (uid: string) => {
        const contacts = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}contacts/getAllContacts`, {
            params: {
                uid
            }
        });
        return contacts;
    };

    function handleBack() {
        history.push(VIEWS.HOME)
    }

    const handleSetSearchName = (event: any) => {
        const value = event.target.value;
        setSearchName(value.trim());
        onClickSearch();
    };

    function onPress(receivingDID: string, contact: boolean) {
        if (contact) {
            history.push(VIEWS.CONTACTPROFILE + "?receivingDID=" + receivingDID + "&from=gc&type=" + searchType + "&query=" + searchName);
        } else {
            history.push(VIEWS.GLOBALCONTACTPROFILE + "?receivingDID=" + receivingDID + "&from=gc&type=" + searchType + "&query=" + searchName);
        }
    }

    function onAdd(contact: any) {
        setIsLoading(true);
        const userToken = sessionStorage.getItem('userToken')
        const decodedUserToken: any = jwt.decode(userToken as string)
        const uid = decodedUserToken["_id"]
        // @ts-ignore
        addContact(uid, contact["name"], contact["receivingDID"], contact["photo"]).then(res => {
            setIsLoading(false);
            setAlertOpen({ title: "Success", content: "Contact added successfully", open: true })
            setNoContacts(false);
            refreshList()
        }).catch((e: any) => {
            setIsLoading(false);
            setAlertOpen({ title: "Error", content: e.response.data.error, open: true })
            console.log(e.response.data.error)
        })
    }

    const refreshList = () => {
        getContacts(uid).then(res => {
            setMyContacts(res.data.contacts);
            onClickSearch("", res.data.contacts)
        })
    };

    const addContact = async (uid: string, contactName: string, contactDID: string, photo: string) => {
        const response = await axios.put(`${process.env.REACT_APP_REWARD_BACKEND}contacts/addContact`, {
            uid,
            contactName,
            contactDID,
            photo
        });
        return response;
    };

    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="My Contacts"
                    handleBack={handleBack}
                />
                <div className="wrap-full">
                    <ContactSearchInput placehldr="Search for people" searchIcon={search} onChange={handleSetSearchName} onClick={onClickSearch} value={searchName}/>

                    {noResultFound ? (<p className="noResultFountTxt">No results, Please try another name</p>) : (<p></p>)}

                    {noContacts ? (<p className="noResultFountTxt">You do not have any contacts yet, Please add people from global contacts to receive and issue rewards faster</p>) : (<p></p>)}

                    {
                        searchName !== '' &&
                        contacts.map((item: any, index: number) => (
                            <ContactListItem
                                userPropic={item.photo}
                                userName={item.name}
                                actionBtn={item.contact ? addDone : adding}
                                clickable={true}
                                onClick={() => { onPress(item["receivingDID"], item["contact"]) }}
                                onPress={() => { item.contact ? setAlertOpen({ title: "Alert !", content: "Already a contact", open: true }) : onAdd(item) }}
                                key={index.toString()}
                            />))
                    }
                    {
                        searchName === '' &&
                        filteredContacts.map((item, index) => (
                            <ContactListItem
                                userPropic={item["photo"]}
                                userName={item["name"]} actionBtn={chevronDown}
                                onPress={() => { onPress(item["receivingDID"], true) }}
                                key={index.toString()}
                                clickable={true}
                                onClick={() => { onPress(item["receivingDID"], true)}}
                            />
                        ))
                    }
                    <Alert
                        open={alertOpen.open}
                        handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                        handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                        title={alertOpen.title}
                        content={alertOpen.content}
                    />
                </div>
            </div>
            {isLoading && <Spinner />}
        </React.Fragment>
    );
}

export default MyContacts;

import React, {useEffect, useState} from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import RewardItem from '../components/RewardItem'
import UserProfile from '../components/UserProfile'
import Spinner from '../components/Spinner'
import Alert from "../components/Alert";

import logo from '../Assets/logo.svg';
import shareIconDark from '../Assets/shareIconDark.svg';
import badgeGold from '../Assets/badgeGold.svg';
import badgeSilver from '../Assets/badgeSilver.svg';
import u3 from '../Assets/u3.png';
import propic from '../Assets/propic.svg';
import {useHistory} from "react-router";
import axios from "axios";
import {VIEWS} from "../utils";

function TrophyCase(){

    const [isKeysGenerated, setIsKeysGenerated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [contact, setContact] = useState({} as any)
    const history = useHistory()
    const [rewards, setRewards] = useState([]);
    const did = new URLSearchParams(history.location.search).get("did");

    useEffect(() => {
        getGlobalContacts(did || "", "did").then((res: any) => {
            console.log(res.data);
            setContact(res.data);
        })

        getRewards(did|| "").then(res => {
            setRewards(res.data.trophy_case);
            console.log(res.data);
        })

    }, []);

    const getRewards = async (did: string) => {
        const rewards = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}reward/viewTrophyCase`, {
            params: {
                did
            }
        });
        return rewards;
    };

    function shareOption(){
        console.log("Done");
    }

    const getGlobalContacts = async (value: string, type: string) => {
        const contacts = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}contacts/users`, {
            params: {
                type,
                value
            }
        });
        return contacts;
    };

    function onSelect(did:string, id:string) {
        history.push(VIEWS.TROPHYVIEWPUBLIC + "?did=" + did + "&id=" + id);
    }

    return(
        <React.Fragment>
        <div className="container container-noback">
            <img className="header-logo-mini" src={logo}></img>
            <div className="wrap-full">
                <div className="delete-icon">
                    <button onClick={shareOption} className="btn-without-background">
                        <img src={shareIconDark}></img>
                    </button>
                </div>
                <UserProfile userProfilePicture={contact.photo} userProfileName={contact.name}/>

                    <div className="reward-item-wrap mg-top-decrease">
                        {
                            rewards.map((item:any,index)=> (
                                <RewardItem
                                    key={index.toString()}
                                    coin={item.claims.rewardBadge || item.claims.issuerImage}
                                    rewardDesc={item.claims.remarks}
                                    blueTxt={"From " + item.claims.issuerName}
                                    userimg={item.claims.issuerImage}
                                    date={item.claims.issuedDate}
                                    onClick={()=>{onSelect(did || "", item._id)}}
                                />))
                        }
                    </div>
            </div>
        </div>
        {isLoading && <Spinner />}
        <Alert
            open={alertOpen.open}
            handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
            handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
            title={alertOpen.title}
            content={alertOpen.content}
        />
        </React.Fragment>
    );
}

export default TrophyCase;

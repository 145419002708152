import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import TopNavigationHeader from "../components/TopNavigationHeader";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setVC, _vc } from "../redux/signerSlice";
import { VIEWS } from "../utils";
import { setVCData, setVP, _vp } from "../redux/presenterSlice";
import { useHistory } from "react-router-dom";

import axios, { AxiosResponse } from "axios";
import Reward from "../components/Reward";
import { VC, VP } from "@zedeid-sdk/sd-vc-lib";
import UserDetailCard from "../components/UserDetailCard";
import MainCard from "../components/Card";
import Spinner from "../components/Spinner";
import Alert from "../components/Alert";

export default function SignedX(props: { type: 'Credential' | 'Presentation' }) {

    const history = useHistory()
    const vc = useAppSelector(_vc);
    const vp = useAppSelector(_vp);
    const [vpData, setVpData] = useState<VP>()
    const [vcData, setVcData] = useState<VC>()
    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (props.type === 'Credential') {
            try {
                setVcData(JSON.parse(vc))
            } catch (error) {

            }
        } else {
            try {
                setVpData(JSON.parse(vp))
            } catch (error) {
                
            }
        }

    }, [props.type, vc, vcData, vp])

    function handleShare() {
        if (props.type === 'Credential') {
            const url = `mailto: ?subject=Signed Reward Credential&body=${vc}`
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link?.parentNode?.removeChild(link);
        } else {
            setIsLoading(true)
            axios.put(`${process.env.REACT_APP_REWARD_BACKEND}rewardPresentation/`, vpData).then((res: AxiosResponse) => {
                if (res.data) {
                    setIsLoading(false)
                    const url = `${process.env.REACT_APP_REWARD_BACKEND || "https://micro-rewards-app.herokuapp.com/"}rewardPage/${res.data.reward._id}`
                    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, "_blank")
                } else {
                    setIsLoading(false)
                    setAlertOpen({ open: true, title: "Error", content: "Sharing error. Try again" })
                }
            }).catch(err => {
                if (err.response?.data?.code === 11000) {
                    setIsLoading(false)
                    const url = `${process.env.REACT_APP_REWARD_BACKEND || "https://micro-rewards-app.herokuapp.com/"}rewardPage/${err.response?.data?.id}`
                    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, "_blank")
                }
                else {
                    setIsLoading(false)
                    setAlertOpen({ open: true, title: "Error", content: "Sharing error. Try again" })
                }

            })

        }

    }

    function handleSave() {
        if (props.type === 'Credential') {
            const blob = new Blob([vc]);
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `MR_${vcData?.claims?.holderName}_${vcData?.claims?.issuedDate}.vc`,
            );
            document.body.appendChild(link);
            link.click();
            link?.parentNode?.removeChild(link);
        } else {
            const blob = new Blob([vp]);
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `MR_${vpData?.credentials?.[0].claims.holderName}_${vpData?.credentials?.[0].claims.issuedDate}.vp`,
            );
            document.body.appendChild(link);
            link.click();
            link?.parentNode?.removeChild(link);
        }

    }

    function handleBack() {
        if (props.type === 'Credential') {
            history.push(VIEWS.ISSUE)
        } else {
            history.push(VIEWS.ACCEPT)
        }
    }

    function handleDone() {
        dispatch(setVC(''))
        dispatch(setVP(''))
        dispatch(setVCData(''))
        history.push(VIEWS.HOME)
    }

    return (
        <div className="root">
            <TopNavigationHeader
                caption={`Signed Reward ${props.type}`}
                handleDone={handleDone}
                handleBack={handleBack}
            />
            <MainCard>
                {
                    props.type === 'Credential' ? (
                        <>
                            <div className="group">
                                <Reward rewardImage={vcData?.claims.rewardBadge} remarks={vcData?.claims.remarks} title={vcData?.claims.title} />
                            </div>

                            <div className="group">
                                <UserDetailCard
                                    caption="Issued by"
                                    name={vcData?.claims.issuerName}
                                    url={vcData?.claims.issuerProfileURL}
                                    image={vcData?.claims.issuerImage}
                                />
                                <UserDetailCard
                                    caption="Issued to"
                                    name={vcData?.claims.holderName}
                                    url={vcData?.claims.holderProfileURL}
                                    image={vcData?.claims.holderImage}
                                />

                                <div className="profile-card">
                                    <div className="card-caption">Issued on</div>
                                    <div className="card-sub-text">
                                        {vcData?.claims.issuedDate}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) :
                        (
                            <>
                                <div className="group">
                                    <Reward rewardImage={vpData?.credentials?.[0].claims.rewardBadge} remarks={vpData?.credentials?.[0].claims.remarks} title={vpData?.credentials?.[0].claims.title} />
                                </div>

                                <div className="group">
                                    <UserDetailCard
                                        caption="Issued by"
                                        name={vpData?.credentials?.[0].claims.issuerName}
                                        url={vpData?.credentials?.[0].claims.issuerProfileURL}
                                        image={vpData?.credentials?.[0].claims.issuerImage}
                                    />
                                    <UserDetailCard
                                        caption="Issued to"
                                        name={vpData?.credentials?.[0].claims.holderName}
                                        url={vpData?.credentials?.[0].claims.holderProfileURL}
                                        image={vpData?.credentials?.[0].claims.holderImage}
                                    />

                                    <div className="profile-card">
                                        <div className="card-caption">Issued on</div>
                                        <div className="card-sub-text">
                                            {vpData?.credentials?.[0].claims.issuedDate}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                }

                <Button onClick={handleShare} className="button-filled">
                    {props.type === 'Credential' ? 'Share via Email' : 'Share on Linkedin'}
                </Button>
                <div className="spacing"></div>
                <Button onClick={handleSave} className="button-filled">
                    Save to a File
                </Button>

            </MainCard>
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />

        </div>
    )
}

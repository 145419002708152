import React, { useEffect, useState } from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import TopNavigationHeader from '../components/TopNavigationHeader'
import RewardItem from '../components/RewardItem'
import UserProfile from '../components/UserProfile'
import Spinner from '../components/Spinner'
import Alert from "../components/Alert";

import shareIcon from '../Assets/shareIcon.svg';
import badgeGold from '../Assets/badgeGold.svg';
import badgeSilver from '../Assets/badgeSilver.svg';
import u3 from '../Assets/u3.png';
import propic from '../Assets/propic.svg';
import { useHistory } from 'react-router'
import { VIEWS } from '../utils'
import { useAppSelector } from '../app/hooks'
import { _image, _name } from '../redux/identitySlice'
import axios from "axios";
import jwt from "jsonwebtoken";

function TrophyCase() {

    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [rewards, setRewards] = useState([]);
    const history = useHistory()
    const name = useAppSelector(_name)
    const profileImage = useAppSelector(_image)
    const userToken = sessionStorage.getItem('userToken')
    const decodedUserToken: any = jwt.decode(userToken as string)
    const uid = decodedUserToken.receivingDID;
    const [emptyTrophyCase, setEmptyTrophyCase] = useState(false)


    useEffect(() => {
        setIsLoading(true);
        getRewards(uid).then(res => {
            setRewards(res.data.trophy_case);
            if (res.data.trophy_case.length === 0) {
                setEmptyTrophyCase(true);
            }
            console.log(res.data);
            setIsLoading(false);
        })
    }, []);

    const getRewards = async (did: string) => {
        const rewards = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}reward/viewTrophyCase`, {
            params: {
                did
            }
        });
        return rewards;
    };

    function handleBack() {
        history.push(VIEWS.PROFILE)
    }

    function handleShare() {
        const url = `${process.env.REACT_APP_URL}trophyCase/public?did=${uid}`
        navigator.clipboard.writeText(url)
        setAlertOpen({ open: true, title: 'Favorite Rewards', content: 'Link copied to clipboard , share this with anyone to showcase your achievements ! ' })
        console.log(url);
    }

    function onSelect(id: string) {
        history.push(VIEWS.TROPHYVIEW + "?id=" + id);
    }

    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="Favorite Rewards"
                    handleBack={handleBack}
                    handleDone={handleShare}
                    rightIcon={shareIcon}
                />
                <div className="wrap-full">
                    <div className="heading-txt-blue">
                        <p>Its time to show your achievements !</p>
                    </div>
                    <UserProfile userProfilePicture={profileImage || propic} userProfileName={name} />
                    {emptyTrophyCase ? (<p className="emptyTrophyCase">You have not added any rewards to your Favorite Rewards. Please add some rewards from My Rewards section.</p>) : (<p></p>)}
                    <div className="reward-item-wrap mg-top-decrease">
                        {
                            rewards.map((item: any, index) => {
                                return (
                                    <RewardItem
                                        key={index.toString()}
                                        coin={item.claims.rewardBadge || item.claims.issuerImage}
                                        rewardDesc={item.claims.title}
                                        blueTxt={"From " + item.claims.issuerName}
                                        userimg={item.claims.issuerImage}
                                        date={item.claims.issuedDate}
                                        onClick={() => { onSelect(item._id) }}
                                    />)
                            })
                        }
                    </div>
                </div>
            </div>
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </React.Fragment>
    );
}

export default TrophyCase;

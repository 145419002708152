import React, { ChangeEvent } from 'react'
import { useState } from "react";
import { isDID, VIEWS } from "../utils";
import { useHistory } from "react-router-dom";

import Logo from '../components/Logo'
import InputLarge from '../components/InputLarge'
import Button from '../components/Button'
import Spinner from '../components/Spinner'
import Alert from "../components/Alert";
import chevronDown from '../Assets/chevronDown.svg';

export default function Onbording() {

    const history = useHistory()
    const [verificationURL, setVerificationURL] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [isInput, setisInput] = useState(true)

    const [validationTxt, setValidationTxt] = useState();

    function signUp() {
        history.push(VIEWS.SIGNUP)
    }

    function login() {
        history.push(VIEWS.SIGNIN)
    }

    function verify() {
        let elements = verificationURL.split('/')
        const rewardId = elements[elements.length - 1]
        const did = elements[elements.length - 2]
        console.log(rewardId)
        if(isDID(did) && rewardId.length === 64){
            console.log(rewardId)
            history.push(`${VIEWS.VERIFIER}/${did}/${rewardId}`)
        }else{
            setAlertOpen({ open: true, title: "Error", content: "Enter valid verification URL" })
        }
    }

    function handleSetVerificationURL(event: ChangeEvent<HTMLInputElement>) {
        setVerificationURL(event.target.value)
    }

    return (

        <React.Fragment>
            <div className="container container-back">
                <Logo/>
                <div className="wrap">
                    {/* <button onClick={login} className="login-btn">Log In</button> */}
                    <Button onClick={login} name="Log In"/>

                    <div className="extra-mt-onboard"></div>
                    <p className="subtitle"><b>Not a member yet?</b></p>
                    <p className="subtitle">Join today to send and receive verifiable smart rewards for free !</p>
                    <Button onClick={signUp} name="Sign up"/>

                    <div className="extra-mt-homebtn"></div>
                    <p className="subtitle">Check the validity of a reward</p>
                    <InputLarge  
                    placeholder="Enter the Reward URL" 
                    onChange={handleSetVerificationURL} 
                    />
                    <p className="error-validation-txt">{validationTxt}</p>
                    {/* <Button onClick={verify} name="Verify" disabled={isInput !== true}/> */}
                    <button onClick={verify} className="login-btn-onboarding" disabled={isInput !== true}>Verify<img src={chevronDown} /></button>
                    
                    <div className="extra-mt-homebtn"></div>
                    <a className="links" target="_blank" href="https://www.microrewards.zedeid.com/">Click here to find out how it works</a>
                </div>
            </div>
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </React.Fragment>
    )
}

import {
    IconButton,
    makeStyles,
} from "@material-ui/core";

import { ReactComponent as ExpandIcon } from "../SVGs/expand.svg";
import { ReactComponent as CollapseIcon } from "../SVGs/collapse.svg";
import { ReactComponent as CopyIcon } from "../SVGs/copy-primary.svg";
import { ReactComponent as ExportIcon } from "../SVGs/export-primary.svg";

import { useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
    label: {
        fontWeight: 'bold',
        fontSize: '0.9rem',
        lineHeight: '1.5rem',
        color: '#24C3B5',
        marginTop: 0
    },
    formControlWrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '450px',
        marginBottom: 25,
        flexDirection: 'column',
        marginRight: '40px'
    },
    error: {
        color: 'red',
        margin: 1
    },
    hidden: {
        display: 'none'
    },
    contentRow: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        padding: '2px 15px 2px 15px',
        color: '#A19696',
        marginTop: '10px'
    },
    hiddenContent: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    valueLength: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row'
    },
    iconSet: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    collapsibleText: {
        marginTop: 10,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },

    collapsibleTextFalse: {
        marginTop: 10,
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word'
    },
    divider: {
        height: 1,
        backgroundColor: '#757575',
        width: '95%',
        marginTop: 10,
        marginBottom: 5
    },
    subText: {
        color: '#757575',
        fontSize: '12px',
        fontWeight: 'bold'
    }
}));

export default function CollapsibleText(props: { label: string, value: string, onCopy: Function, onExport: Function, error?: string, helperText?: string }) {
    const classes = useStyles();
    const [collapsed, setCollapsed] = useState(true);
    const ref = useRef<any>()
    const [width, setWidth] = useState(50)
    let movementTimer: NodeJS.Timeout;

    useEffect(() => {
        setWidth(ref.current?.offsetWidth as number)
      }, []);

    function middleClip(text:string, length:number){
        if(text.length > length){
            return text.substring(0,length/2 - 1) + '...' + text.substring(text.length - length/2, text.length)
        }
        return text
    }

    window.addEventListener('resize', ()=>{
        console.log(ref.current?.offsetWidth )
        clearInterval(movementTimer);
        movementTimer = setTimeout(() => {
            setWidth(ref.current?.offsetWidth as number)
        }, 100);
      });

    return (
        <div className={classes.formControlWrapper}>
            <p className={classes.label}>
                {props.label}
            </p>
            <div className={classes.contentRow} ref={ref}> 
                <p className={classes.collapsibleTextFalse}>
                    {collapsed ? middleClip(props.value, width/10 - 3) : props.value}
                </p>
                <IconButton onClick={() => {
                    setCollapsed(!collapsed)
                }} >
                    {collapsed ? <ExpandIcon /> : <CollapseIcon />}
                </IconButton>
            </div>
            {!collapsed ?
                <div>
                    <div className={classes.divider}></div>
                    <div className={classes.hiddenContent}>

                        <div className={classes.valueLength}>
                            <p className={classes.subText}> {props.value ? `Length ${props.value.length}` : ""}</p>
                        </div>
                        <div className={classes.iconSet}>
                            <IconButton disabled={props.value === ''} onClick={() => { props.onCopy(props.value) }} >
                                <CopyIcon />
                            </IconButton>
                            <IconButton disabled={props.value === ''} onClick={() => { props.onExport(props.value) }}>
                                <ExportIcon />
                            </IconButton>
                        </div>
                    </div>
                </div> : null
            }
            <p className={props.error ? classes.error : classes.hidden}>{props.helperText}</p>
        </div>

    )
}

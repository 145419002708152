import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from "@material-ui/core";
import { ChangeEvent, useState } from "react";

export default function AlertWithText(props: { id?: string, title: string, content: string, open: boolean, handleOpen: any, handleClose: any, handleAction:any, userNamePreviously?:any}) {

    const [name, setName] = useState('')

    function handleNameInput(event: ChangeEvent<HTMLInputElement>) {
        const name = event.target.value
        setName(name)
    }

    function action(){
        props.handleAction(name)
        props.handleClose()
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby={`${props.id}-alert-dialog-title`}
            aria-describedby={`${props.id}-alert-dialog-description`}
        >
 
            <div className="popupOverLay">
                <div className="popup-wrap">
                    <div className="popup-heading">
                        <p>Change your name</p>
                    </div>
                    <div className="mg-top-boxbtn"></div>
                    <input type="text" className="normal-input" placeholder="Enter your name" onChange={handleNameInput} maxLength={30}></input>
                    <div className="mg-top-boxbtn"></div>
                    <button className="popup-btn" onClick={action}>Ok</button>
                    <button className="popup-btn" onClick={props.handleClose}>Cancel</button>
                </div>
            </div>

        </Dialog>
    )
}
import { ChangeEvent, useEffect, useState } from "react";
import { Button } from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setVCData, setVP, _vcData } from "../redux/presenterSlice";
import { VIEWS } from "../utils";
import { present, VC } from "@zedeid-sdk/sd-vc-lib";
import { _receivingPrivateKey, _receivingDID } from "../redux/identitySlice";
import { useHistory } from "react-router-dom";
import MainCard from "../components/Card";
import TopNavigationHeader from "../components/TopNavigationHeader";
import Reward from "../components/Reward";
import UserDetailCard from "../components/UserDetailCard";
import Alert from "../components/Alert";

export default function Presenter() {

    const dispatch = useAppDispatch();
    const history = useHistory()
    const holderPrivateKey = useAppSelector(_receivingPrivateKey);
    const holderDID = useAppSelector(_receivingDID);
    const vcData = useAppSelector(_vcData);
    const [vc, setVc] = useState<VC>()
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })

    useEffect(() => {
        try {
            const parsedVC:VC = JSON.parse(vcData)
            if(holderDID !== parsedVC.subject.did){
                dispatch(setVCData(''))
                setAlertOpen({ open: true, title: "Error", content: "Not a reward issued to your DID" })
                throw Error()
            }
            setVc(parsedVC)
        } catch (error) {

        }
    }, [dispatch, holderDID, vcData])

    function handleVCData(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files[0]) {

            const reader = new FileReader()
            reader.onload = async (e) => {
                const vc = (e?.target?.result) as string
                dispatch(setVCData(vc))
            };
            reader.readAsText(event.target.files[0])
        }


    };

    function handleGenerate() {
        try {
            const vp = present(holderPrivateKey, [vc as VC], [{}]) as any
            dispatch(setVP(JSON.stringify(vp)))
            history.push(VIEWS.SIGNEDVP)
        } catch (err) {
            setAlertOpen({ open: true, title: "Error", content: "Unknown error. Try again" })
        }
    }

    function handleDone() {
        dispatch(setVP(''))
        dispatch(setVCData(''))
        history.push(VIEWS.HOME)
    }

    function handleBack() {
        history.push(VIEWS.HOME)
    }

    return (
        <div className="root">
            <TopNavigationHeader
                caption="Accepting a Reward"
                handleDone={handleDone}
                handleBack={handleBack}
            />
            <MainCard>
                <input
                    accept=".vc"
                    className="hidden"
                    id="vc-upload"
                    multiple
                    type="file"
                    onChange={handleVCData}
                />
                <label htmlFor="vc-upload">
                    <Button className="button-outlined" component="span">
                        Open Reward
                    </Button>
                </label>

                {vc?
                    <>
                        <div className="group">
                            <Reward rewardImage={vc?.claims.rewardBadge} remarks={vc?.claims.remarks} title={vc?.claims.title}/>
                        </div>

                        <div className="group">

                            <UserDetailCard
                                caption="Issued to"
                                name={vc?.claims.holderName}
                                url={vc?.claims.holderProfileURL}
                                image={vc?.claims.holderImage}
                            />
                            <UserDetailCard
                                caption="Issued by"
                                name={vc?.claims.issuerName}
                                url={vc?.claims.issuerProfileURL}
                                image={vc?.claims.issuerImage}
                            />

                            <div className="profile-card">
                                <div className="card-caption">Issued on</div>
                                <div className="card-sub-text">
                                    {vc?.claims.issuedDate}
                                </div>
                            </div>

                        </div>
                        <input className="hidden" accept=".vc" id="icon-button-file" type="file" />

                        <Button onClick={handleGenerate} className="button-filled">
                            Accept the Reward
                        </Button>
                    </>
                    :
                    <div className="placeholder-div">
                        
                    </div>

                }
            </MainCard>
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />

        </div>
    )
}

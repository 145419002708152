import React, { useState } from 'react'
import attributeDelete from '../Assets/attributeDelete.svg';
import attributeEdit from '../Assets/attributeEdit.svg';


export default function AddSectionAttribute(props: { attributeList: any, updateAttribute: any, removeAttribute: any, setAddAttributePopUpOpen?: any }) {

    return <React.Fragment>
        {Object.keys(props.attributeList).map((key: string, index: number) => (
            <React.Fragment key={index}>
                <div className="addSectionAttribute" >
                    <div className="sectionAttribute">{key}</div>
                    <div className="sectionValue">{props.attributeList[key]}</div>
                    <div className="sectionEdit">
                        <button className="addSectionBtn" onClick={() => props.updateAttribute(key)}><img src={attributeEdit} /></button>
                        <button className="addSectionBtn" onClick={() => props.removeAttribute(key)}><img src={attributeDelete} /></button>
                    </div>
                </div>
            </React.Fragment>
        ))
        }
    </React.Fragment>

}

import * as React from 'react';
import { useState } from "react";
import Spinner from './Spinner'
import Alert from "./Alert";
import LogoTop from './LogoTop'
import TopNavigationHeader from './TopNavigationHeader'
import Button from './Button'
import FullContainerBack from './FullContainerBack'
import Wrap from './Wrap'


export default function AnchoringProfile03(props:{done?:any, back?:any}){


    return(
        <React.Fragment>
            <FullContainerBack>
                <TopNavigationHeader
                    caption=""
                    handleBack={props.back}
                />
                <LogoTop/>
                <Wrap>
                    <p className="greenTxtHeading">Adding a TXT record to your Domain</p>
                    
                    <div className="txtGuide">
                        <p className="greenTxtBulletsForm">1. Sign in to your Domain host Account</p>
                        <p className="greenTxtBulletsForm">2. Go to your domain’s DNS records(This might be called as DNS management, Name Server Management, Control Panel or Advanced Settings)</p>
                        <p className="greenTxtBulletsForm">3. Select the option to add a new record</p>
                        <p className="greenTxtBulletsForm">4. For the record type, select TXT</p>
                        <p className="greenTxtBulletsForm">5. In the Name/Host/Alias field, enter @ or leave it blank.</p>
                        <p className="greenTxtBulletsForm">6. Your host might require you to enter your domain, which looks like example.com, into this field. Your other DNS records might indicate what you should enter.</p>
                        <p className="greenTxtBulletsForm">7. In the Time to Live (TTL) field, leave the default.</p>
                        <p className="greenTxtBulletsForm">8. In the Value/Answer/Destination field, paste the verification record you copied in step 1.</p>
                        <p className="greenTxtBulletsForm">9. Save the record.</p><br/>
                        <p className="greenTxtBulletsForm">Note: If you see a warning message about changing your DNS settings, disregard it. Adding the TXT record won’t damage your website or DNS settings.</p>
                    </div>
                   
                    <div className="extra-mt-homebtn"></div>
                    <Button onClick={props.done} name="Done" />
                    <div className="extra-mt-homebtn"></div>
                </Wrap>
            </FullContainerBack>
        </React.Fragment>
    );

}

import React, {useEffect, useState} from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import TopNavigationHeader from '../components/TopNavigationHeader'
import RewardItem from '../components/RewardItem'
import Spinner from '../components/Spinner'
import { useHistory } from 'react-router'
import { VIEWS } from '../utils'
import axios from "axios";
import jwt from "jsonwebtoken";
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { setView, _view } from '../redux/myRewardsSlice'


function MyRewards(){

    // const [toggleState, setToggleState] = useState("myRewards");
    const view = useAppSelector(_view)
    const [myRewards, setMyRewards] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [issuedRewards, setIssuedRewards] = useState([]);
    const dispatch = useAppDispatch()
    const [emptyMyRewardsMsg, setEmptyMyRewardsMsg] = useState(false)
    const [emptyIsseRewardsMsg, setEmptyIsseRewardsMsg] = useState(false)

    const history = useHistory()


    useEffect(() => {
        setIsLoading(true);
        const userToken = sessionStorage.getItem('userToken')
        const decodedUserToken:any = jwt.decode(userToken as string)
        const receivingDID = decodedUserToken.receivingDID;
        getRewards(receivingDID).then(res => {
            console.log(res.data);
            setMyRewards(res.data.accepted_credentials);
            if(res.data.accepted_credentials.length === 0){
                setEmptyMyRewardsMsg(true);
            }
            setIsLoading(false);
        })

        getIssuedRewards(receivingDID).then(res => {
            console.log(res.data);
            setIssuedRewards(res.data.issued_credentials);
            console.log(setIssuedRewards)
            if(res.data.issued_credentials.length === 0){
                setEmptyIsseRewardsMsg(true);
            }
            setIsLoading(false);
        })

    }, []);

    const getRewards = async (did: string) => {
        const rewards = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}reward/getAcceptedRewardCredentials`, {
            params: {
                did
            }
        });
        return rewards;
    };

    const getIssuedRewards = async (did: string) => {
        const rewards = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}reward/getIssuedRewardCredentials`, {
            params: {
                did
            }
        });
        return rewards;
    };

    const toggleTab = (index: any,) => {
        // setToggleState(index);
        dispatch(setView(index))
    };

    function handleBack(){
        history.push(VIEWS.PROFILE)
    }

    function onSelect(id:string) {
        history.push(VIEWS.MYREWARDPREVIEW + "?id=" + id);
    }

    function showIssuedReward(item:any){
        history.push(VIEWS.ISSUEDREWARDPREVIEW, item)
    }

    function handleOnClickReward(type:'MY'|'ISSUED', id:number){
        if(type === 'MY'){
            history.push(VIEWS.MYREWARDPREVIEW)
        }else if(type === 'ISSUED'){
            history.push(VIEWS.MYREWARDPREVIEW)
        }
    }

    return(
        <React.Fragment>

            <div className="container container-noback">

                <TopNavigationHeader
                caption="Rewards"
                handleBack={handleBack}
                />

                <div className="wrap-full">

                    <div className="merge-btn">
                        <button className={view === "myRewards" ? "mergeBtnBlue merge-btn-left" : "mergeBtnWhite merge-btn-left"} onClick={() => toggleTab("myRewards")}>My Rewards</button>
                        <button className={view === "issuedRewards" ? "mergeBtnBlue merge-btn-right" : "mergeBtnWhite merge-btn-right"} onClick={() => toggleTab("issuedRewards")}>Issued Rewards</button>
                    </div>


                    <div className={view !== "myRewards" ? "hidden" : "reward-item-wrap"} >
                        {emptyMyRewardsMsg? (<p className="emptyRewardsTitle">You have not received any Rewards yet.</p>):(<p></p>)}
                        {
                            myRewards.map((item:any,index)=> (
                                <RewardItem
                                    key={index.toString()}
                                    coin={item.claims.rewardBadge || item.claims.issuerImage}
                                    rewardDesc={item.claims.title}
                                    blueTxt={"From " + item.claims.issuerName}
                                    userimg={item.claims.issuerImage}
                                    date={item.claims.issuedDate}
                                    onClick={() => {onSelect(item._id)}}
                                />))
                        }
                    </div>

                    <div className={view !== "myRewards" ? "reward-item-wrap" : "hidden"} >
                        {emptyIsseRewardsMsg? (<p className="emptyRewardsTitle">You have not issued any Rewards yet.</p>):(<p></p>)}
                        {
                            issuedRewards.map((item:any,index)=> (
                                <RewardItem
                                    key={index.toString()}
                                    coin={item.claims.rewardBadge || item.claims.issuerImage}
                                    rewardDesc={item.claims.title}
                                    blueTxt={"To " + item.claims.holderName}
                                    userimg={item.claims.holderImage}
                                    date={item.claims.issuedDate}
                                    onClick={() => {showIssuedReward(item)}}
                                />))
                        }
                    </div>

                </div>

            </div>
            {isLoading && <Spinner />}
        </React.Fragment>
    );
}

export default MyRewards;


import React, { Component } from 'react'

function ContactListItem(props: {userPropic: any, userName: any, actionBtn: any, blueTxt?: any, greyTxt?: any, onPress?:any, clickable?:boolean, onClick?:any}){
    return(
        <React.Fragment>
            <div className="contact-item">
                <div className="left-side">
                    <img src={props.userPropic} className="userpropic-icon"></img>
                    <div className="textArea">
                        <p className={props.clickable ? "userprofile-name clickable": "userprofile-name"} onClick={props.onClick}>{props.userName}</p>
                        {props.greyTxt ? (<p className="receivedRequestTxtGrey">{props.greyTxt}</p>):(<p></p>)}
                        {props.blueTxt ? (<p className="receivedRequestTxt">{props.blueTxt}</p>):(<p></p>)}
                    </div>
                </div>
                <div className="right-side">
                    <button className="btn-without-background" onClick={props.onPress}>
                        <img src={props.actionBtn} className="chevronDown-icon"></img>
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ContactListItem;

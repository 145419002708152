// Ceylon Exchange Mentoring - Certificate of Mastery 2
import React from "react";
import QRCode from "qrcode.react";
import "../../Style/certificateTemplates/Template4.styles.scss";

const Template4 = ({ certificate }) => {
    const [logoURL, setLogoURL] = React.useState("");
    const [seal1URL, setSeal1URL] = React.useState("");
    const [seal2URL, setSeal2URL] = React.useState("");

    React.useEffect(() => {
        const newLogoURL = certificate?.certificate_logo && certificate?.certificate_logo;
        const newSeal1URL = certificate?.signature_one && certificate?.signature_one;
        const newSeal2URL = certificate?.signature_two && certificate?.signature_two;
        setLogoURL(newLogoURL);
        setSeal1URL(newSeal1URL);
        setSeal2URL(newSeal2URL);
    }, [certificate]);

    const signatureRowContent = React.useMemo(() => {
        const content = {
            sign1: '',
            sign1Label: certificate?.signature_title_one,
            sign2: '',
            sign2Label: certificate?.signature_title_two,
        };

        const hasSignature1 = !!certificate?.signature_one;
        const hasSignature2 = !!certificate?.signature_two;
        if (hasSignature1 && hasSignature2) {
            content.sign1 = seal1URL;
            content.sign2 = seal2URL;
        }
        if (hasSignature1 && !hasSignature2) {
            content.sign1 = seal1URL;
            content.sign1Label = certificate?.signature_title_one;
        }
        if (!hasSignature1 && hasSignature2) {
            content.sign1 = seal2URL;
            content.sign1Label = certificate?.signature_title_two;
        }
        return content;
    }, [certificate, seal1URL, seal2URL]);

    return (
        <div className="template-4-certificate-container">
            <div className="certificate-root">
                <div className="certificate-content">
                    <div className="certificate-title-content">
                        <div className="certificate-title-container">
                            {certificate?.title || "Certificate Title"}
                        </div>
                        <div className="attendance-text">CERTIFICATE OF ATTENDANCE</div>
                    </div>
                    <div className="certificate-recievers-info-container">
                        <div className="awarded-to-text">Awarded to</div>
                        <div className="certificate-description-wrapper">
                            <div className="recievers-name">
                                {certificate?.userName}
                            </div>
                            <div className="certificate-description-container">
                                {certificate?.description || "Certificate description"}
                            </div>
                        </div>
                    </div>
                    <div className="certificate-footer">
                        <div className="certificate-footer-content">
                            {
                                (!!certificate?.display_qr) &&
                                <div className="qr-code">
                                    <QRCode value={certificate?.shareLink} size={75} renderAs="svg" />
                                </div>
                            }
                            <div className="org-logo-wrapper">
                                <img
                                    src={
                                        logoURL ||
                                        "/assets/certificate/template-4/mr-template-4-logo-light.png"
                                    }
                                    alt=""
                                    className="org-logo"
                                />
                            </div>
                        </div>

                        <div className="certificate-footer-content">
                            <div className="certificate-signature-pane">
                                <div className="signatures-container">
                                    {signatureRowContent.sign1 ? <div className="signature">
                                        {!signatureRowContent.sign1 && (
                                            <div className="signature-text">
                                                Signature 1
                                            </div>
                                        )}
                                        {signatureRowContent.sign1 && (
                                            <div className="signature-image-container">
                                                <img
                                                    src={signatureRowContent.sign1}
                                                    alt="Signature"
                                                    className="signature-image"
                                                />
                                            </div>
                                        )}
                                        <div className="signature-line signature-line-1" />
                                        <div className="signature-hint">
                                            {signatureRowContent.sign1Label || <>WASAAM ISMAIL<br />Founder</>}
                                        </div>
                                    </div> : ''}
                                    <div className="certificate-date-container">
                                        {certificate?.issuedDate}
                                        <div className="signature-line" />
                                        <div className="hint-text">DATE</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Template4;

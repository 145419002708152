import React, { useState } from 'react'
import Alert from '../components/Alert'
import { useHistory } from 'react-router'
import Logo from '../components/Logo'
import Spinner from '../components/Spinner'
import TopNavigationHeader from '../components/TopNavigationHeader'
import { VIEWS } from '../utils'
import Button from '../components/Button'

export default function VerifyReward() {

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    

    function handleBack(){
        history.push(VIEWS.HOME)
    }

    function sendInvite(){
        console.log("Clicked")
    }

    function handleRewardUrl(){
        console.log("Clicked")
    }

    return (
        <React.Fragment>
            <div className="container container-back">
                <TopNavigationHeader
                    caption="Validate a Reward"
                    handleBack={handleBack}
                />
                <Logo />
                <div className="wrap">

                    <p className="bluetxt extra-pd">Validate  using the Reward URL</p>
                    <input type="text" onChange={handleRewardUrl} className="normal-input" placeholder="Enter the Reward URL"></input>

                    <div className="extra-mb"></div>
                    <div className="extra-mb"></div>

                    <p className="bluetxt extra-pd">Validate using the .vp Reward File</p>
                    <div className="rewardfile-upload-field">
                        <input type="file" data-buttonText="Upload the File"/>
                    </div>

                    <div className="extra-mb"></div>
                    <div className="extra-mb"></div>

                    <Button onClick={sendInvite} name="Verify" disabled={false}/>

                    <div className="extra-mb"></div>
                    <div className="extra-mb"></div>
                </div>
            </div>
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </React.Fragment>
    )
}

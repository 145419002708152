import React from 'react';
import { useHistory } from 'react-router-dom';
import TopNavigationHeader from '../components/TopNavigationHeader';
import { VIEWS } from '../utils';
import Certificate from '../components/Certificate';
import CertificateSignature from '../Assets/certificateSignature.png'


export default function RewardCertificateView() {
    const certificate = {
        userName: "Thomas Miller",
        linkedInUrl: "www.linkedin.com/in/thomasmiller",
        issuedDate: "05/12/21",
        CustomAttributes: "Custom Attribute",
        title: "Certificate of Mastery",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        signature_one: CertificateSignature,
        signature_two: CertificateSignature,
        signature_title_one: "Signature Title",
        signature_title_two: "Signature Title",
    }

    const history = useHistory()

    function handleBack() {
        history.push(VIEWS.HOME)
    }

    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="Reward Certificate"
                    handleBack={handleBack}
                />
                <div className="wrap-full">
                    <Certificate certificate={certificate} />
                </div>
            </div>
        </React.Fragment>
    );
}

import React, {useEffect, useState} from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import Boxbtn from '../components/Boxbtn'
import RewardPreviewItem from '../components/RewardPreviewItem'

import copy from '../Assets/copy.svg';
import badgeGold from '../Assets/badgeGold.svg';
import u3 from '../Assets/u3.png';
import u9 from '../Assets/u9.png';
import logo from '../Assets/logo.svg';
import shareIconSolid from '../Assets/shareIconSolid.svg';
import {useHistory} from "react-router";
import axios from "axios";

export default function ReceivedRewardPrvPubTrCase() {
    const history = useHistory()
    const uid = new URLSearchParams(history.location.search).get("did");
    const id = new URLSearchParams(history.location.search).get("id");
    const [rewards, setRewards] = useState([]);

    useEffect(() => {
        if(id && id!= "undefined"){
            getRewards(uid || "",id).then(res => {
                setRewards(res.data.trophy_case);
                console.log(res.data);
            })
        }
    }, []);

    function handleBack(){
        console.log('Clicked successfully.');
    }

    function handleDone(){
        console.log('Clicked successfully.');
    }

    function copyOption(){
        navigator.clipboard.writeText(window.location.href)
    }


    function addToTrphyCase() {
        console.log('Clicked successfully.');
    }

    function shareOnLinkedin() {
        console.log('Clicked successfully.');
    }

    const getRewards = async (did: string,id:string) => {
        const rewards = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}reward/viewTrophyCase`, {
            params: {
                did,
                id
            }
        });
        return rewards;
    };

    return (
        <React.Fragment>
            <div className="container container-noback">

                <img className="header-logo-mini" src={logo}></img>

                <div className="wrap-full">
                    {
                        rewards.map((item:any,index)=> (
                            <RewardPreviewItem
                                key={index.toString()}
                                copyClicked={copyOption}
                                copyIcon={copy}
                                holderName={item.claims.holderName}
                                rewardImage={item.claims.rewardBadge}
                                holderImage={item.claims.holderImage}
                                issuerImage={item.claims.issuerImage}
                                issuedDate={item.claims.issuedDate}
                                issuerName={item.claims.issuerName}
                                holderProfileUrl={item.claims.holderProfileURL}
                                issuerProfileUrl={item.claims.issuerProfileURL}
                                remarks={item.claims.remarks} rewardTitle={''}
                                attributesValues={JSON.parse(item.claims.attributesValues || '[]') || []}                            />
                        ))
                    }
                    <div className="boxbtn-section-irp mg-top-boxbtn">
                        <Boxbtn icon={shareIconSolid} name="Share on Linkedin" onClick={shareOnLinkedin} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

import { Dialog } from "@material-ui/core";
import { useState, useRef, useEffect } from "react";
import { useAppSelector } from "../app/hooks";
import { _attributes } from "../redux/signerSlice";

export default function EditAttributePopUp(props: { id?: string, title?: string, content?: string, open: boolean, handleOpen?: any, handleClose?: any, handleAction?:any}) {
    
    const [inputKey, setInputKey] = useState('')
    const [inputValue, setInputValue] = useState('')
    const inputRef = useRef(null);
    const attributes = useAppSelector(_attributes)

    useEffect(() => {
        if(props.content){
            setInputKey(props.content)
            setInputValue(attributes[props.content])
        } 
    }, [attributes, props.content])

    const handleChangeAttribute = (e:any) => {
        setInputKey(e.target.value)
    }

    const handleChangeValue = (e:any) => {
        setInputValue(e.target.value)
    }

    const handleEdit = (e:any) => {
        e.preventDefault();
        props.handleAction(inputKey, inputValue);
        setInputKey('')
        setInputValue('')
        props.handleClose()
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby={`${props.id}-alert-dialog-title`}
            aria-describedby={`${props.id}-alert-dialog-description`}
        >
 
            <div className="popupOverLay">
                <div className="popup-wrap-addSections">
                    <div className="popup-heading">
                        <p>Edit Value</p>
                    </div>
                    <p className="colorGreen">Enter the new value</p>
                    <div className="exmfbtm"></div>
                    {/* <input 
                        type="text" 
                        className="normal-input" 
                        placeholder="Attribute" 
                        onChange={handleChangeAttribute} 
                        value={inputKey}
                        name='attribute'
                        autoComplete="off"
                        ref={inputRef}
                    /> */}
                    <div className="exmfbtm"/>
                    <input 
                        type="text" 
                        className="popup-bold-input" 
                        placeholder="Value" 
                        onChange={handleChangeValue} 
                        value={inputValue}
                        name='value'
                        autoComplete="off"
                        ref={inputRef}
                    />
                    <div className="mg-top-boxbtn"></div>
                    <div className="buttonRow">
                        <button className="popup-btn" onClick={handleEdit}>Save</button>
                        <button className="popup-btn" onClick={props.handleClose}>Close</button>
                    </div>
                </div>
            </div>

        </Dialog>
    )
}
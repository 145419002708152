import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '2.5rem 2rem',
        backgroundColor: '#f1f1fa',
        borderRadius: '30px 30px 30px 30px',
        marginBottom: 0,
    },
}));

export default function MainCard(props: any) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {props.children}
        </div>
    )
}

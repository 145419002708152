import React, {useEffect} from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import AcceptRewardListItem from '../components/AcceptRewardListItem'
import TopNavigationHeader from '../components/TopNavigationHeader'
import Button from '../components/Button'

import closeIcon from '../Assets/closeIcon.svg';

import { useState } from "react";
import { useHistory } from 'react-router'
import { VIEWS } from '../utils'
import axios from "axios";
import jwt from "jsonwebtoken";
import PopupQuestion from "../components/PopupQuestion";
import Spinner from "../components/Spinner";
import Alert from "../components/Alert";




function ReceivedRewards(){

    const [isKeysGenerated, setIsKeysGenerated] = useState(true)
    const [rewards, setRewards] = useState([])
    const [popUp, setPopUp] = useState(false)
    const [index, setIndex] = useState(-1)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    //get rewards
    const userToken = sessionStorage.getItem('userToken')
    const decodedUserToken:any = jwt.decode(userToken as string)
    const uid = decodedUserToken.receivingDID;
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: ''})


    useEffect(() => {
        setIsLoading(true);
        getRewards(uid).then(res => {
            setRewards(res.data.credentials);
            if(res.data.credentials.length === 0){
                setAlertOpen({ open: true, title: 'Alert', content: 'You don\'t have any new received rewards.'});
            }
            console.log(res.data.credentials);
            setIsLoading(false);
        })
    }, []);

    const getRewards = async (did: string) => {
        const rewards = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}reward/getUnacceptedRewardCredentials`, {
            params: {
                did
            }
        });
        return rewards;
    };


    function handleBack() {
        history.push(VIEWS.HOME)
    }

    function handleDone() {
        history.push(VIEWS.HOME)
    }

    function requestReward(){
        history.push(VIEWS.REQUESTREWARD)
    }

    function onSelect(id:string) {
        history.push(VIEWS.RECEIVEDREWARDPREVIEW + "?id=" + id);
    }

    const cancelReward = async (did: string,id:string) => {
        const rewards = await axios.delete(`${process.env.REACT_APP_REWARD_BACKEND}reward/declineRewardCredential`, {
            data: {
                did,
                id
            }
        });
        return rewards;
    };
    const onRemove = (id:string)=> {
        console.log('ID REMOVE',id)
        setIsLoading(true);
        cancelReward(uid, id).then(res => {
            getRewards(uid).then(res => {
                setRewards(res.data.credentials);
                console.log('ON REMOVE RES',res.data.credentials);
                setIsLoading(false);
            })
        });
        setPopUp(false)
    };


    return(
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                caption="Received Rewards"
                handleBack={handleBack}
                handleDone={handleDone}
                disabled={isKeysGenerated !== true}
                />
                <div className="wrap-full">
                    <div className="wrapReverse">
                        {
                            rewards.map((item:any,index)=> (
                            <AcceptRewardListItem
                                key={index.toString()}
                                userPropic={item.claims.rewardBadge}
                                rewardDesc={item.claims.issuerName + " sent you a reward"}
                                role={item.claims.title}
                                actionBtn={closeIcon}
                                blueTxt={"From " + item.claims.issuerName}
                                userimg={item.claims.issuerImage}
                                date={item.claims.issuedDate}
                                onSelect={()=>{onSelect(item._id)}}
                                onCancel={()=>{setPopUp(true);setIndex(index)}}
                            />))
                        }
                    </div>
                    <div className="extraMargin-iR-Btn">
                        <Button onClick={requestReward} name="Request a new  Reward"/>
                    </div>      
                </div>
            </div>
            {isLoading && <Spinner />}
            {
                popUp ?<PopupQuestion onClickConfirm={()=>{// @ts-ignore
                onRemove(rewards ? rewards[0]._id : "")}} onClickCancel={()=>{setPopUp(false)}} headingText={"Are you sure you want to remove this reward ?"} bodyMessage={"You won't be able to revert this action"} buttonTxtCancel={"NO"} buttonTxtConfirm={"YES"}/>
                : null
            }
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false})}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            /> 
        </React.Fragment>
    );
}

export default ReceivedRewards;

import React from "react";
import QRCode from "qrcode.react";
import "../../Style/certificateTemplates/Template1.styles.scss";

const Template1 = ({ certificate }) => {
    const [logoURL, setLogoURL] = React.useState("");
    const [seal1URL, setSeal1URL] = React.useState("");
    const [seal2URL, setSeal2URL] = React.useState("");

    React.useEffect(() => {
        const newLogoURL = certificate?.certificate_logo && certificate?.certificate_logo;
        const newSeal1URL = certificate?.signature_one && certificate?.signature_one;
        const newSeal2URL = certificate?.signature_two && certificate?.signature_two;
        setLogoURL(newLogoURL);
        setSeal1URL(newSeal1URL);
        setSeal2URL(newSeal2URL);
    }, [certificate]);

    const signatureRowContent = React.useMemo(() => {
        const content = {
            sign1: '',
            sign1Label: certificate?.signature_title_one,
            sign2: '',
            sign2Label: certificate?.signature_title_two,
        };
        const hasSignature1 = !!certificate?.signature_one;
        const hasSignature2 = !!certificate?.signature_two;
        if (hasSignature1 && hasSignature2) {
            content.sign1 = seal1URL;
            content.sign2 = seal2URL;
        }
        if (hasSignature1 && !hasSignature2) {
            content.sign2 = seal1URL;
            content.sign2Label = certificate?.signature_title_one;
        }
        if (!hasSignature1 && hasSignature2) {
            content.sign2 = seal2URL;
            content.sign2Label = certificate?.signature_title_two;
        }
        return content;
    }, [certificate, seal1URL, seal2URL]);

    return (
        <div className="template-1-certificate-container">
            <div className="certificate-root">
                <div className="certificate-content">
                    <img src="/assets/certificate/template-1/mr-template-1-frame.png" alt="" className="certificate-frame" />
                    <div className="certificate-badge-container">
                        <img
                            src={
                                logoURL ||
                                "/assets/certificate/template-2/mr-template-2-logo-icon.png"
                            }
                            alt=""
                            className="certificate-badge"
                        />
                    </div>
                    <div className="certificate-title-container">
                        {certificate?.title || "Certificate Title"}
                    </div>
                    <div className="certificate-recievers-info-container">
                        <div className="awarded-to-text">Awarded to</div>
                        <div className="recievers-name">
                            {certificate?.userName}
                        </div>
                    </div>
                    <div className="certificate-description-container">
                        {certificate?.description || ""}
                    </div>
                    {certificate?.custom_attributes?.length ? (
                        <div className="certificate-custom-attributes-container">
                            {certificate.custom_attributes.map((attribute, idx) => (
                                <div key={idx}>
                                    <span>{attribute?.label || ""}</span>
                                    <span>-</span>
                                    <span>{attribute?.value || ""}</span>
                                </div>
                            ))}
                        </div>
                    ) : ''}
                    <div className="certificate-date-container">
                        {certificate?.issuedDate}
                        <div className="signature-line" />
                        <div className="hint-text">Date</div>
                    </div>
                    <div className="certificate-signature-pane">
                        <div className="signatures-container">
                            {signatureRowContent.sign1 ? <div className="signature">
                                {!signatureRowContent.sign1 && (
                                    <div className="signature-text">
                                        Signature 1
                                    </div>
                                )}
                                {signatureRowContent.sign1 && (
                                    <div className="signature-image-container">
                                        <img
                                            src={signatureRowContent.sign1}
                                            alt="Signature"
                                            className="signature-image"
                                        />
                                    </div>
                                )}
                                <div className="signature-line" />
                                <div className="signature-hint">
                                    {signatureRowContent.sign1Label || ""}
                                </div>
                            </div> : ''}

                            {signatureRowContent.sign2 ?
                                <div className="signature">
                                    <div className="signature-image-container">
                                        <img
                                            src={signatureRowContent.sign2}
                                            alt="Signature"
                                            className="signature-image"
                                        />
                                    </div>
                                    <div className="signature-line" />
                                    <div className="signature-hint">
                                        {signatureRowContent.sign2Label || ""}
                                    </div>
                                </div> : ''}
                        </div>
                    </div>
                    <div className="certificate-footer">
                        {
                            (!!certificate?.display_qr) &&
                            <div className="qr-code">
                                <QRCode value={certificate?.shareLink} size={50} renderAs="svg" />
                            </div>
                        }
                        <div className="org-info">
                            {
                                certificate?.issuer_image &&
                                <div className="org-logo-container">
                                    <img
                                        src={certificate?.issuer_image}
                                        alt=""
                                        className="org-logo"
                                    />
                                </div>
                            }
                            <div className="org-name">{certificate?.issuerName}</div>
                        </div>
                        {/* <div className="org-web-url">{certificate?.issuerProfileURL}</div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Template1;

import React from 'react'
import '../Style/App.scss'
import '../Style/Responsive.scss'
import TopNavigationHeader from '../components/TopNavigationHeader'
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { VIEWS } from "../utils";
import Spinner from "../components/Spinner";
import Alert from "../components/Alert";
import correctMark from '../Assets/correctMark.svg';
import TxtValueAndStatus from '../components/TxtValueAndStatus'
import TxtValueDomainVerify from '../components/TxtValueDomainVerify'
import FullContainerBack from '../components/FullContainerBack'
import WrapFull from '../components/WrapFull'
import TxtHelpGuide from '../components/TxtHelpGuide'
import shajs from 'sha.js'
import { ecdsaSign } from 'secp256k1'
import { useAppSelector } from '../app/hooks';
import { _rewardingDID, _rewardingPrivateKey } from '../redux/identitySlice';
import axios from 'axios';
import jwt from 'jsonwebtoken'
import AlertConfirmation from '../components/AlertConfirmation'

export default function TxtMain() {

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [alertWithConfirmation, setAlertWithConfirmation] = useState({ open: false, title: '', content: '' })
    const [onceGenerateTxt, setOnceGenerateTxt] = useState(false)
    const [helpDisplay, setHelpDisplay] = useState(false)
    const [isEditBtnActive, setIsEditBtnActive] = useState(false)
    const [domainInputDisabled, setDomainInputDisabled] = useState(false)
    const issuingPrivateKey = useAppSelector(_rewardingPrivateKey);
    const [domain, setDomain] = useState('')
    const [txt, setTxt] = useState('')
    const [verifiedDomain, setVerifiedDomain] = useState('')
    const [isDomainVerify, setIsDomainVerify] = useState(false)
    const issuingDID = useAppSelector(_rewardingDID);


    useEffect(() => {
        setIsLoading(true)
        const userToken = sessionStorage.getItem('userToken')
        const decodedUserToken: any = jwt.decode(userToken as string)
        setIsDomainVerify(decodedUserToken?.domainVerified)
        setVerifiedDomain(decodedUserToken?.domain)
        try {
            if (decodedUserToken.domainVerified || decodedUserToken.domain) {
                setDomain(decodedUserToken.domain)
                setOnceGenerateTxt(true)
                setIsEditBtnActive(true)
                setDomainInputDisabled(true)
                checkDomainStatuToken()
                const domainHash: string = new shajs.sha256().update(decodedUserToken?.domain).digest('hex');
                const domainHashBuffer = Buffer.from(domainHash, 'hex');
                const issuingPrivateKeyBuffer = Buffer.from(issuingPrivateKey, 'hex');
                const signatureArray = Buffer.from(ecdsaSign(domainHashBuffer, issuingPrivateKeyBuffer).signature)
                const decodedSignatureArray = signatureArray.toString('base64')
                setTxt(decodedSignatureArray)
            }
            setIsLoading(false)
        }
        catch (error) {
            setIsLoading(false)
        }
    }, [])


    function handleBack() {
        history.push(VIEWS.PROFILE)
    }

    function goToHelp() {
        setHelpDisplay(true)
    }

    function backToDomainVerify() {
        setHelpDisplay(false)
    }

    function handleDomain(event: any) {
        const domain = event.target.value
        setDomain(domain)
    }

    function validateDomain() {
        const regexp = /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/g;
        if (!domain || regexp.test(domain) === false) {
            setAlertOpen({ open: true, title: "Alert", content: "Domain invalid. Please enter a valid domain." })
            return false;
        }
        return true;
    }

    async function generateTxt() {
        if (validateDomain()) {
            setIsLoading(true)
            setDomain(domain);
            setIsDomainVerify(false)
            try {
                const domainHash: string = new shajs.sha256().update(domain).digest('hex');
                const domainHashBuffer = Buffer.from(domainHash, 'hex');
                const issuingPrivateKeyBuffer = Buffer.from(issuingPrivateKey, 'hex');
                const token = sessionStorage.getItem('userToken')
                const userTokenResponse = await axios.post(`${process.env.REACT_APP_REWARD_BACKEND}user/set/domain`,
                    { token, domain: domain })
                sessionStorage.setItem('userToken', userTokenResponse.data.user)
                const signatureArray = Buffer.from(ecdsaSign(domainHashBuffer, issuingPrivateKeyBuffer).signature)
                const decodedSignatureArray = signatureArray.toString('base64')
                setIsLoading(false)
                setOnceGenerateTxt(true)
                setIsEditBtnActive(true)
                setDomainInputDisabled(true)
                setTxt(decodedSignatureArray)
            } catch (e) {
                setIsLoading(false)
            }
        }
    }

    async function checkDomainStatusBackend() {
        setIsLoading(true)
        const token = sessionStorage.getItem('userToken')
        try {
            const userTokenResponse = await axios.post(`${process.env.REACT_APP_REWARD_BACKEND}user/verify/txtRecords`,
                {
                    token,
                    issuingDID
                })
            if (userTokenResponse.data) {
                setIsLoading(false)
                const userToken = userTokenResponse.data.user                
                if (userTokenResponse.data.domainVerified) {
                    sessionStorage.setItem("userToken", userToken);
                    setIsDomainVerify(true)
                    setAlertOpen({ open: true, title: "Sucess", content: "Domain is verified." })
                } else {
                    setIsLoading(false)
                    setIsDomainVerify(false)
                    setAlertOpen({ open: true, title: "Alert", content: "Domain verification is still pending. Please try agin later or check the TXT file added into the DNS correctly" })
                }
            } else {
                setIsLoading(false)
                setIsDomainVerify(false)
                setAlertOpen({ open: true, title: "Alert", content: "Response is empty. Please try again" })
            }
        } catch (error: any) {
            setIsLoading(false)
            setIsDomainVerify(false)
            setAlertOpen({ open: true, title: "Alert", content: error.message })
        }

    }

    function checkDomainStatuToken() {
        const userToken = sessionStorage.getItem('userToken')
        const decodedUserToken: any = jwt.decode(userToken as string)
        setIsDomainVerify(decodedUserToken?.domainVerified)
        setVerifiedDomain(decodedUserToken?.domain)
    }

    function handleDone() {
        history.push(VIEWS.PROFILE)
    }


    function enableEdit() {
        setAlertWithConfirmation({ ...alertWithConfirmation, open: true })
    }


    function alertWithConfirmationOk() {
        setIsEditBtnActive(false);
        setDomainInputDisabled(false)
    }

    return (
        <React.Fragment>
            {helpDisplay ? (
                <TxtHelpGuide back={backToDomainVerify} done={backToDomainVerify} />
            ) : (
                <FullContainerBack>
                    <TopNavigationHeader
                        caption="Claim ID by Domain"
                        handleBack={handleBack}
                        rightIcon={correctMark}
                        handleDone={handleDone}
                    />
                    <WrapFull>
                        <TxtValueDomainVerify
                            editBtn={isEditBtnActive}
                            generateTxtValue={generateTxt}
                            handleDomain={handleDomain}
                            enableEdit={enableEdit}
                            domain={domain}
                            disabled={domainInputDisabled}
                        />
                        {onceGenerateTxt ? (
                            <TxtValueAndStatus
                                helpTxtPage={goToHelp}
                                txt={txt}
                                domain={domain || verifiedDomain}
                                checkStatus={checkDomainStatusBackend}
                                isDomainVerify={isDomainVerify}
                            />
                        ) : null}
                    </WrapFull>
                </FullContainerBack>
            )}

            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
            <AlertConfirmation
                open={alertWithConfirmation.open}
                handleClose={() => setAlertWithConfirmation({ ...alertWithConfirmation, open: false })}
                handleOpen={() => setAlertWithConfirmation({ ...alertWithConfirmation, open: true })}
                handleAction={alertWithConfirmationOk}
                message="Are you sure you want to change your domain ?"
                yesTxt={true}
            />
        </React.Fragment>
    );
}

import React, {ChangeEvent, useEffect} from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import Button from '../components/Button'
import TopNavigationHeader from '../components/TopNavigationHeader'

import backBtn from '../Assets/backBtn.svg';
import copy from '../Assets/copy.svg';
import correctMark from '../Assets/correctMark.svg';

import { useState } from "react";
import { useHistory } from 'react-router'
import { VIEWS } from '../utils'
import { useAppSelector } from '../app/hooks'
import { _receivingDID, _rewardingDID, _mnemonic } from '../redux/identitySlice'
import { Checkbox, IconButton } from '@material-ui/core'
import Alert from "../components/Alert";
import PopupQuestion from "../components/PopupQuestion";
import AlertConfirmation from '../components/AlertConfirmation'
import axios from "axios";

function Viewmyid() {

    const history = useHistory()
    const mnemonic = useAppSelector(_mnemonic)
    const rewardingDID = useAppSelector(_rewardingDID)
    const receivingDID = useAppSelector(_receivingDID)
    const [copyTextDid, setCopyTextDid] = useState(false);
    const [copyText, setCopyText] = useState(false);
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [clickHandleDone, setClickHandleDone] = useState(false)
    const currentUrl = window.location.href;
    const [popUp, setPopUp] = useState(false)
    const [alertWithConfirmation, setAlertWithConfirmation] = useState({ open: false, title: '', content: '' })
    const [enableEmail,setEnableEmail] =useState(true)
    const [email,setEmail] = useState('')
    const [disableSend,setDisableSend]= useState(false)

    useEffect(() => {
        console.log(currentUrl);
        if(currentUrl == `${process.env.REACT_APP_URL}identity/hkfsuhfry77yr43riuhf34fy7fheiwowjf89r4`){
            setAlertOpen({ open: true, title: "Alert", content: "Heads up!  Make sure to copy and save your ID Secrete safely" })
        }
    }, []);


    function shareViaEmail() {
        const url = `mailto: ?subject=My DIDs&body=My receiving DID: ${receivingDID} %0D%0AMy rewarding DID: ${rewardingDID}`
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    }

    function handleBack() {
        history.push(VIEWS.PROFILE)
    }

    function handleDone() {
        if(currentUrl === `${process.env.REACT_APP_URL}identity/hkfsuhfry77yr43riuhf34fy7fheiwowjf89r4`){
            console.log("Popup Display");
            setAlertWithConfirmation({ ...alertWithConfirmation, open: true })
        }else{
            history.push(VIEWS.PROFILE)
            console.log("Go to profile");
        }
    }



    function onCopy(value: string) {
        setCopyText(true)
        navigator.clipboard.writeText(value)
        setTimeout(() => {
            setCopyText(false)
        }, 2000)
    }

    function onCopyDid(value: string) {
        setCopyTextDid(true)
        navigator.clipboard.writeText(value)
        setTimeout(() => {
            setCopyTextDid(false)
        }, 2000)
    }
    function emailChange(event: ChangeEvent<HTMLInputElement>) {
        const currentEmail = event.target.value;
        setEmail(currentEmail);
    };
    async function sendIdEmail() {
        if(!enableEmail){return;}
        var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(!email.match(mailFormat)){
            setAlertOpen({ open: true, title: "Error", content: "You have entered an invalid email address!" })
            return;
        }
        const token = sessionStorage.getItem('userToken')
        setDisableSend(true)
        await axios.post(`${process.env.REACT_APP_REWARD_BACKEND}user/emailSecretID`,
            {
                token,
                email,
                mnemonic
            })
            .then((res) =>{
                console.log(res)
                setAlertOpen({ open: true, title: "Success", content: "Email sent !" })
            })
            .catch((res) => {
                console.log(res)
                setAlertOpen({ open: true, title: "Error", content: "Email sent failed, please try again." })
            })
        setEmail('')
        setDisableSend(false)
    }
    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="My Profile"
                    handleDone={handleDone}
                    rightIcon={correctMark}
                />

                <div className="wrap-full">
                    <div className="viemyid-section">
                        <p className="subtitle2">Your ID Secret</p>
                        <div className="input_container">
                            <input type="password" className="id-input" value={mnemonic}></input>
                            <button className="btn-without-background" onClick={() => onCopy(mnemonic)}>
                                <img className="copy-icon-inside" src={copy}></img>
                                {copyText? (<div className="tooltipText">Copied to<br/>Clipboard</div>) : ""}
                            </button>
                        </div>
                        <p className="blue-paratxt decrease-mg-top">Important : This word collection is top secret. You must keep this safe. You can use this to login to Micro Rewards directly without Email, password combination.</p>
                        <div className="topSpace">
                            <hr/>
                            <div className="mediumTopSpace">
                                <Checkbox className="greenCheckBox" defaultChecked={true} onChange={() => setEnableEmail(!enableEmail)}/>
                                <span className="greenTxt">Send ID Secret to My Email</span>
                            </div>
                            {
                                enableEmail?
                                <div>
                                <input hidden={!enableEmail} disabled={!enableEmail} type="text" className="normal-input" value={email} onChange={emailChange} placeholder="Type Your Email"/>
                                <Button disabled={disableSend} name="Send" onClick={sendIdEmail} />
                                </div>
                                :""
                            }
                        </div>
                    </div>
                    <div className="viemyid-section">
                        <p className="subtitle2">Your Receiving DID</p>
                        <p className="bluetxt">{receivingDID}<div className="exmgright"/>
                            <IconButton  onClick={() => onCopyDid(receivingDID)}>
                                <img src={copy}></img>
                                {copyTextDid? (<div className="tooltipText">Copied to Clipboard</div>) : ""}
                            </IconButton>
                        </p>
                        <p className="viewmyid-desc">Use this DID when you want to Receive a Reward from someone else</p>
                    </div>
                    <div className="viemyid-section">
                        <p className="subtitle2">Your Issuing DID</p>
                        <p className="bluetxt">{rewardingDID}</p>
                        <p className="viewmyid-desc">This DID is used when you Issue a Reward to someone else</p>
                    </div>
                    {/* <Button onClick={shareViaEmail} name="Share via Email" /> */}
                </div>
            </div>
            {
                popUp ? <PopupQuestion onClickConfirm={handleBack()} onClickCancel={() => { setPopUp(false) }} headingText={"Alert"} bodyMessage={"Heads up !  Did you copy and save your ID Secrete safely  ? you will not be able to recover this later."} buttonTxtCancel={"CANCEL"} buttonTxtConfirm={"CONFIRM"} />
                : null

            }
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
            <AlertConfirmation
                open={alertWithConfirmation.open}
                handleClose={() => setAlertWithConfirmation({ ...alertWithConfirmation, open: false })}
                handleOpen={() => setAlertWithConfirmation({ ...alertWithConfirmation, open: true })}
                handleAction={handleBack}
                message="Heads up !  Did you copy and save your ID Secrete safely? you will not be able to recover this later."
            />
        </React.Fragment>
    );
}

export default Viewmyid;
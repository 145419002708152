import { Button, makeStyles } from "@material-ui/core";
import axios, { AxiosResponse } from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import MainCard from "../components/Card";
import CollapsibleText from "../components/CollpasibleText";
import TopNavigationHeader from "../components/TopNavigationHeader";
import { setIsVerifid, setVP, _vp } from "../redux/verifierSlice";
import { VIEWS } from "../utils";
import LogoGreen from "../Assets/logoGreen.svg"

const useStyles = makeStyles((theme) => ({
    caption: {
        fontWeight: 'bold',
        fontSize: '0.9rem',
        lineHeight: '3rem',
        color: '#161719',
        margin: 0,
    }
}))

export default function VerificationDetails() {

    const history = useHistory()
    const dispatch = useAppDispatch()
    const vp = useAppSelector(_vp);
    const [url, setURL] = useState('Loading...')
    const classes = useStyles()

    useState(() => {
        axios.put(`${process.env.REACT_APP_REWARD_BACKEND}reward/presentaion`, vp).then((res: AxiosResponse) => {
            if (res.data) {
                const url = `${process.env.REACT_APP_URL || "https://micro-rewards-view.herokuapp.com/"}verify/${res.data.reward._id}`
                setURL(url)
            }
        }).catch(err => {
            if (err.response?.data?.code === 11000) {
                const url = `${process.env.REACT_APP_URL || "https://micro-rewards-view.herokuapp.com/"}verify/${err.response?.data?.id}`
                setURL(url)
            }
            else {
                setURL('Fetching URL failed')
            }

        })
    })


    function handleDone() {
        dispatch(setVP(undefined))
        dispatch(setIsVerifid(false))
        history.push(VIEWS.ONBORDING)
    }

    function handleBack() {
        history.goBack()
    }

    function onCopy(value: string) {
        navigator.clipboard.writeText(value)
    }

    function onExport(value: string) {
        const url = `mailto: ?subject= &body=${value}`
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    }

    return (
        <div className="container container-noback">
            <TopNavigationHeader
                caption="Verification Details"
                handleDone={handleDone}
                handleBack={handleBack}
            />
            <div className="wrap-full">
                <img src={LogoGreen} className="logoGreen-mg"/>
                <div className="group">
                    <div className="profile-card">
                        <CollapsibleText
                            label="Issuer DID"
                            value={vp?.credentials?.[0].issuer.did}
                            onCopy={onCopy}
                            onExport={onExport}
                        />
                        <CollapsibleText
                            label="Receiver DID"
                            value={vp?.credentials?.[0].subject.did}
                            onCopy={onCopy}
                            onExport={onExport}
                        />
                        <CollapsibleText
                            label="Verification URL"
                            value={url}
                            onCopy={onCopy}
                            onExport={onExport}
                        />
                    </div>
                </div>
                <div className="group">
                    <div className="profile-card">
                        <CollapsibleText
                            label="VC JSON"
                            value={JSON.stringify(vp)}
                            onCopy={onCopy}
                            onExport={onExport}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}


import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import identityReducer from '../redux/identitySlice';
import signerReducer from '../redux/signerSlice';
import presenterReducer from '../redux/presenterSlice';
import verifierReducer from '../redux/verifierSlice';
import myRewardsReducer from '../redux/myRewardsSlice';

export const store = configureStore({
  reducer: {
    identity: identityReducer,
    signer: signerReducer,
    presenter: presenterReducer,
    verifier: verifierReducer,
    myRewards: myRewardsReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

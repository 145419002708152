import React, { useEffect, useRef, useState } from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import TopNavigationHeader from '../components/TopNavigationHeader'
import Boxbtn from '../components/Boxbtn'
import RewardPreviewItem from '../components/RewardPreviewItem'
import BoxbtnTransparent from '../components/BoxbtnTransparent'

import copy from '../Assets/copy.svg';
import badgeGold from '../Assets/badgeGold.svg';
import u3 from '../Assets/u3.png';
import u9 from '../Assets/u9.png';
import singleCloseIcon from '../Assets/singleCloseIcon.svg';
import shareIconSolid from '../Assets/shareIconSolid.svg';
import axios from "axios";
import Alert from "../components/Alert";

import jwt from "jsonwebtoken";
import { useHistory } from "react-router";
import { shareOnFacebookHelper, shareOnLinkedIn, VIEWS } from "../utils";
import Spinner from "../components/Spinner";
import ShareComponent from '../components/ShareComponent'
import { FacebookShareButton } from 'react-share'
import Certificate from '../components/Certificate'
import CertificateSignature from '../Assets/certificateSignature.png'
import ReceivedRewardPreviewUser from '../components/RewardPreviewUser'


function ReceivedRewardPreviewTrophyCase() {
    const [isKeysGenerated, setIsKeysGenerated] = useState(true)
    const history = useHistory()
    const [rewards, setRewards] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const userToken = sessionStorage.getItem('userToken')
    const decodedUserToken: any = jwt.decode(userToken as string)
    const uid = decodedUserToken.receivingDID;
    const id = new URLSearchParams(history.location.search).get("id");
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [showButtons, setShowButtons] = useState(false)
    const [copyText, setCopyText] = useState(false);
    const [shareOn, setShareOn] = useState(false);
    const [copiedTooltip, setCopiedTooltip] = useState(false)
    const [addToLinkedInUrl, setAddToLinkedInUrl] = useState('')

    const [tabBtn, setTabBtn] = useState('')
    const [isCertificateAvailable, setIsCertificateAvailable] = useState(false);
    const [isBadgeAvailable, setIsBadgeAvailable] = useState(false);
    const [displayBadge, setDisplayBadge] = useState(true)
    const [displayCertificate, setDisplayCertificate] = useState(false)

    const fbShareRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        setIsLoading(true)
        if (id && id != "undefined") {
            getRewards(uid, id).then(res => {
                // setRewards(res.data.credentials);
                setRewards(res.data.trophy_case);
                console.log(res.data);

                //Checking the the badge is available or not 
                if (!!res.data.trophy_case[0].claims?.rewardBadge) {
                    setIsBadgeAvailable(true);
                }
                //Checking the the certificate is available or not 
                if (!!res.data.trophy_case[0].claims?.certificate) {
                    setIsCertificateAvailable(true);
                }

                //Checking the active tab
                /* if (res.data.trophy_case[0]) {
                    setTabBtn('badge');
                } else {
                    setTabBtn('certificate');
                } */
                if (!!res.data.trophy_case[0].claims?.rewardBadge) {
                    //setTabBtn('badge');

                    displyBatch()
                } else if (!!res.data.trophy_case[0].claims?.certificate) {
                    //setTabBtn('certificate');

                    displyCertificate()
                }

                setIsLoading(false);
                setShowButtons(true);
            })
        }
    }, []);

    useEffect(() => {
        if (rewards[0]) {
            try {
                const item: any = rewards[0];
                const certificate = JSON.parse(item.claims.certificate)
                const date = new Date(certificate.DATE)
                const certUrl: any = shareOnFacebookHelper(uid, id || "")
                
                const url = new URL("https://www.linkedin.com/profile/add")
                url.searchParams.append('startTask', 'CERTIFICATION_NAME')
                url.searchParams.append('name', certificate.title)
                url.searchParams.append('organizationName', item.claims.issuerName)
                url.searchParams.append('issueYear', date.getFullYear().toString())
                url.searchParams.append('issueMonth', (date.getMonth() +  1).toString())
                url.searchParams.append('certUrl', certUrl)

                if (url) setAddToLinkedInUrl(url.href)
            } catch (error) {
                console.log(error)
            }
        };

    }, [rewards])

    function handleBack() {
        history.goBack()
    }

    function handleDone() {
        console.log('Clicked successfully.');
    }

    function copyOption() {
        setCopyText(true)
        navigator.clipboard.writeText(window.location.href)
        setTimeout(() => {
            setCopyText(false)
        }, 2000)
    }

    const getRewards = async (did: string, id: string) => {
        const rewards = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}reward/viewTrophyCase`, {
            params: {
                did,
                id
            }
        });
        return rewards;
    };

    const removeFromCase = async (did: string, id: string) => {
        const rewards = await axios.put(`${process.env.REACT_APP_REWARD_BACKEND}reward/removeFromTrophyCase`, {
            did,
            id
        });
        return rewards;
    };

    function removeFromTrophyCase() {
        setIsLoading(true)
        console.log('remove from TROPHY CASE', uid, id);
        removeFromCase(uid, id || "").then(res => {
            console.log(res)
            setIsLoading(false)
            setAlertOpen({ open: true, title: 'Success', content: 'Reward removed from Favorite Rewards.' });
        }).catch(e => {
            console.log(e)
        })
    }

    function goToTrophyCase() {
        setAlertOpen({ ...alertOpen, open: false })
        history.push(VIEWS.TROPHYCASE);
    }

    function shareOnLinkedin() {
        shareOnLinkedIn(uid, id || "")
    }

    function shareOnFacebook() {
        return shareOnFacebookHelper(uid, id || "")
    }

    function shareOnDisplay() {
        setShareOn(true);
    }

    function copyURL() {
        setCopiedTooltip(true)
        navigator.clipboard.writeText(shareOnFacebookHelper(uid, id || ""))
        setTimeout(() => {
            setCopiedTooltip(false)
        }, 2000)
    }

    function displyBatch() {
        setTabBtn('badge')
        setDisplayBadge(true)
        setDisplayCertificate(false)
    }

    function displyCertificate() {
        setTabBtn('certificate')
        setDisplayCertificate(true)
        setDisplayBadge(false)
    }

    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="Reward Preview"
                    handleBack={handleBack}
                    handleDone={handleDone}
                    disabled={isKeysGenerated !== true}
                />
                <div className="wrap-full">

                    <div className="tabSection">
                        {isBadgeAvailable && (
                            <button className={tabBtn === 'badge' ? "tabSectionBtnActive" : "tabSectionBtn"} onClick={displyBatch}>Badge</button>
                        )}
                        {isCertificateAvailable && (
                            <button className={tabBtn === 'certificate' ? "tabSectionBtnActive" : "tabSectionBtn"} onClick={displyCertificate}>Certificate</button>
                        )}
                    </div>

                    {displayBadge && (
                        <div className='insideComponentsWrap'>
                            {
                                rewards.map((item: any, index) => (
                                    <RewardPreviewItem
                                        key={index.toString()}
                                        copyClicked={copyOption}
                                        copyIcon={copy}
                                        holderName={item.claims.holderName}
                                        rewardImage={item.claims.rewardBadge}
                                        holderImage={item.claims.holderImage}
                                        issuerImage={item.claims.issuerImage}
                                        issuedDate={item.claims.issuedDate}
                                        issuerName={item.claims.issuerName}
                                        holderProfileUrl={item.claims.holderProfileURL}
                                        issuerProfileUrl={item.claims.issuerProfileURL}
                                        remarks={item.claims.remarks} rewardTitle={item.claims.title}
                                        copyText={copyText}
                                        attributesValues={JSON.parse(item.claims.attributesValues || '[]') || []}
                                    />
                                ))
                            }
                            {
                                showButtons ? (
                                    <div className="boxbtn-section mg-top-boxbtn">
                                        <BoxbtnTransparent icon={singleCloseIcon} name="Remove from Favorite Rewards" onClick={removeFromTrophyCase} />
                                        {/* <Boxbtn icon={shareIconSolid} name="Share on Linkedin" onClick={shareOnLinkedin}/> */}
                                        <FacebookShareButton children={undefined} url={shareOnFacebook()} ref={fbShareRef} />
                                        <Boxbtn icon={shareIconSolid} name="Share on" onClick={shareOnDisplay} />
                                    </div>
                                ) : null
                            }
                            {shareOn ? (
                                <ShareComponent
                                    addToLinkedIn={addToLinkedInUrl}
                                    shareOnLinkedIn={shareOnLinkedin}
                                    shareOnFacebook={() => { fbShareRef!.current?.click() }}
                                    linkValue={shareOnFacebookHelper(uid, id || "")}
                                    clickCopy={copyURL}
                                    closeShareOn={setShareOn}
                                    copiedTooltip={copiedTooltip}
                                />
                            ) : null}
                        </div>
                    )}

                    {displayCertificate && (
                        <div className='insideComponentsWrap'>
                            {
                                rewards.map((item: any, index) => {
                                    const certificate = JSON.parse(item.claims.certificate)
                                    certificate['issuedDate'] = item.claims.issuedDate
                                    certificate['issuerImage'] = item.claims.issuerImage
                                    certificate['issuerName'] = item.claims.issuerName
                                    certificate['issuerProfileURL'] = item.claims.issuerProfileURL
                                    certificate['userName'] = item.claims.holderName
                                    certificate['shareLink'] = shareOnFacebookHelper(uid, id || "")
                                    certificate['linkedInUrl'] = item.claims.holderProfileURL

                                    return <Certificate
                                        key={index.toString()}
                                        certificate={certificate}
                                    />
                                })
                            }
                            {
                                !isBadgeAvailable && isCertificateAvailable ?
                                    <>
                                        <div className="ReceivedRewardPreview-wrap" style={{ marginTop: '30px' }} >
                                            {
                                                rewards.map((item: any, index) => (
                                                    <ReceivedRewardPreviewUser
                                                        heading="Issued by"
                                                        profileImage={item.claims.issuerImage}
                                                        userName={item.claims.issuerName}
                                                        url={item.claims.issuerProfileURL}
                                                    />
                                                ))
                                            }
                                        </div>
                                        {
                                            showButtons ? (
                                                <div className="boxbtn-section mg-top-boxbtn">
                                                    <BoxbtnTransparent icon={singleCloseIcon} name="Remove from Favorite Rewards" onClick={removeFromTrophyCase} />
                                                    {/* <Boxbtn icon={shareIconSolid} name="Share on Linkedin" onClick={shareOnLinkedin}/> */}
                                                    <FacebookShareButton children={undefined} url={shareOnFacebook()} ref={fbShareRef} />
                                                    <Boxbtn icon={shareIconSolid} name="Share on" onClick={shareOnDisplay} />
                                                </div>
                                            ) : null
                                        }
                                        {shareOn ? (
                                            <ShareComponent
                                                addToLinkedIn={addToLinkedInUrl}
                                                shareOnLinkedIn={shareOnLinkedin}
                                                shareOnFacebook={() => { fbShareRef!.current?.click() }}
                                                linkValue={shareOnFacebookHelper(uid, id || "")}
                                                clickCopy={copyURL}
                                                closeShareOn={setShareOn}
                                                copiedTooltip={copiedTooltip}
                                            />
                                        ) : null}
                                    </> : null
                            }
                        </div>
                    )}

                    <Alert
                        open={alertOpen.open}
                        handleClose={goToTrophyCase}
                        handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                        title={alertOpen.title}
                        content={alertOpen.content}
                    />
                </div>
            </div>
            {isLoading && <Spinner />}
        </React.Fragment>
    );
}

export default ReceivedRewardPreviewTrophyCase;

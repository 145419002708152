import React, { useEffect, useState } from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import TopNavigationHeader from '../components/TopNavigationHeader'
import Boxbtn from '../components/Boxbtn'
import RewardPreviewItem from '../components/RewardPreviewItem'

import copy from '../Assets/copy.svg';
import badgeGold from '../Assets/badgeGold.svg';
import u3 from '../Assets/u3.png';
import u9 from '../Assets/u9.png';
import plusCircle from '../Assets/plusCircle.svg';
import shareIconSolid from '../Assets/shareIconSolid.svg';
import { useHistory } from 'react-router'

function IssuedRewardPreview(props:any){

    const history = useHistory()
    const [itemDetails, setItemDetails] = useState({} as any)
    const [copyText, setCopyText] = useState(false);


    useEffect(() => {
        setItemDetails(history.location.state as any)
    }, [history.location.state])

    function handleBack(){
        history.goBack()
    }

    function handleDone(){
        console.log('Clicked successfully.');
    }

    function copyOption(){
        setCopyText(true)
        console.log('Clicked successfully.');
        console.log(itemDetails.claims)
        setTimeout(() => {
            setCopyText(false)
        }, 2000)
    }

    return(
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                caption="Issued Reward"
                handleBack={handleBack}
                handleDone={handleDone}
                />
                <div className="wrap-full">

                    <RewardPreviewItem 
                        // copyClicked={copyOption}
                        // copyIcon={copy}
                        rewardImage={itemDetails?.claims?.rewardBadge}
                        holderImage={itemDetails?.claims?.holderImage}
                        issuerImage={itemDetails?.claims?.issuerImage} 
                        holderName={itemDetails?.claims?.holderName} 
                        issuerName={itemDetails?.claims?.issuerName} 
                        holderProfileUrl={itemDetails?.claims?.holderProfileURL} 
                        issuerProfileUrl={itemDetails?.claims?.issuerProfileURL} 
                        issuedDate={itemDetails?.claims?.issuedDate} 
                        rewardTitle={itemDetails?.claims?.title} 
                        remarks={itemDetails?.claims?.remarks}                    
                        copyText={copyText}
                        attributesValues={JSON.parse(itemDetails?.claims?.attributesValues || '[]') || []}  
                        />
                    
                    {/* <div className="boxbtn-section-irp mg-top-boxbtn">
                        <Boxbtn icon={shareIconSolid} name="Share on Linkedin" onClick={shareOnLinkedin}/>
                    </div> */}
                </div>
            </div>
        </React.Fragment>
    );
}

export default IssuedRewardPreview;
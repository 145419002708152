import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface VerifierState {
    vp: any;
    isVerified: boolean;
}

const initialState: VerifierState = {
    vp: undefined,
    isVerified: false
};

export const verifierSlice = createSlice({
    name: 'verifier',
    initialState,
    reducers: {
        setVP: (state, action: PayloadAction<string | undefined>) => {
            state.vp = action.payload? JSON.parse(action.payload): action.payload;
        },
        setIsVerifid: (state, action: PayloadAction<boolean>) => {
            state.isVerified = action.payload;
        },
    },
});

export const { setVP, setIsVerifid } = verifierSlice.actions;

export const _vp = (state: RootState) => state.verifier.vp;
export const _isVerified = (state: RootState) => state.verifier.isVerified;

export default verifierSlice.reducer;

import { IconButton } from '@material-ui/core'
import { ReactComponent as BackIcon } from '../SVGs/arrow-left-white.svg'


export default function TopNavigationHeader(props: { caption: string, handleBack?: any, handleDone?: any, disabled?: boolean, rightIcon?: any }) {

    return (
        <div className="topNav">
            <div className="left">
                {props.handleBack?
                <IconButton onClick={props.handleBack}>
                    <BackIcon />
                </IconButton>
                :<div className="emptyBackBtnSpace" />}
            </div>
            <p className="topNav-txt">{props.caption}</p>
            <div className="right">
                <IconButton onClick={props.handleDone} disabled={props.disabled as boolean} className="pd-right">
                    <img src={props.rightIcon}></img>
                </IconButton>
            </div>
        </div>
    )
}

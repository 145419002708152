import React from 'react'
import '../Style/App.scss'
import '../Style/Responsive.scss'
import TxtMain from '../components/TxtMain'


function ClaimIDByDomain() {

    return (
        <React.Fragment>
            <TxtMain/>
        </React.Fragment>
    );
}

export default ClaimIDByDomain;

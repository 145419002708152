import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface PresenterState {
    vcData: string;
    vp: string;
}

const initialState: PresenterState = {
    vcData: '',
    vp: ''
};

export const presenterSlice = createSlice({
    name: 'presenter',
    initialState,
    reducers: {
        setVCData: (state, action: PayloadAction<string>) => {
            state.vcData = action.payload;
        },
        setVP: (state, action: PayloadAction<string>) => {
            state.vp = action.payload;
        },
    },
});

export const { setVCData, setVP } = presenterSlice.actions;

export const _vcData = (state: RootState) => state.presenter.vcData;
export const _vp = (state: RootState) => state.presenter.vp;

export default presenterSlice.reducer;

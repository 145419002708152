import React, { useEffect } from 'react'

import '../Style/App.scss'
import '../Style/Responsive.scss'

import Boxbtn from '../components/Boxbtn'
import UserProfile from '../components/UserProfile'
import TopNavigationHeader from '../components/TopNavigationHeader'

import arrowAccept from '../Assets/arrowAccept.svg';
import arrowIssue from '../Assets/arrowIssue.svg';
import userProfile from '../Assets/userProfile.png';
import addCircle from '../Assets/addCircle.svg';
import addDone from '../Assets/addDone.svg';

import { useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import jwt from 'jsonwebtoken'
import { VIEWS } from "../utils";
import { useAppDispatch } from '../app/hooks'
import { setHolderDID, setHolderName, setHolderImage } from '../redux/signerSlice'
import Spinner from "../components/Spinner";
import Alert from "../components/Alert";


function GlobalContactProfile() {
    const history = useHistory()
    const [contact, setContact] = useState(null)
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })

    useEffect(() => {

        const receivingDID = new URLSearchParams(history.location.search).get("receivingDID");
        if (receivingDID && receivingDID !== "undefined") {
            getContact(receivingDID).then(res => {
                setContact(res.data);
            })
        }
    }, []);

    const getContact = async (receivingDID: string) => {
        const contact = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}contacts/users`, {
            params: {
                type: "did",
                value: receivingDID
            }
        });
        return contact;
    };

    function issueReward() {
        // @ts-ignore
        dispatch(setHolderDID(contact.receivingDID))
        // @ts-ignore
        dispatch(setHolderName(contact.name))
        // @ts-ignore
        dispatch(setHolderImage(contact.photo)) //update
        history.push(VIEWS.ISSUE)
    }

    function acceptReward() {
        console.log('Clicked successfully.');
    }

    function handleBack() {
        const searchType = new URLSearchParams(history.location.search).get("type");
        const query = new URLSearchParams(history.location.search).get("query");
        history.push(VIEWS.MYCONTACT + "?type=" + searchType + "&query=" + query)
    }

    function onAdd() {
        setIsLoading(true);
        const userToken = sessionStorage.getItem('userToken')
        const decodedUserToken: any = jwt.decode(userToken as string)
        const uid = decodedUserToken["_id"]
        const searchType = new URLSearchParams(history.location.search).get("type");
        const query = new URLSearchParams(history.location.search).get("query");
        // @ts-ignore
        addContact(uid, contact["name"], contact["receivingDID"], contact["photo"]).then(res => {
            setIsLoading(false);
            // @ts-ignore
            history.push(VIEWS.CONTACTPROFILE + "?receivingDID=" + contact["receivingDID"]+ "&from=gc&success=true&type=" + searchType + "&query=" + query);
        }).catch(e => {
            setAlertOpen({open: true, title: "Error", content: e.response.data.error})
            setIsLoading(false);
        });
    }

    const addContact = async (uid: string, contactName: string, contactDID: string, photo: string) => {
        const response = await axios.put(`${process.env.REACT_APP_REWARD_BACKEND}contacts/addContact`, {
            uid,
            contactName,
            contactDID,
            photo
        });
        return response;
    };

    return (
        <React.Fragment>
            <div className="container container-noback">
                <TopNavigationHeader
                    caption="Global Contacts Profile"
                    handleBack={handleBack}
                />
                <div className="wrap-full">
                    {
                        contact ?
                            <>
                                <div className="delete-icon">
                                    <button className="btn-without-background" onClick={onAdd}>
                                        <img src={addCircle}></img>
                                    </button>
                                </div>
                                <UserProfile userProfilePicture={// @ts-ignore
                                    contact["photo"]} userProfileName={// @ts-ignore
                                        contact["name"]
                                    } />
                                <div className="boxbtn-section">
                                    <Boxbtn icon={arrowIssue} name="Issue a Reward" onClick={issueReward} />
                                    <Boxbtn icon={arrowAccept} name="Request a Reward" onClick={acceptReward} />
                                </div>
                            </> : null
                    }

                </div>
            </div>
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
            {isLoading && <Spinner />}
        </React.Fragment>
    );
}

export default GlobalContactProfile;

import React, { ChangeEvent, useState, useEffect, useRef } from "react";

import '../Style/App.scss'
import '../Style/Responsive.scss'

import arrowAccept from '../Assets/arrowAccept.svg';
import arrowIssue from '../Assets/arrowIssue.svg';
import notoficationIcon from '../Assets/notoficationIcon.svg';
import notoficationHaveIcon from '../Assets/notoficationHaveIcon.svg';
import propic from '../Assets/defaultUser.jpg';

import { isDID, VIEWS } from "../utils";
import { useHistory, Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setHolderDID, setHolderPrivateKey, setImage, setIsIdentityCreated, setIssuerDID, setIssuerPrivateKey, setMnemonic, setName, _image } from "../redux/identitySlice";

import {isAddress} from '../utils'

import Logo from '../components/Logo'
import Button from '../components/Button'
import InputLarge from '../components/InputLarge'
import Boxbtn from '../components/Boxbtn'
import MyContacts from "./2.2 MyContacts";
import Spinner from '../components/Spinner';
import Alert from "../components/Alert";
import { type } from "os";
import axios from "axios";
import jwt from "jsonwebtoken";
import socket from "../socket";


let useClickOutside = (handler:any) => {
    let dropdownMenuRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        let maybeHandler = (event:any) => {
            if (!dropdownMenuRef?.current?.contains(event.target)) {
            handler();
            }
        };
        document.addEventListener("mousedown", maybeHandler);
        return () => {
            document.removeEventListener("mousedown", maybeHandler);
        };
    });
    return dropdownMenuRef;
};


export default function Home() {

    const history = useHistory()
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState({ open: false, title: '', content: '' })
    const [isOpen, setIsOpen] = useState(false);
    const [isInput, setisInput] = useState(true)
    const [validationTxt, setValidationTxt] = useState();
    const [verificationURL, setVerificationURL] = useState('')
    const profileImage = useAppSelector(_image)

    const userToken = sessionStorage.getItem('userToken')
    const decodedUserToken:any = jwt.decode(userToken as string)
    const uid = decodedUserToken.receivingDID;
    const [notificationIn, setNotificationIn] = useState(false)

    const socketHandler = (data:any)=> {
        console.log('NEW DATA RECIEVED HOME', data)
        if(data){
            setNotificationIn(true);
        }
    }

    useEffect(() => {
        //setIsLoading(true)
        getRewards(uid).then(res => {
                if(res.data[0].unread.length !== 0){
                    console.log("Notification have");
                    setNotificationIn(true);
                    setIsLoading(false)
                }
            console.log(res.data[0].unread);
            //setIsLoading(false)
        }).catch(err => {

        })
        socket.on(uid || "", socketHandler);
        return () => {
            socket.off(uid,socketHandler)
        };
    }, []);

    const getRewards = async (uid: string) => {
        const rewards = await axios.get(`${process.env.REACT_APP_REWARD_BACKEND}notifications/getUnreadNotifications`, {
            params: {
                uid
            }
        });
        return rewards;
    };


    function handleIssueReward() {
        history.push(VIEWS.RECEIVEDREQUESTS)
    }

    function handleAcceptReward() {
        history.push(VIEWS.RECEIVEDREWARDS)
    }

    function handleLogout() {
        dispatch(setMnemonic(''))
        dispatch(setName(''))
        dispatch(setImage(''))
        dispatch(setHolderPrivateKey(''))
        dispatch(setHolderDID(''))
        dispatch(setIssuerPrivateKey(''))
        dispatch(setIssuerDID(''))
        dispatch(setIsIdentityCreated(false))
        sessionStorage.removeItem('userToken')
    }

    function verify() {
        let elements = verificationURL.split('/')
        const rewardId = elements[elements.length - 1]
        const did = elements[elements.length - 2]
        if(isDID(did) && rewardId.length === 64){
            console.log(rewardId)
            history.push(`${VIEWS.VERIFIER}/${did}/${rewardId}`)
        }else{
            setAlertOpen({ open: true, title: "Error", content: "Enter valid verification URL" })
        }

    }

    function inviteCollegues() {
        history.push(VIEWS.INVITE)
    }

    function goToMyProfile() {
        history.push(VIEWS.PROFILE)
    }

    function goMyContacts() {
        history.push(VIEWS.MYCONTACT)
    }

    function goMyRewards() {
        history.push(VIEWS.MYREWARDS)
    }

    function goToNotifications(){
        history.push(VIEWS.NOTIFICATIONS)
    }

    let dropdownMenuRef = useClickOutside(() => {
        setIsOpen(false);
    });

    function handleSetVerificationURL(event: ChangeEvent<HTMLInputElement>) {
        setVerificationURL(event.target.value)
    }

    return (
        <React.Fragment>
            <div className="container container-back">
                <div className="nav_right">
                    <ul>
                        <li className="nr_li">
                            <button onClick={goToNotifications} className="btn-without-background">
                                {notificationIn? (<img className="notify" src={notoficationHaveIcon}></img>):(<img className="notify" src={notoficationIcon}></img>)}
                            </button>
                        </li>
                        <div ref={dropdownMenuRef}>
                            <li className="nr_li profile-dropdown-main" >
                                <button onClick={() => setIsOpen((isOpen) => !isOpen)} className="btn-without-background">
                                    <img className="avatar" src={profileImage || propic}></img>
                                </button>
                                <div className={`profile-dropdown-menu animate__animated animate__fadeIn ${isOpen ? 'profile-dropdown-menu-active' : ''}`}>
                                    <div className="dd_right">
                                        <ul>
                                            <li><button onClick={goToMyProfile} className="btn-without-background profile-dropdown-fonts">My Profile</button></li>
                                            <li><button onClick={goMyContacts} className="btn-without-background profile-dropdown-fonts">My Contacts</button></li>
                                            <li><button onClick={goMyRewards} className="btn-without-background profile-dropdown-fonts">My Rewards</button></li>
                                            <li><button onClick={handleLogout} className="btn-without-background profile-dropdown-fonts">Log Out</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>
                <Logo />
                <div className="wrap">
                    <div className="boxbtn-section boxbtn-mg-home">
                        <Boxbtn icon={arrowIssue} name="Issue a Reward" onClick={handleIssueReward} />
                        <Boxbtn icon={arrowAccept} name="Accept a Reward" onClick={handleAcceptReward} />
                    </div>
                    <InputLarge
                        text="Check the validity of a Reward"
                        placeholder="Enter the Reward URL"
                        onChange={handleSetVerificationURL}
                    />
                    <p className="error-validation-txt">{validationTxt}</p>
                    <Button onClick={verify} name="Verify a Reward" disabled={isInput !== true} />
                    <div className="extra-mt-homebtn"></div>
                    <Button onClick={inviteCollegues} name="Invite Your Collegues" />
                    <div className="extra-mt-homebtn"></div>
                </div>
            </div>
            {isLoading && <Spinner />}
            <Alert
                open={alertOpen.open}
                handleClose={() => setAlertOpen({ ...alertOpen, open: false })}
                handleOpen={() => setAlertOpen({ ...alertOpen, open: true })}
                title={alertOpen.title}
                content={alertOpen.content}
            />
        </React.Fragment>
    )
}


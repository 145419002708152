import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    profileCard: {
        margin: '1rem'
    },
    cardCaption: {
        fontWeight: 'bold',
        fontSize: '0.9rem',
        lineHeight: '3rem',
        color: '#161719'
    },
    profileCardInner: {
        display: 'flex'
    },
    profileImage: {
        width: 100,
        height: 'auto',
        borderRadius: 10,
        marginRight: '2rem'
    },
    profileName: {
        fontSize: '0.9rem',
        lineHeight: '1rem',
        color: '#161719'
    },
    profileUrl: {
        fontSize: '0.9rem',
        lineHeight: '1rem',
        color: '#757575'
    }
}))
export default function UserDetailCard(props: any) {

    const classes = useStyles()
    return (
        <div className={classes.profileCard}>
            <div className={classes.cardCaption}>{props.caption}</div>
            <div className={classes.profileCardInner}>
                <img className={classes.profileImage} src={props.image || process.env.PUBLIC_URL + '/dummy-user.png'} alt="" />
                <div>
                    <p className={classes.profileName}>{props.name}</p>
                    <p className={classes.profileUrl}>{props.url}</p>
                </div>
            </div>
        </div>
    )
}
import React from 'react'
import BoxBtnNew from './BoxBtnNew'
import closeIconShare from '../Assets/closeIconShare.svg'
import LinkedInVector from '../Assets/LinkedInVectorGreen.svg'
import FacebookVector from '../Assets/FacebookVectorGreen.svg'
import CopyVector from '../Assets/CopyVector.svg'


export default function ShareComponent(props: { closeShareOn: any, shareOnLinkedIn: any, shareOnFacebook: any, linkValue: any, clickCopy: any, copiedTooltip?: any, addToLinkedIn?: any, }) {

    function closeShareOn() {
        props.closeShareOn(false);
    }

    return (
        <React.Fragment>
            <div className="share-component-overLay-wrap"></div>
            <div className="share-component">
                <div className="share-component-header">
                    <h1>Share On</h1>
                    <button onClick={closeShareOn}>
                        <img src={closeIconShare} alt="" />
                    </button>
                </div>
                {/* <p className="share-component-sub-heading reduce-bottom-margin">Share this via</p> */}
                <div className="boxbtn-section mg-top-boxbtn">
                    <BoxBtnNew icon={LinkedInVector} onClick={props.shareOnLinkedIn} addToBtn={props.addToLinkedIn} />
                    <BoxBtnNew icon={FacebookVector} onClick={props.shareOnFacebook} xl />
                </div>
                <p className="share-component-sub-heading">Or copy link</p>
                <div className="copy-input-container">
                    <div className="copy-input-container-left">
                        <img src={CopyVector} alt="" />
                        <input type="text" value={props.linkValue} />
                    </div>
                    <button onClick={props.clickCopy}>Copy</button>
                    {props.copiedTooltip ? (
                        <div className="tooltipTextShareComponent">Copied to<br />Clipboard</div>
                    ) : null}
                </div>
            </div>
        </React.Fragment>
    )
}

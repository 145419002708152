import React, { Component } from 'react'
import logo from '../Assets/logo.svg'


export default function LogoTop() {
    return(
        <div className="decreaseMargin">
            <img src={logo}></img>
        </div>
    );
}

import React, { useEffect } from 'react';
import Template1 from '../CertificateTemplates/Template1';
import Template2 from '../CertificateTemplates/Template2';
import Template3 from '../CertificateTemplates/Template3';
import Template4 from '../CertificateTemplates/Template4';

export const templates = [
    { name: "Default Arrangement", template: Template1 },
    { name: "Certificate of Mastery", template: Template2 },
    { name: "Certificate of Mastery 2", template: Template3 },
    { name: "Certificate of Mind Bait", template: Template4 },
];

export default function Certificate(props: { templateRef?: any, certificate?: any }) {
    var CertificateTemplate = Template1
    
    if (props.certificate?.template_name) {
        let index = templates.findIndex(i => i.name == props.certificate?.template_name)
        if (index > -1) CertificateTemplate = templates[index].template
    }
    return (
        <div ref={props.templateRef}>
            <CertificateTemplate certificate={props.certificate} />
        </div>
    );
}

import keccak256 from 'keccak256'

export enum VIEWS {
    ONBORDING = '/',
    ISSUE = '/issue',
    ACCEPT = '/accept',
    VERIFIER = '/verify',
    SIGNEDVC = '/issuedReward',
    SIGNEDVP = '/acceptedReward',
    IDENTITY = '/identity',
    HOME = '/home',
    MORE = '/verificationDetails',
    SIGNIN = '/signin',
    SIGNUP = '/signup',
    CALLBACK = '/callback',
    PROFILE = '/profile',
    MYREWARDS = '/rewards',
    RECEIVEDREQUESTS = '/receivedRequests',
    RECEIVEDREWARDS = '/receivedRewards',
    RECEIVEDREWARDPREVIEW = '/receivedRewardPreview',
    MYCONTACT = '/myContacts',
    CONTACTPROFILE = '/contactProfile',
    GLOBALCONTACTS = '/contacts',
    GLOBALCONTACTPROFILE = '/userProfile',
    INVITE = '/invite',
    TROPHYCASE = '/trophyCase',
    TROPHYCASEPUBLIC = '/trophyCase/public',
    TROPHYVIEW = '/trophyView',
    TROPHYVIEWPUBLIC = '/trophyView/public',
    MYREWARDPREVIEW = '/myRewardPreview',
    ISSUEDREWARDPREVIEW = '/issuedRewardPreview',
    GENREWARDPREVIEW = '/generatedRewardPreview',
    NOTIFICATIONS = '/notifications',
    BADGES = '/quickBadges',
    REQUESTREWARD = '/requestReward',
    SEEMORE = '/seeMore',
    VERIFYDOMAIN = '/verifyDomain',
    VERIFYREWARD = '/VerifyReward',
    REWARDCERTIFICATE = '/rewardCertificate',
}

export function shareOnLinkedIn(did: string, id: string) {
    const uri = `/${did}/${id}`
    const url = `${process.env.REACT_APP_REWARD_VIEW}${uri}`;
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, "_blank");
}

export function shareOnFacebookHelper(did: string, id: string) {
    const uri = `/${did}/${id}`
    const url = `${process.env.REACT_APP_REWARD_VIEW}${uri}`;
    return url
}

export const rewardTypes = {
    receivedReward: 'receivedReward',
    acceptedReward: 'acceptedReward'
}

export function isAddress(address: string) {

    if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
        // check if it has the basic requirements of an address
        return false;
    } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
        // If it's all small caps or all all caps, return true
        return true;
    } else {
        // Otherwise check each case
        return isChecksumAddress(address);
    }
}

export function isChecksumAddress(address: string) {
    // Check each case
    address = address.replace('0x', '');
    const addressHash: string = keccak256(address.toLowerCase()).toString('hex');
    for (var i = 0; i < 40; i++) {
        // the nth letter should be uppercase if the nth digit of casemap is 1
        if ((parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) || (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])) {
            return false;
        }
    }
    return true;
}

export function isDID(did: string) {

    return (did && did.split(':')[0] === 'did' && did.split(':')[1] === 'ethr' && isAddress(did.split(':')[2]))


}

import React, { Component } from 'react'

function Boxbtn(props: {onClick: any, icon: any, name?: any, disabled?: boolean}){
    return(
        <React.Fragment>
            <button onClick={props.onClick} disabled={props.disabled} className={props.disabled ? "boxbtn-custom disabled-background": "boxbtn-custom"}>
                <img src={props.icon} className="iconPadding"></img>{props.name}
            </button>
        </React.Fragment>
    );
}

export default Boxbtn;

import React from 'react'

import ReceivedRewardPreviewUser from './RewardPreviewUser'
import { IconButton } from '@material-ui/core'

function RewardPreviewItem(props: {
    copyClicked?: any,
    copyIcon?: any,
    rewardImage: any,
    holderImage: any,
    issuerImage: any,
    holderName: string,
    issuerName: string,
    holderProfileUrl: string,
    issuerProfileUrl: string,
    issuedDate: string,
    rewardTitle: string,
    remarks: string,
    seeMore?: any,
    attributesValues: { [key: string]: string },
    copyText?: any
}) {
    function getDate(date:string) {
        try{
            const date2 = new Date(date)
            const month:number = date2.getMonth() + 1
            const monthString = month.toString().length > 1 ? month.toString() : "0"+ month
            const day = date2.getDate()
            const dayString = day.toString().length > 1 ? day.toString() : "0"+ day
            return (date2.getFullYear() + "-" + monthString + "-" + dayString)
        }catch (e) {
            return date
        }
    }
    return (
        <React.Fragment>
            <div className="ReceivedRewardPreview-wrap">

                {/* <div className="delete-icon">
                    {props.copyClicked && props.copyIcon &&
                        <IconButton onClick={props.copyClicked}>
                            <img src={props.copyIcon}></img>
                            {props.copyText ? (<div className="tooltipTextRewardPreview">Copied to<br></br>Clipboard</div>) : ""}
                        </IconButton>
                    }
                </div> */}

                <div className="coin-section">
                    <img className="coin-img" src={props.rewardImage}></img>
                    <p className="title-txt-blue">{props.rewardTitle}</p>
                    <p className="normal-txt-black">{props.remarks} </p>
                </div>

                <div className="extra-mt"></div>

                <ReceivedRewardPreviewUser
                    heading="Issued To"
                    profileImage={props.holderImage}
                    userName={props.holderName}
                    url={props.holderProfileUrl}
                />

                <div className="extra-mt2"></div>

                <ReceivedRewardPreviewUser
                    heading="Issued by"
                    profileImage={props.issuerImage}
                    userName={props.issuerName}
                    url={props.issuerProfileUrl}
                />

                <div className="extra-mt2"></div>

                <ReceivedRewardPreviewUser
                    heading="Issued on"
                    userName={getDate(props.issuedDate)}
                />

                <div className="issuerDetails">
                    <p className="sectionTitle"><span>Attributes</span></p>
                    {
                        Object.keys(props.attributesValues).map((key: string, index: number) => (
                            <div className="addSectionAttributePreview" key={index.toString()}>
                                <div className="sectionAttributePreview">{key}</div>
                                <div className="sectionValue">{props.attributesValues[key]}</div>
                            </div>
                        ))
                    }
                </div>

            </div>
        </React.Fragment>
    );
}

export default RewardPreviewItem;
